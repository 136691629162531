/**
 * Created by - Ashlekh on 09-08-2024
 * Reason - To have Quality Policy section
 */
import { useContext, useEffect, useState } from "react";
import style from "./qualityPolicy.module.css";
import { getQualityPolicyDetailsApi } from "../../../Api/services";
import parse from "html-react-parser";
import config from "../../../Api/config";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const QualityPolicy = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [qualityPolicy, setQualityPolicy] = useState({});
  const [showContent, setShowContent] = useState(false);
  // Added by - Ashlekh on 09-08-2024
  // Reason - This useEffect will execute when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    qualityPolicyDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // { name: "Company Profile", path: "/company_profile/quality-policy" },
      { name: "Quality Policy", path: "/company_profile/quality-policy" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 09-08-2024
  // Reason - This useEffect will execute when page loads

  // Added by - Ashlekh on 09-08-2024
  // Reason - To call API for getting QualityPolicy details from backend
  const qualityPolicyDetails = async () => {
    try {
      const response = await getQualityPolicyDetailsApi();
      setQualityPolicy(response?.quality_policy);
    } catch (error) {
      console.error("Error fetching QualityPolicy details: ", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 09-08-2024
  // Reason - To call API for getting QualityPolicy details from backend
  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
          {/* {qualityPolicy?.title ||
      qualityPolicy?.image ||
      qualityPolicy?.first_description ||
      qualityPolicy?.second_description ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
          <div className={`${style.mainContainer}`}>
            {/* Added by Jhamman on 03-10-2024
          Reason - Added image like banner*/}
            {qualityPolicy?.image ? (
              <div className={`${style.imageContainer}`}>
                <img
                  className={`${style.image}`}
                  src={config.baseURL + qualityPolicy.image}
                  alt=""
                />
              </div>
            ) : null}
            {/* End of addition by Jhamman on 03-10-2024
          Reason - Added image like banner*/}
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}

            {/* Commented by Jhamman on 03-10-2024
                Reason - this field is removed by backend
             qualityPolicy?.title ||
            qualityPolicy?.title?.trim()?.length > 0 || 
            
            qualityPolicy?.image ||
            
            qualityPolicy?.first_description ||
            qualityPolicy?.first_description?.trim()?.length > 0 || 
            End of commentation by Jhamman on 03-10-2024
                Reason - this field is removed by backend  */}

            {qualityPolicy?.second_description ||
            qualityPolicy?.second_description?.trim()?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={`${style.pageTitle}`}>Quality Policy</div>*/
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Qu</span>
                  <span className={style.title2}>ality Policy</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* Added by - Ashlekh on 07-09-2024
        Reason - To add page title and No details available */}

            {/* End of code - Ashlekh on 07-09-2024
        Reason - To add page title and No details available */}
            <div className={`${style.subContainer}`}>
              {/* Commented by Jhamman on 03-10-2024
                Reason - this field is removed by backend */}
              {/* <div className={`${style.subContainer1}`}> */}
              {/* {qualityPolicy?.title ? (
                  <div className={`${style.titleContainer}`}>
                    {qualityPolicy.title}
                  </div>
                ) : null} */}
              {/* End of commentation by Jhamman on 03-10-2024
                Reason - this field is removed by backend */}

              {/* Commented by Jhamman on 03-10-2024
                Reason - this field is removed by backend  */}
              {/* {qualityPolicy?.image ? (
                  <div className={`${style.imageContainer}`}>
                    <img
                      className={`${style.image}`}
                      src={config.baseURL + qualityPolicy.image}
                      alt=""
                    />
                  </div>
                ) : null} */}
              {/* End of commentation by Jhamman on 03-10-2024
                Reason - this field is removed by backend  */}

              {/* Commented by Jhamman on 03-10-2024
                Reason - this field is removed by backend */}
              {/* {qualityPolicy?.first_description ? (
                  <div className={`${style.firstDescriptionContainer}`}>
                    {qualityPolicy?.first_description}
                  </div>
                ) : null} */}
              {/* </div> */}
              {/* <div className={`${style.subContainer2}`}> */}
              {/* End of commentation by Jhamman on 03-10-2024
                Reason - this field is removed by backend */}
              {qualityPolicy?.second_description ? (
                <div className={`${style.secondDescriptionContainer}`}>
                  {" "}
                  {parse(qualityPolicy?.second_description)}{" "}
                </div>
              ) : null}
              {/* </div> */}
            </div>
          </div>
          {/* Code changed by - Ashlekh on 07-09-2024
        Reason - To change condition for No details available */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
    reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
  reason - Show nothing while loading the data*/}
    </>
  );
};
export default QualityPolicy;
