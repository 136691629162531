// Added by - Ashish Dewangan on 17-08-2024
// Reason - UI to render certificate details

import React, { useContext, useEffect, useState } from "react";
import { certificate } from "../../Api/services";
import certificateStyle from "./Certificates.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../Api/config";

import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const Certificates = () => {
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);

  const [certificatePageDetails, setCertificatePageDetails] = useState("");
  const [certificates, setCertificates] = useState([]);
  /**
   * Added by - Ashish Dewangan on 17-08-2024
   * Reason - calling get API to get certificate page details
   */
  const fetchCertificatesData = async () => {
    try {
      const response = await certificate();
      setCertificatePageDetails(response.certificate_page_content.page_content);
      setCertificates(response.certificates);
    } catch (error) {
      console.error("Error fetching certificate details:", error);
    }
  };
  /**
   * End of code addition by - Ashish Dewangan on 17-08-2024
   * Reason - calling get API to get certificate page details
   */

  /**
   * Added by - Ashish Dewangan on 17-08-2024
   * Reason - calling fetchCertificatesData on page load,
   * that will call API to get certificate page details.
   */
  useEffect(() => {
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Certificates", path: "/certificates" },
    ]);

    /**
     * Added by - Ashish Dewangan on 17-08-2024
     * Reason - To scroll to the top of the page when page loads
     */
    window.scrollTo(0, 0);
    /**
     * End of code addition by - Ashish Dewangan on 17-08-2024
     * Reason - To scroll to the top of the page when page loads
     */

    fetchCertificatesData();
  }, []);
  /**
   * End of code addition by - Ashish Dewangan on 17-08-2024
   * Reason - calling fetchCertificatesData on page load,
   * that will call API to get certificate page details.
   */

  if (certificatePageDetails?.trim()?.length < 1 && certificates?.length < 1)
    /**
     * Modified by - Ashish Dewangan on 23-08-2024
     * Reason - To provide styling
     */
    // return <p>No details available</p>;
    return (
      <p className={certificateStyle.noDetailsAvailableText}>
        No details available
      </p>
    );
  /**
   * End of modification by - Ashish Dewangan on 23-08-2024
   * Reason - To provide styling
   */

  return (
    <div className={certificateStyle.mainContainer}>
      <div className={certificateStyle.certificatePageContentContainer}>
        <NavigationPath navigationPathArray={navigationPath} />
        {/*
         * Modified by - Ashish Dewangan on 22-08-2024
         * Reason - To provide page title
         */}
        <div className={certificateStyle.pageTitle}>Certifications</div>
        {/*
         * End of modification by - Ashish Dewangan on 22-08-2024
         * Reason - To provide page title
         */}
        {certificatePageDetails?.trim()?.length > 1 &&
          parse(certificatePageDetails)}
      </div>

      {certificates.length > 0 && (
        <div className={certificateStyle.certificatesImageContainer}>
          {certificates.map((certificate, index) => {
            return (
              <div className={certificateStyle.certificatesImageWrapper}>
                <img
                  src={baseURL + certificate.certificate_image}
                  className={certificateStyle.certificatesImage}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Certificates;
