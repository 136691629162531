/**
 * Created by - Ashlekh on 08-08-2024
 * Reason - To have BusinessValue section
 */
import { useContext, useEffect, useState } from "react";
import { getBusinessValueDetailsApi } from "../../../Api/services";
import style from "./businessValue.module.css";
import config from "../../../Api/config";
import parse from "html-react-parser";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";
import { GlobalContext } from "../../../context/Context";

const BusinessValue = () => {
  const [businessDetails, setBusinessDetails] = useState({});
  const [businessPrinciples, setBusinessPrinciples] = useState([]);
  const [showContent, setShowContent] = useState(false);
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  // Added by - Ashlekh on 08-08-2024
  // Reason - This useEffect will execute when page loads
  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 28-09-2024
     * Reason - Scroll to top when refresh
     */
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 28-09-2024
     * Reason - Scroll to top when refresh
     */

    businessValueDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // { name: "Company Profile", path: "/company_profile/business-value" },
      { name: "Business Value", path: "/company_profile/business-value" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 08-08-2024
  // Reason - This useEffect will execute when page loads

  // Added by - Ashlekh on 08-08-2024
  // Reason - To call API for getting BusinessValue details from backend
  const businessValueDetails = async () => {
    try {
      const response = await getBusinessValueDetailsApi();
      setBusinessDetails(response?.business_value);
      setBusinessPrinciples(response?.business_principle);
    } catch (error) {
      console.error("Error fetching about us details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 08-08-2024
  // Reason - To call API for getting BusinessValue details from backend
  console.log(businessDetails);
  console.log(businessPrinciples);

  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
          {/* {businessDetails?.banner_image ||
      businessDetails?.description ||
      businessPrinciples?.length > 0 ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
        Reason - To change condition for No details available */}
          <div className={`${style.mainContainer}`}>
            {businessDetails?.banner_image ? (
              <div className={`${style.bannerImage}`}>
                <img
                  className={`${style.image}`}
                  src={config.baseURL + businessDetails?.banner_image}
                  alt=""
                />
              </div>
            ) : null}
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 07-09-2024
        Reason - To display Title and No details available */}
            {businessDetails?.description || businessPrinciples?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={`${style.pageTitle}`}>Business Value</div> */
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Bu</span>
                  <span className={style.title2}>siness Value</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
        Reason - To display Title and No details available */}
            {businessDetails?.description ? (
              <div className={`${style.description}`}>
                {" "}
                {parse(businessDetails?.description)}{" "}
              </div>
            ) : null}
            {businessPrinciples != null && businessPrinciples?.length > 0 ? (
              <div className={`${style.businessPrincipleContainer}`}>
                {businessPrinciples.map((item, index) => (
                  <div
                    key={index}
                    className={`${style.businessPrincipleSubContainer}`}>
                    <div className={`${style.imageContainer}`}>
                      <img
                        className={`${style.principleImage}`}
                        // Code changed by - Ashlekh on 11-09-2024
                        // Reason - To display default image of business principle
                        // src={config.baseURL + item?.image}
                        src={
                          item?.image
                            ? `${config.baseURL + item?.image}`
                            : "/business_principle_default_4.PNG"
                        }
                        // End of code - Ashlekh on 11-09-2024
                        // Reason - To display default image of business principle
                        alt=""
                      />
                    </div>
                    <div className={`${style.titleContainer}`}>
                      {item.title}
                    </div>
                    <div className={`${style.descriptionContainer}`}>
                      {item.description}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {/* Commented by - Ashlekh on 07-09-2024
        Reason - To change condition for No details available */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};
export default BusinessValue;
