/**
 * Created by - Ashish Dewangan on 08-07-2024
 * Reason - To have footer section
 */

import React, { useContext, useEffect, useState } from "react";
import footerStyle from "./footer.module.css";
import { GlobalContext } from "../../context/Context";
import {
  MdOutlineEmail,
  MdOutlinePhoneAndroid,
  MdOutlineLocationOn,
  MdOutlinePhone,
} from "react-icons/md";
import { certificate } from "../../Api/services";
import { baseURL } from "../../Api/config";
import { Link } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import TopToPage from "../TopToPage/TopToPage";

const Footer = () => {
  const { settingDetail, setSettingDetail } = useContext(GlobalContext);
  const [certificates, setCertificates] = useState([]);

  /**
   * Added by - Ashish Dewangan on 17-08-2024
   * Reason - calling get API to get certificate page details
   */
  const fetchCertificatesData = async () => {
    try {
      const response = await certificate();
      setCertificates(response.certificates);
    } catch (error) {
      console.error("Error fetching certificate details:", error);
    }
  };
  /**
   * End of code addition by - Ashish Dewangan on 17-08-2024
   * Reason - calling get API to get certificate page details
   */

  /**
   * Added by - Ashish Dewangan on 17-08-2024
   * Reason - calling fetchCertificatesData when footer is loaded,
   * that will call API to get certificate page details.
   */
  useEffect(() => {
    fetchCertificatesData();
  }, []);
  /**
   * End of code addition by - Ashish Dewangan on 17-08-2024
   * Reason - calling fetchCertificatesData when footer is loaded,
   * that will call API to get certificate page details.
   */

  return (
    /**
     * Added by - Ashish Dewangan on 25-08-2024
     * Reason - To align footer content
     */
    <div>
      {/* * Added by - Ashish Dewangan on 25-08-2024
       * Reason - To align footer content */}

      <div className={`${footerStyle.footerContainer}`}>
        {/* Added by - Ashish Dewangan on 21-08-2024
      Reason - For whatsapp  */}
        {settingDetail?.whatsapp_number?.trim()?.length > 0 && (
          <div className={`${footerStyle.whatsAppIcon}`} draggable={true}>
            <FloatingWhatsApp
              //Modified by Jhamman lal sahu on 25-08-2024
              // Reason - to set position of whatspp chat box
              style={{ left: 30 }}
              buttonStyle={{ left: 30, width: 40, height: 40 }}
              //Modified by Jhamman lal sahu on 25-08-2024
              // Reason - to set position of whatspp chat box
              //Modified by Jhamman lal sahu on 25-08-2024
              // Reason - Added 91 in prefix
              // phoneNumber={settingDetail.whatsapp_number}
              phoneNumber={`91${settingDetail.whatsapp_number}`}
              // End of modification by Jhamman lal sahu on 25-08-2024
              // Reason - Added 91 in prefix
              avatar={settingDetail.logo ? baseURL + settingDetail.logo : null}
              accountName="9M India Limited"
              allowClickAway={true}
            />
          </div>
        )}
        {/* End of code addition by - Ashish Dewangan on 21-08-2024
       Reason - For whatsapp */}
        <div className={`${footerStyle.flexRow}`}>
          {/* modified by Jhamman on 16-09-2024
        Reason - Added separate class name*/}
          {/* <div className={`${footerStyle.flexColumn}`}> */}
          <div className={`${footerStyle.flexColumn1}`}>
            {/* End of modification by Jhamman on 16-09-2024
        Reason - Added separate class name*/}
            <div className={`${footerStyle.columnTitle}`}>Get in touch</div>

            <div className={`${footerStyle.columnContent}`}>
              {settingDetail.office_address &&
                settingDetail.office_address?.trim()?.length > 0 && (
                  <div className={`${footerStyle.contentText}`}>
                    <div>
                      <MdOutlineLocationOn
                        className={`${footerStyle.contentIcon}`}
                      />
                    </div>
                    <div className={footerStyle.contactContainer}>
                      <div className={footerStyle.contactHeading}>
                        Office address:
                      </div>
                      <div className={footerStyle.contactContent}>
                        {settingDetail?.office_address}
                      </div>
                    </div>
                  </div>
                )}

              {/* Added by Jhamman on 09-09-2024
                Reason - to add factory address*/}
              {settingDetail.factory_address &&
                settingDetail.factory_address?.trim()?.length > 0 && (
                  <div className={`${footerStyle.contentText}`}>
                    <div>
                      <MdOutlineLocationOn
                        className={`${footerStyle.contentIcon}`}
                      />
                    </div>
                    <div className={footerStyle.contactContainer}>
                      <div className={footerStyle.contactHeading}>
                        Factory address:
                      </div>
                      <div className={footerStyle.contactContent}>
                        {settingDetail?.factory_address}
                      </div>
                    </div>
                  </div>
                )}
              {/* End of addition by Jhamman on 09-09-2024
                Reason - to add factory address*/}

              {/* Added by Jhamman on 09-09-2024
                Reason - to add general query contact number*/}

              {settingDetail.career_contact_number &&
                settingDetail.career_contact_number?.trim()?.length > 0 && (
                  <div className={`${footerStyle.contentText}`}>
                    <div>
                      <MdOutlinePhone
                        className={`${footerStyle.contentIcon}`}
                      />
                    </div>
                    <div className={footerStyle.contactContainer}>
                      <div className={footerStyle.contactHeading}>
                        For general query:
                      </div>
                      <div className={footerStyle.contactContent}>
                        {settingDetail.career_contact_number}
                      </div>
                    </div>
                  </div>
                )}
              {/* End of addition by Jhamman on 09-09-2024
                Reason - to add general query contact*/}

              {/* Added by Jhamman on 09-09-2024
                Reason - to add  to add institutional business and tenders*/}
              {settingDetail.business_contact_number &&
                settingDetail.business_contact_number?.trim()?.length > 0 && (
                  <div className={`${footerStyle.contentText}`}>
                    <div>
                      <MdOutlinePhone
                        className={`${footerStyle.contentIcon}`}
                      />
                    </div>
                    <div className={footerStyle.contactContainer}>
                      <div className={footerStyle.contactHeading}>
                        For institutional business and tenders:
                      </div>
                      <div className={footerStyle.contactContent}>
                        {settingDetail.business_contact_number}
                      </div>
                    </div>
                  </div>
                )}
              {/* End of addition by Jhamman on 09-09-2024
                Reason - to add institutional business and tenders*/}

              {/* Commented by Jhamman on 09-09-2024
                Reason- Modified below*/}
              {/* {settingDetail.email &&
                settingDetail.email?.trim()?.length > 0 && (
                  <div className={`${footerStyle.contentText}`}>
                    <div>
                      <MdOutlineEmail
                        className={`${footerStyle.contentIcon}`}
                      />
                    </div>{" "}
                    email : {settingDetail.email}{" "}
                  </div>
                )} */}

              {/* End of commentation by Jhamman on 09-09-2024
                Reason- Modified below*/}

              {/* Addition by Jhamman on 09-09-2024
                  Reason - modified email setion*/}
              {settingDetail?.email ||
              settingDetail?.email?.trim()?.length > 0 ||
              settingDetail?.second_email ||
              settingDetail?.second_email?.trim()?.length > 0 ? (
                <div className={footerStyle.contentText}>
                  <div>
                    <MdOutlineEmail className={footerStyle.contentIcon} />
                  </div>
                  <div className={footerStyle.contactContainer}>
                    <div className={footerStyle.contactHeading}>Email:</div>
                    <div className={footerStyle.contactContent}>
                      {settingDetail?.email ||
                      settingDetail?.email?.trim()?.length > 0 ? (
                        <div style={{ marginBottom: 3 }}>
                          {settingDetail?.email}
                        </div>
                      ) : null}
                      {settingDetail?.second_email ||
                      settingDetail?.second_email?.trim()?.length > 0 ? (
                        <div style={{ marginBottom: 3 }}>
                          {settingDetail?.second_email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* End of addition by Jhamman on 09-09-2024
                  Reason - modified email setion*/}

              {/* Added by Jhamman on 09-09-2024
                 Reason - Added  hr email*/}
              {settingDetail.hr_email &&
                settingDetail.hr_email?.trim()?.length > 0 && (
                  <div className={`${footerStyle.contentText}`}>
                    <div>
                      <MdOutlineEmail
                        className={`${footerStyle.contentIcon}`}
                      />
                    </div>
                    <div className={footerStyle.contactContainer}>
                      <div className={footerStyle.contactHeading}>
                        Hr email:
                      </div>
                      <div className={footerStyle.contactContent}>
                        {/* {settingDetail?.email} */}
                        {settingDetail?.hr_email}
                      </div>
                    </div>
                  </div>
                )}
              {/* End of addition by Jhamman on 09-09-2024
                    Reason - Added hr email*/}

              {/* Added by Jhamman on 09-09-2024
                Reason - to add domestic sales contact number*/}
              {settingDetail.domestic_sales_contact_number &&
                settingDetail.domestic_sales_contact_number?.trim()?.length >
                  0 && (
                  <div className={`${footerStyle.contentText}`}>
                    <div>
                      <MdOutlinePhone
                        className={`${footerStyle.contentIcon}`}
                      />
                    </div>
                    <div className={footerStyle.contactContainer}>
                      <div className={footerStyle.contactHeading}>
                        For domestic sales:
                      </div>
                      <div className={footerStyle.contactContent}>
                        {settingDetail.domestic_sales_contact_number}
                      </div>
                    </div>
                  </div>
                )}
              {/* End of addition by Jhamman on 09-09-2024
                Reason - to add domestic sales contact number*/}

              {/* Added by Jhamman on 09-09-2024
                 Reason - Added contact international sales*/}
              {settingDetail?.international_sales_contact_number ||
              settingDetail?.international_sales_contact_number?.trim()
                ?.length > 0 ||
              settingDetail?.first_email_for_international_sales ||
              settingDetail?.first_email_for_international_sales?.trim()
                ?.length > 0 ||
              settingDetail?.second_email_for_international_sales ||
              settingDetail?.second_email_for_international_sales?.trim()
                ?.length > 0 ? (
                <div className={footerStyle.contentText}>
                  <div>
                    <MdOutlineEmail className={footerStyle.contentIcon} />
                  </div>
                  <div className={footerStyle.contactContainer}>
                    <div className={footerStyle.contactHeading}>
                      For international sales:
                    </div>
                    <div className={footerStyle.contactContent}>
                      {settingDetail?.international_sales_contact_number ||
                      settingDetail?.international_sales_contact_number?.trim()
                        ?.length > 0 ? (
                        <div style={{ marginBottom: 3 }}>
                          {settingDetail?.international_sales_contact_number}
                        </div>
                      ) : null}
                      {settingDetail?.first_email_for_international_sales ||
                      settingDetail?.first_email_for_international_sales?.trim()
                        ?.length > 0 ? (
                        <div style={{ marginBottom: 3 }}>
                          {settingDetail?.first_email_for_international_sales}
                        </div>
                      ) : null}
                      {settingDetail?.second_email_for_international_sales ||
                      settingDetail?.second_email_for_international_sales?.trim()
                        ?.length > 0 ? (
                        <div style={{ marginBottom: 3 }}>
                          {settingDetail?.second_email_for_international_sales}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* End of addition by Jhamman on 09-09-2024
                    Reason - Added contact for international sales*/}
            </div>
          </div>

          {/* Added by Jhamman lal sahu on 17-08-2024
        Reason - To show important links in footer     */}

          {/* modified by Jhamman on 16-09-2024
        Reason - Added separate class name*/}
          {/* <div className={`${footerStyle.flexColumn}`}> */}
          <div className={`${footerStyle.flexColumn2}`}>
            {/* End of modification by Jhamman on 16-09-2024
        Reason - Added separate class name*/}
            <div className={`${footerStyle.columnTitle}`}>Important links</div>

            {/* Modified by - Jhamman on 26-08-2024
            Reason - separation of limk between more then one div to show them in diffrent column */}
            {/* <div className={`${footerStyle.columnContent}`}> */}
            <div className={`${footerStyle.impLinkColumn}`}>
              <div className={`${footerStyle.column1}`}>
                {/* End of modification by - Jhamman on 26-08-2024
            Reason - separation of limk between more then one div to show them in diffrent column */}
                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/privacy-policy"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Privacy Policy
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/terms-and-conditions"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    T&C
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                {/* Added by - Jhamman lal sahu on 26-08-2024
              Reason - to add more links */}

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Home
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/infrastructure/manufacturing"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Manufacturing
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/infrastructure/packaging"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Packaging
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/infrastructure/research-and-development"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    R&D
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/infrastructure/accreditation"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Accreditation
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/infrastructure/quality-control"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Quality control */}
                    Quality Control
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/about_us"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* About us */}
                    About Us
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/mission-vision"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Mission vision */}
                    Mission Vision
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/business-value"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Business value */}
                    Business Value
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/management"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Management
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/why-us"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Why us */}
                    Why Us
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/quality-policy"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Quality policy */}
                    Quality Policy
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>
              </div>

              {/* Added by - Jhamman on 26-08-2024
            Reason - separation of limk between more then one div to show them in diffrent column */}
              <div className={`${footerStyle.column2}`}>
                {/* End of addition by - Jhamman on 26-08-2024
            Reason - separation of limk between more then one div to show them in diffrent column */}

                {/* <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/business-value"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Business value
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/management"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Management
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/why-us"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Why us
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/quality-policy"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Quality policy
                  </Link>
                </div> */}

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/company_profile/client-satisfaction"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Client satisfaction */}
                    Client Satisfaction
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/global-presence"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Global presence */}
                    Global Presence
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/contract-manufacturing"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Contract manufacturing */}
                    Contract Manufacturing
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/third-party-manufacturing"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Third party manufacturing */}
                    Third Party Manufacturing
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/institutional-tenders"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Institutional tenders */}
                    Institutional Tenders
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/generic-medicines"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Generic medicines */}
                    Generic Medicines
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>
                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/otc-products"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* OTC products */}
                    OTC Products
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/regulatory-services"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Regulatory services */}
                    Regulatory Services
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    /* Modified by Jhamman on 26-09-2024
                  Reason- path changed*/
                    // to="/product-details"
                    to="/all-products"
                    /* End of modification by Jhamman on 26-09-2024
                  Reason- path changed*/
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Products details */}
                    Products
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/events"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Events
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>
                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/gallery"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* gallery */}
                    Gallery
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/blog"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Blog
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/career"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Career
                  </Link>
                  {/* Added by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                  <span className={`${footerStyle.orOperator} `}> || </span>
                  {/* End of addition by jhamman on 19-09-2024
                  Reason - added || between link in mobile view*/}
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/contact"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    {/* Contact us */}
                    Contact Us
                  </Link>
                </div>
              </div>

              {/* Commented by Jhamman on 19-09-2024
                  Reason - No need all defined in two column*/}
              {/* Added by - Jhamman on 26-08-2024
            Reason - separation of limk between more then one div to show them in diffrent column */}
              {/* <div className={`${footerStyle.column3}`}> */}
              {/* End of addition by - Jhamman on 26-08-2024
            Reason - separation of limk between more then one div to show them in diffrent column */}
              {/* <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/otc-products"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    OTC products
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/services/regulatory-services"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Regulatory services
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/product-details"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Products details
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/events"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Events
                  </Link>
                </div> */}

              {/* <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/gallery"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    gallery
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/blog"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Blog
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/career"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Career
                  </Link>
                </div>

                <div className={`${footerStyle.contentText} `}>
                  <Link
                    to="/contact"
                    className={`${footerStyle.linkText} `}
                    style={{ marginBottom: "6px" }}>
                    Contact us
                  </Link>
                </div> */}
              {/* </div> */}
              {/* End of the addition by - Jhamman lal sahu on 26-08-2024
              Reason - to add more links */}
              {/* End of commentation by Jhamman on 19-09-2024
                  Reason - No need all defined in two column*/}
            </div>
          </div>

          {/* End of addition by Jhamman lal sahu on 17-08-2024
        Reason - To show important links in footer     */}

          {/* Addition by Jhamman lal sahu on 22-08-2024
        Reason - To show important links in footer in mobile view    */}
          <div className={`${footerStyle.flexColumn2ForMobile}`}>
            <div className={`${footerStyle.columnTitle}`}>Important links</div>
            <div className={`${footerStyle.impLinkColumnForMobile}`}>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/privacy-policy"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Privacy Policy
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/terms-and-conditions"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  T&C
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Home
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/infrastructure/manufacturing"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Manufacturing
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/infrastructure/packaging"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Packaging
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/infrastructure/research-and-development"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  R&D
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/infrastructure/accreditation"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Accreditation
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/infrastructure/quality-control"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Quality Control
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/company_profile/about_us"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  About Us
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/company_profile/mission-vision"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Mission Vision
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/company_profile/business-value"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Business Value
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/company_profile/management"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Management
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/company_profile/why-us"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Why Us
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/company_profile/quality-policy"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Quality Policy
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/company_profile/client-satisfaction"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Client Satisfaction
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/global-presence"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Global Presence
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/services/contract-manufacturing"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Contract Manufacturing
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/services/third-party-manufacturing"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Third Party Manufacturing
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/services/institutional-tenders"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Institutional Tenders
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/services/generic-medicines"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Generic Medicines
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/services/otc-products"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  OTC Products
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/services/regulatory-services"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Regulatory Services
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/all-products"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Products
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/events"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Events
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/gallery"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Gallery
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>
              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/blog"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Blog
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>

              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/career"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Career
                </Link>
                <span className={`${footerStyle.orOperator} `}> || </span>
              </div>

              <div className={`${footerStyle.contentText} `}>
                <Link
                  to="/contact"
                  className={`${footerStyle.linkText} `}
                  style={{ marginBottom: "6px" }}>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>

          {/* End of addition by Jhamman lal sahu on 22-09-2024
        Reason - To show important links in footer for mobile view*/}

          {/* Added by - Ashish dewangan on 17-08-2024
        Reason - To show certifications on footer */}
          {certificates.length > 0 && (
            /* modified by Jhamman on 16-09-2024
        Reason - Added separate class name*/
            /* <div className={`${footerStyle.flexColumn}`}> */
            <div className={`${footerStyle.flexColumn3}`}>
              {/* End of modification by Jhamman on 16-09-2024
            Reason - Added separate class name */}
              <div className={`${footerStyle.columnTitle}`}>Certificates</div>

              <div className={`${footerStyle.columnContent}`}>
                <div className={footerStyle.certificatesImageContainer}>
                  {certificates.map((certificate, index) => {
                    /* Commented by Jhamman on 19-09-2024
                    Reason - all the certificate should show in footer*/
                    {
                      /* if (index < 9) */
                    }
                    /* End of commentation by Jhamman on 19-09-2024
                    Reason - all the certificate should show in footer*/
                    return (
                      <div
                        className={footerStyle.certificatesImageWrapper}
                        key={index}>
                        {/* Modified by Jhamman on 19-09-2024
                        Reason - add contact us link instead of certificate*/}
                        {/* <Link to={"/certificates"}> */}
                        <Link to={"/contact"}>
                          {/* End of modification by Jhamman on 19-09-2024
                        Reason - add contact us link instead of certificate*/}
                          <img
                            src={baseURL + certificate.certificate_image}
                            className={footerStyle.certificatesImage}
                          />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {/* End of code addition by - Ashish dewangan on 17-08-2024
        Reason - To show certifications on footer */}
        </div>

        {/* * Modified by - Ashish Dewangan on 25-08-2024
         * Reason - To align footer content */}

        {/* {settingDetail.copyright_text &&
        settingDetail.copyright_text?.trim()?.length > 0 && (
          <div className={` ${footerStyle.contentText}`}>
            {settingDetail.copyright_text}{" "}
          </div>
        )} */}
      </div>
      <div className={`${footerStyle.bottomFooterContainer}`}>
        {/*modified by Jhamman on 16-09-2024
            Reason - changed class name*/}
        {/* <div className={` ${footerStyle.contentText} `}>  */}
        <div className={` ${footerStyle.poweredByContentText} `}>
          {/* End of modification by Jhamman on 16-09-2024
            Reason - changed class name */}
          {/*End of modification by Jhamman on 18-09-2024
            Reason - changed class name*/}

          {/* /* Modified by Jhamman on 28-09-2024
            Reason - Create a link to the text*/}
          <Link
            className={`${footerStyle.poweredByLinkText} `}
            to={`https://adyant.co.in/`}
            target="_blank"
            rel="noopener noreferrer">
            Powered by Adyant SoftTech
          </Link>
          {/* End of modification by Jhamman on 28-09-2024
            Reason - Create a link to the text*/}
        </div>
        {settingDetail.copyright_text &&
          settingDetail.copyright_text?.trim()?.length > 0 && (
            /* modified by Jhamman on 16-09-2024
            Reason - changed class name*/
            /* <div className={` ${footerStyle.contentText} `}> */
            <div className={` ${footerStyle.copyrightContentText} `}>
              {/* modified by Jhamman on 16-09-2024
            Reason - changed class name */}
              <span> || </span>
              {settingDetail.copyright_text}
            </div>
          )}
        {/*modified by Jhamman on 18-09-2024
            Reason - changed class name*/}
      </div>
      {/* * End of modification by - Ashish Dewangan on 25-08-2024
       * Reason - To align footer content */}

      {/* Added by - jhamman lal sahu on 18-08-2024
      Reason - to add top to page button */}
      <TopToPage />
      {/* End of the addition by - jhamman lal sahu on 18-08-2024
      Reason - to add top to page button */}
    </div>
  );
};
export default Footer;
