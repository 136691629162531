/**
 * Created by - Ashish on 08-07-2024
 * Reason - for storing global states
 */
import { useState, useEffect } from "react";
import { createContext } from "react";
import { useLocation } from "react-router-dom";
import { getSettingDetailsApi } from "../Api/services";
// import { refresh, userDetails } from "../Api/services";

export const GlobalContext = createContext();

export default function Context(props) {
  const location = useLocation();

  const [user, setUser] = useState({});
  const [settingDetail, setSettingDetail] = useState({});

  /**
   * Added by - Ashish Dewangan on 08-08-2024
   * Reason - Added a variable to store navigation path
   */
  const [navigationPath, setNavigationPath] = useState([
    { name: "home", path: "/" },
  ]);
  /**
   * End of code addition by - Ashish Dewangan on 08-08-2024
   * Reason - Added a variable to store navigation path
   */

  /**
   * Added by - Ashish Dewangan on 16-08-2024
   * Reason - Calling API on page load to get setting details
   */
  useEffect(() => {
    fetchSettingData();
  }, []);

  const fetchSettingData = async () => {
    try {
      const response = await getSettingDetailsApi();
      setSettingDetail(response.setting_details);
    } catch (error) {
      console.error("Error fetching homepage details:", error);
    }
  };

  /**
   * End of code addition by - Ashish Dewangan on 16-08-2024
   * Reason - Calling API on page load to get setting details
   */

  /* Commented by Jhamman on 26-09-2024
  Reason - We longer need because we are rendering data in another page*/
  /* Added by Jhamman on 05-09-2024
  Reason - to remove events data from session storage that is set in event.jsx */
  // useEffect(() => {
  //   sessionStorage.removeItem("selectedEvent");
  // }, [navigationPath]);
  /* End of addition by Jhamman on 05-09-2024
  Reason - to remove events data from session storage that is set in event.jsx */
   /* End of commentation by Jhamman on 26-09-2024
  Reason - We longer need because we are rendering data in another page*/

  /**
   * Added by - Ashish Dewangan on 08-07-2024
   * Reason - On refresh get user details from backend
   */
  /**
   * Commented by - Ashish Dewangan on 05-08-2024
   * Reason - Logged in user logic is not required in this project
   */
  // useEffect(() => {
  //   var access = localStorage.getItem("access");
  //   if (access) {
  //     getUserDetails(access);
  //   } else {
  //     localStorage.removeItem("access");
  //   }
  // }, []);
  /**
   * End of comment by - Ashish Dewangan on 05-08-2024
   * Reason - Logged in user logic is not required in this project
   */
  /**
   * End of code addition by - Ashish Dewangan on 08-07-2024
   * Reason - On refresh get user details from backend
   */

  /**
   * Added by - Ashish Dewangan on 08-07-2024
   * Reason - calling api to get user details
   */
  /**
   * Commented by - Ashish Dewangan on 05-08-2024
   * Reason - Logged in user logic is not required in this project
   */
  // const getUserDetails = async (access) => {
  //   const response = await userDetails(access);
  //   if (response.user) {
  //     setUser(response.user);
  //   } else {
  //     const response = await refresh();
  //     if (response.refresh) {
  //       if (!localStorage.getItem("access")) {
  //         localStorage.setItem("access", response.access);
  //       }
  //       else{
  //         localStorage.removeItem("access");
  //       }
  //       // if (localStorage.getItem("access")) {
  //       //   getUserDetails(localStorage.getItem("access"));
  //       // }
  //     }
  //   }
  // };
  /**
   * Commented by - Ashish Dewangan on 05-08-2024
   * Reason - Logged in user logic is not required in this project
   */
  /**
   * End of code addition by - Ashish Dewangan on 08-07-2024
   * Reason - calling api to get user details
   */

  return (
    <>
      <GlobalContext.Provider
        value={{
          user,
          setUser,
          /**
           * Added by - Ashish Dewangan on 08-08-2024
           * Reason - Exported navigationPath and setNavigationPath variables so that they can be used anywhere in the project
           */
          navigationPath,
          setNavigationPath,
          /**
           * End of code addition by - Ashish Dewangan on 08-08-2024
           * Reason - Exported navigationPath and setNavigationPath variables so that they can be used anywhere in the project
           */

          /**
           * Added by - Ashish Dewangan on 16-08-2024
           * Reason - Exported settingDetail and setSettingDetail variables so that they can be used anywhere in the project
           */
          settingDetail,
          setSettingDetail,
          /**
           * End of code addition by - Ashish Dewangan on 16-08-2024
           * Reason - Exported settingDetail and setSettingDetail variables so that they can be used anywhere in the project
           */
        }}>
        {props.children}
      </GlobalContext.Provider>
    </>
  );
}
