// Added by - Jhamman Lal Sahu on 17-08-2024
// Reason - UI to render Terms and conditions details

import React, { useEffect, useState, useContext } from "react";
import Style from "./TermsAndConditions.module.css"
import { termsAndConditionsDetailsApi } from "../../Api/services";
import parse from "html-react-parser";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const TermsAndConditions = () => {
  const [termsAndConditionsDetails, setTermsAndConditionsDetails] = useState({});
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);

  //Added by Jhamman Lal sahu on 17-08-2024
  //Reason - API call to get Terms And Conditions details

  const fetchTermsAndConditionsData = async () => {
    const response = await termsAndConditionsDetailsApi();
    setTermsAndConditionsDetails(response);
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 18-09-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 18-09-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 17-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Terms and conditions", path: "/terms-and-conditions" },
    ]);
    /**
     * End of code addition by - Jhamman lal sahu on 17-08-2024
     * Reason - To add paths to navigation path variable
     */

    fetchTermsAndConditionsData();
  }, []);

  //End of the addition by Jhamman Lal sahu on 17-08-2024
  //Reason - API call to get terms and conditions details

  if(!termsAndConditionsDetails) return <p>No details available</p>;

  return (
    <div className={Style.mainContainer}>
      <div className={Style.subContainer}>
        <NavigationPath navigationPathArray={navigationPath} />

        {termsAndConditionsDetails?.termsAndConditions_details?.title ? (
          <div className={Style.title_field}>
            <div className={Style.title}>
              {parse(termsAndConditionsDetails.termsAndConditions_details.title)}
            </div>
          </div>
        ) : null}

        {termsAndConditionsDetails?.termsAndConditions_details?.description ? (
          <div className={Style.description_field}>
            <div className={Style.description}>
              {parse(termsAndConditionsDetails.termsAndConditions_details.description)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TermsAndConditions;

// End of the addition by Jhamman Lal Sahu on 17-08-2024
// Reason - UI to render Terms And Conditions details
