import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import BasePage from "./pages/BasePage/BasePage";

function App() {
  return (
    <div>
      <Header />

      <BasePage />

      <Footer />
    </div>
  );
}

export default App;
