// Added by - Jhamman Lal Sahu on 31-07-2024
// Reason - UI to render accreditation details

import { accreditation } from "../../../Api/services";
import accreditationStyle from "./Accreditation.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../../Api/config";
import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const Accreditation = () => {
  // Added by - Jhamman Lal Sahu on 31-07-2024
  // Reason - to fetch accreditation details

  const [accreditationDetails, setAccreditationDetails] = useState({});
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  const fetchAccreditationDetails = async () => {
    try {
      const response = await accreditation();
      setAccreditationDetails(response);
    } catch (error) {
      console.error("Error fetching accreditation details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 18-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 18-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },

      /* Commented by Jhamman on 14-09-2024
      Reason - Remove infrastructure from Path*/
      // { name: "Infrastructure", path: "/infrastructure/accreditation" },
      /* End of commentation by Jhamman on 14-09-2024
      Reason - Remove infrastructure from Path*/

      { name: "Accreditation", path: "/infrastructure/accreditation" },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchAccreditationDetails();
  }, []);

  //End of the addition by Jhamman Lal Sahu on 31-07-2024
  //Reason - to fetch accreditation details

  //Commented by jhamman on 30-08-2024
  //Reason - handle it below, with condition.
  //Added by - Jhamman lal sahuon 25-08-2024
  //Reason - to render no details awailable
  // if (
  //   accreditationDetails?.accreditation_details?.banner_image_file &&
  //   accreditationDetails?.accreditation_details?.title_and_description &&
  //   accreditationDetails?.gmpAccreditation_Details?.value.length > 0 &&
  //   accreditationDetails?.gmpApproval_details?.value.length > 0 &&
  //   accreditationDetails?.exportBusiness_details?.value.length > 0 &&
  //   accreditationDetails?.productRegistered_details?.value.length > 0 &&
  //   accreditationDetails?.upcomingCertification_details?.value.length > 0
  // ) {
  //   return (
  //     <div className={accreditationStyle.noDetails}>
  //       <p className={accreditationStyle.noDetailText}>No details available</p>
  //     </div>
  //   );
  // }

  //End of the addition - Jhamman lal sahuon 25-08-2024
  //Reason - to render no details awailable
  //End of the modification by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  return (
    <>
      {showContent ? (
        // Added by Jhamman on 30-08-2024
        //Reason - conditional rendering
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        accreditationDetails?.accreditation_details?.banner_image_file !=
          null ||
        //Added by Jhamman on 01-09-2024
        // Reason - Forget to apply previously
        accreditationDetails?.accreditation_details?.title_and_description ||
        //Added by Jhamman on 01-09-2024
        // Reason - Forget to apply previously
        accreditationDetails?.exportBusiness_details?.value?.logo_or_image !=
          null ||
        accreditationDetails?.gmpAccreditation_Details?.value?.logo_or_image !=
          null ||
        accreditationDetails?.gmpApproval_details?.value?.logo_or_image !=
          null ||
        accreditationDetails?.productRegistered_details?.value?.logo_or_image !=
          null ||
        accreditationDetails?.upcomingCertification_details?.value
          ?.logo_or_image != null ? (
          // End of addition by Jhamman on 30-08-2024
          //Reason - conditional rendering */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          <div className={accreditationStyle.mainContainer}>
            {/* {accreditationDetails?.accreditation_details?.banner_image_file ? (
        <img
          src={
            baseURL +
            accreditationDetails.accreditation_details.banner_image_file
          }
          alt="accreditation"
          className={accreditationStyle.accreditation_banner_Image}
        />
      ) : null} */}

            {/* Modified by - Jhamman lal sahu on 22-08-2024
    Reason - Given an alternative option for banner, it can be image to */}

            {accreditationDetails?.accreditation_details?.banner_image_file ? (
              /\.(mp4|mkv|webm|avi|mov|flv|wmv)$/i.test(
                accreditationDetails?.accreditation_details?.banner_image_file
              ) ? (
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_video_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <video
                  autoPlay
                  muted
                  loop
                  className={accreditationStyle.banner_video}>
                  <source
                    src={`${baseURL}${accreditationDetails?.accreditation_details?.banner_image_file}`}
                    type={`video/${accreditationDetails?.accreditation_details?.banner_image_file
                      .split(".")
                      .pop()}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                /* </div> */
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_image_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <img
                  src={`${baseURL}${accreditationDetails?.accreditation_details?.banner_image_file}`}
                  alt="Banner"
                  className={accreditationStyle.banner_image}
                />
                /* </div> */
              )
            ) : null}

            {/* End of the modification by - Jhamman lal sahu on 22-08-2024
    Reason - Given an alternative option for banner, it can be image to */}

            {/* Added by - Jhamman lal sahu on 22-08-2024
        Reason - To add path in UI */}

            <NavigationPath navigationPathArray={navigationPath} />

            {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}

            <div className={accreditationStyle.subContainer}>
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* accreditationDetails?.accreditation_details
                  ?.title_and_description ||
                accreditationDetails?.exportBusiness_details?.value
                  ?.logo_or_image != null ||
                accreditationDetails?.gmpAccreditation_Details?.value
                  ?.logo_or_image != null ||
                accreditationDetails?.gmpApproval_details?.value
                  ?.logo_or_image != null ||
                accreditationDetails?.productRegistered_details?.value
                  ?.logo_or_image != null ||
                accreditationDetails?.upcomingCertification_details?.value
                  ?.logo_or_image != null ?  */}
              {accreditationDetails?.accreditation_details
                ?.title_and_description ||
              accreditationDetails?.gmpAccreditation_Details?.value?.length >
                0 ||
              accreditationDetails?.exportBusiness_details?.value?.length > 0 ||
              accreditationDetails?.gmpAccreditation_Details?.value?.length >
                0 ||
              accreditationDetails?.gmpApproval_details?.value?.length > 0 ||
              accreditationDetails?.productRegistered_details?.value?.length >
                0 ||
              accreditationDetails?.upcomingCertification_details?.value
                ?.length > 0 ? (
                /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                /* <div className={Style.pageTitle}>Accreditation</div> */
                <div className={accreditationStyle.pageTitle}>
                  <img
                    className={accreditationStyle.headingImage}
                    src="headingdesign.png"
                  />
                  <div className={accreditationStyle.headingTitle}>
                    <span className={accreditationStyle.title1}>Ac</span>
                    <span className={accreditationStyle.title2}>
                      creditation
                    </span>
                  </div>
                </div>
              ) : (
                /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                /*Modified by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
                /* null */
                <div className={accreditationStyle.noDetails}>
                  <p className={accreditationStyle.noDetailText}>
                    No details available
                  </p>
                </div>
              )}
              {/* /* End of modification by Jhamman on 06-09-2024 Reason - Added no
              details available instead of null */ }
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {accreditationDetails?.accreditation_details
                ?.title_and_description && (
                <div className={accreditationStyle.titleAndDescription}>
                  {parse(
                    accreditationDetails.accreditation_details
                      .title_and_description
                  )}
                </div>
              )}
              {/* Added by - Jhamman Lal Sahu on 04-08-2024
        Reason - To render GMP Accreditation Details */}
              <div className={accreditationStyle.miniContainer}>
                {/* Modified by jhamman lal Sahu 0n 22-08-2024
          Reason - Have some UI issues */}
                {accreditationDetails?.gmpAccreditation_Details?.value.length >
                0 ? (
                  <div className={accreditationStyle.titleOfFields}>
                    {accreditationDetails?.gmpAccreditation_Details?.title}
                    {/* Commented by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* {accreditationDetails?.gmpAccreditation_Details?.title} */}
                    {/* End of the commentation by Jhamman lal sahu on 08-08-2024
            Reason - have some issues on UI */}

                    {/* Added by - jhamman lal sahu on 08-08-2024
            Reason - some inhancement applied */}
                    {/* {accreditationDetails?.gmpAccreditation_Details?.value
              ?.logo_or_image
              ? accreditationDetails?.gmpAccreditation_Details?.title
              : null} */}
                    {/* End of the addition by Jhamman lal sahu on 08-08-2024
            reason - Reason - some inhancement applied */}
                  </div>
                ) : null}
                {/* End of the modifications by jhamman lal Sahu 0n 22-08-2024
          Reason - Have some UI issues */}

                <div className={accreditationStyle.titleAndImagesContainer}>
                  {accreditationDetails?.gmpAccreditation_Details?.value?.map(
                    (data, index) => (
                      <div
                        key={index}
                        className={accreditationStyle.titleAndLogo}>
                        <img
                          src={baseURL + data.logo_or_image}
                          alt={data.title}
                          className={accreditationStyle.accreditationImage}
                        />
                        <p className={accreditationStyle.title}>{data.title}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* // End of the addition by Jhamman Lal Sahu on 04-08-2024
        // Reason - To render GMP Accreditation Details */}
              {/* Added by - Jhamman Lal Sahu on 04-08-2024
        Reason - To render GMP Approval Details */}
              <div className={accreditationStyle.miniContainer}>
                {/* Modified by jhamman lal Sahu 0n 22-08-2024
          Reason - Have some UI issues */}
                {accreditationDetails?.gmpApproval_details?.value.length > 0 ? (
                  <div className={accreditationStyle.titleOfFields}>
                    {accreditationDetails?.gmpApproval_details?.title}
                    {/* Commented by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* {accreditationDetails?.gmpApproval_details?.title} */}
                    {/* End of commentation by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* Added by - jhamman lal sahu on 08-08-2024
            Reason - some inhancement applied */}
                    {/* {accreditationDetails?.gmpApproval_details?.value?.logo_or_image
              ? accreditationDetails?.gmpApproval_details?.title
              : null} */}
                    {/* End of the addition by Jhamman lal sahu on 08-08-2024
            reason - Reason - some inhancement applied */}
                  </div>
                ) : null}
                <div className={accreditationStyle.titleAndImagesContainer}>
                  {accreditationDetails?.gmpApproval_details?.value?.map(
                    (data, index) => (
                      <div
                        key={index}
                        className={accreditationStyle.titleAndLogo}>
                        <img
                          src={baseURL + data.logo_or_image}
                          alt={data.title}
                          className={accreditationStyle.accreditationImage}
                        />
                        <p className={accreditationStyle.title}>{data.title}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* // End of the addition by Jhamman Lal Sahu on 04-08-2024
        // Reason - To render GMP Approval Details */}
              {/* Added by - Jhamman Lal Sahu on 04-08-2024
        Reason - To render Export Business Details */}
              <div className={accreditationStyle.miniContainer}>
                {/* Modified by jhamman lal Sahu 0n 22-08-2024
          Reason - Have some UI issues */}
                {accreditationDetails?.exportBusiness_details?.value.length >
                0 ? (
                  <div className={accreditationStyle.titleOfFields}>
                    {accreditationDetails?.exportBusiness_details?.title}
                    {/* Commented by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* {accreditationDetails?.exportBusiness_details?.title} */}
                    {/* End of commentation by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* Added by - jhamman lal sahu on 08-08-2024
            Reason - some inhancement applied */}
                    {/* {accreditationDetails?.exportBusiness_details?.value?.logo_or_image
              ? accreditationDetails?.exportBusiness_details?.title
              : null} */}
                    {/* End of the addition by Jhamman lal sahu on 08-08-2024
            reason - Reason - some inhancement applied */}
                  </div>
                ) : null}
                <div className={accreditationStyle.titleAndImagesContainer}>
                  {accreditationDetails?.exportBusiness_details?.value?.map(
                    (data, index) => (
                      <div
                        key={index}
                        className={accreditationStyle.titleandflag}>
                        <img
                          src={baseURL + data.logo_or_image}
                          alt={data.title}
                          className={accreditationStyle.accreditationImage}
                        />
                        <p className={accreditationStyle.title}>{data.title}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* // End of the addition by Jhamman Lal Sahu on 04-08-2024
        // Reason - To render Export Business Details */}
              {/* Added by - Jhamman Lal Sahu on 04-08-2024
        Reason - To render Product Registered Details */}
              <div className={accreditationStyle.miniContainer}>
                {/* Modified by jhamman lal Sahu 0n 22-08-2024
          Reason - Have some UI issues */}
                {accreditationDetails?.productRegistered_details?.value.length >
                0 ? (
                  <div className={accreditationStyle.titleOfFields}>
                    {accreditationDetails?.productRegistered_details?.title}
                    {/* Commented by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* {accreditationDetails?.productRegistered_details?.title} */}
                    {/* End of commentation by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}

                    {/* Added by - jhamman lal sahu on 08-08-2024
            Reason - some inhancement applied */}
                    {/* {accreditationDetails?.productRegistered_details?.value
              ?.logo_or_image
              ? accreditationDetails?.productRegistered_details?.title
              : null} */}
                    {/* End of the addition by Jhamman lal sahu on 08-08-2024
            reason - Reason - some inhancement applied */}
                  </div>
                ) : null}
                <div className={accreditationStyle.titleAndImagesContainer}>
                  {accreditationDetails?.productRegistered_details?.value?.map(
                    (data, index) => (
                      <div
                        key={index}
                        className={accreditationStyle.titleandflag}>
                        <img
                          src={baseURL + data.logo_or_image}
                          alt={data.title}
                          className={accreditationStyle.accreditationImage}
                        />
                        <p className={accreditationStyle.title}>{data.title}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* // End of the addition by Jhamman Lal Sahu on 04-08-2024
        // Reason - To render Product Registered Details */}
              {/* Added by - Jhamman Lal Sahu on 04-08-2024
        Reason - To render upcoming Certification Details */}
              <div className={accreditationStyle.miniContainer}>
                {/* Modified by jhamman lal Sahu 0n 22-08-2024
          Reason - Have some UI issues */}
                {accreditationDetails?.upcomingCertification_details?.value
                  .length > 0 ? (
                  <div className={accreditationStyle.titleOfFields}>
                    {accreditationDetails?.upcomingCertification_details?.title}
                    {/* Commented by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* {accreditationDetails?.upcomingCertification_details?.title} */}
                    {/* End of commentation by jhamman lal Sahu 0n 08-08-2024
          Reason - Have some UI issues */}
                    {/* Added by - jhamman lal sahu on 08-08-2024
            Reason - some inhancement applied */}
                    {/* {accreditationDetails?.productRegistered_details?.value
              ?.logo_or_image
              ? accreditationDetails?.productRegistered_details?.title
              : null} */}
                    {/* End of the addition by Jhamman lal sahu on 08-08-2024
            reason - Reason - some inhancement applied */}
                  </div>
                ) : null}

                <div className={accreditationStyle.titleAndImagesContainer}>
                  {accreditationDetails?.upcomingCertification_details?.value?.map(
                    (data, index) => (
                      <div
                        key={index}
                        className={accreditationStyle.titleandflag}>
                        <img
                          src={baseURL + data.logo_or_image}
                          alt={data.title}
                          className={accreditationStyle.accreditationImage}
                        />
                        <p className={accreditationStyle.title}>{data.title}</p>
                      </div>
                    )
                  )}
                </div>

                {/* // End of the addition by Jhamman Lal Sahu on 04-08-2024
        // Reason - To render Upcoming Details */}
              </div>
            </div>
          </div>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* ) : ( // Added by Jhamman on 30-08-2024 //Reason - No details available
      <div className={accreditationStyle.noDetails}>
        <p className={accreditationStyle.noDetailText}>No details available</p>
      </div>
      ) // End of the addition by Jhamman on 30-08-2024 /* Reason - No details
      available */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={accreditationStyle.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};
export default Accreditation;

// End of the addition by Jhamman Lal Sahu on 31-07-2024
// Reason - UI to render accreditation details
