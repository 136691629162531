/**
 * Created by - Ashlekh on 13-08-2024
 * Reason - To have Career section
 */
import { useContext, useEffect, useRef, useState } from "react";
import {
  getCareerPageDetailsApi,
  postCareerRequestApi,
} from "../../Api/services";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import style from "./career.module.css";
import config from "../../Api/config";
import parse from "html-react-parser";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const Career = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [careerData, setCareerData] = useState({});
  // Added by - Ashlekh on 13-08-2024
  // Reason - This useEffect will executes when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    careerDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Career", path: "/career" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 13-08-2024
  // Reason - This useEffect will executes when page loads

  const initialValues = {
    name: "",
    email: "",
    countryCode: "91",
    contact_number: "",
    resume: null,
  };

  // Added by - Ashlekh on 13-08-2024
  // Reason - To apply validation in form fields
  const validationSchema = Yup.object({
    contact_number: Yup.number()
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value
      )
      .integer()
      .typeError("Please enter 10 digit mobile number ")
      .required("Please enter mobile number")
      .min(1000000000, "Please enter 10 digit mobile number ")
      .max(9999999999, "Please enter 10 digit mobile number "),
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9\u0900-\u097F\s]+$/,
        "Special characters are not allowed"
      )
      .required("Please enter name"),
    email: Yup.string().trim().email("Please enter email in correct format"),
    resume: Yup.mixed()
      .required("Please select a file")
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) =>
          !value ||
          (value &&
            [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(value.type))
      )
      .test(
        "fileSize",
        "File size is too large, should be less than 5MB",
        (value) => !value || (value && value.size <= 5 * 1024 * 1024)
      ),
  });
  // End of code - Ashlekh on 13-08-2024
  // Reason - To apply validation in form fields

  // Added by - Ashlekh on 13-08-2024
  // Reason - To display validation message
  const renderError = (message) => (
    <span className={`${style.validationMsgContainer}`}>{message}</span>
  );
  // End of code - Ashlekh on 13-08-2024
  // Reason - To display validation message

  const fileInputRef = useRef(null);

  const onSubmit = (values, { resetForm }) => {
    submitCareerRequest(values);
    resetForm();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const FileInput = ({ field, form, innerRef }) => {
    const handleChange = (event) => {
      form.setFieldValue(field.name, event.currentTarget.files[0]);
    };

    return (
      <input
        type="file"
        onChange={handleChange}
        accept=".pdf,.doc,.docx"
        ref={innerRef}
        className={`${style.fileInput}`}
      />
    );
  };

  // Added by - Ashlekh on 13-08-2024
  // Reason - Post API to submit form details
  async function submitCareerRequest(values) {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);

    //Added by Jhamman on 03-09-2024
    // Reason - added country code
    formData.append("countryCode", values.countryCode);
    //End of addition by Jhamman on 03-09-2024
    // Reason - added country code

    formData.append("contact_number", values.contact_number);
    formData.append("resume", values.resume);
    const response = await postCareerRequestApi(formData);
    if (response.message) {
      notificationObject.success(response.message);
    } else if (response.error) {
      notificationObject.dismissAll();
      notificationObject.error(response.error);
    }
  }

  // End of code - Ashlekh on 13-08-2024
  // Reason - Post API to submit form details

  // Added by - Ashlekh on 13-08-2024
  // Reason - To get career details from backend
  const careerDetails = async () => {
    try {
      const response = await getCareerPageDetailsApi();
      setCareerData(response?.career_information);
    } catch (error) {
      console.error("Error fetching career details:", error);
    }
  };

  // End of code - Ashlekh on 13-08-2024
  // Reason - To get career details from backend
  return (
    <div className={`${style.mainContainer}`}>
      {careerData?.banner_image != null ? (
        <div className={`${style.bannerImageContainer}`}>
          <img
            className={`${style.bannerImage}`}
            src={config.baseURL + careerData?.banner_image}
            alt=""
          />
        </div>
      ) : null}
      {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
      <NavigationPath navigationPathArray={navigationPath} />
      {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
      {/* Added by - Ashlekh on 07-09-2024
        Reason - To add page title */}
      {/* Modified by Jhamman on 05-10-2024
      Reason - Changed the style of heading */}
      {/* <div className={`${style.pageTitle}`}>Career</div> */}
      <div className={style.pageTitle}>
        <img className={style.headingImage} src="headingdesign.png" />
        <div className={style.headingTitle}>
          <span className={style.title1}>Ca</span>
          <span className={style.title2}>reer</span>
        </div>
      </div>
      {/* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/}
      {/* End of code - Ashlekh on 07-09-2024
        Reason - To add page title */}
      <div className={`${style.subContainer}`}>
        {careerData?.description &&
        careerData?.description?.trim()?.length > 0 ? (
          <div className={`${style.descriptionContainer}`}>
            {/* {careerData?.description != null ? ( */}
            <div className={`${style.description}`}>
              {" "}
              {parse(careerData?.description)}{" "}
            </div>
            {/* ) : null} */}
          </div>
        ) : null}

        <div className={`${style.formSubContainer}`}>
          <div className={`${style.formTitle}`}>
            Please complete the form below and we will contact you shortly:-
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}>
            <Form noValidate={true}>
              {/* Added by Jhamman on 22-09-2024
            Reason - error is not rendering properly*/}
              <div className={`${style.inputAndErrorContainer}`}>
                {/* Added by by Jhamman on 22-09-2024
            Reason - error is not rendering properly*/}
                <div className={`${style.inputHolder}`}>
                  <label className={`${style.label}`}>
                    Name
                    <span style={{ color: "red" }}> * </span>
                  </label>
                  <div className={`${style.colon}`}> : </div>
                  <div className={`${style.inputWithError}`}>
                    <Field
                      name="name"
                      placeholder="Your name"
                      type="text"
                      className={`${style.inputBox}`}
                    />

                    {/* Commented by Jhamman on 22-09-2024
            Reason - rendered below*/}
                    {/* <ErrorMessage name="name" render={renderError} /> */}
                    {/* End of commentation by Jhamman on 22-09-2024
            Reason - rendred below*/}
                  </div>
                </div>
                <ErrorMessage name="name" render={renderError} />
              </div>

              {/* Added by Jhamman on 22-09-2024
            Reason - error is not rendering properly*/}
              <div className={`${style.inputAndErrorContainer}`}>
                {/* Added by by Jhamman on 22-09-2024
            Reason - error is not rendering properly*/}
                <div className={`${style.inputHolder}`}>
                  <label className={`${style.label}`}>
                    Mobile Number
                    <span style={{ color: "red" }}> * </span>
                  </label>
                  <div className={`${style.colon}`}> : </div>
                  {/* <div className={style.country_code_and_mobile_number}> */}

                  <div className={`${style.inputWithError}`}>
                    {/* Added by Jhamman on 03-09-2024
                  Reason - added country code */}
                    <Field
                      name="countryCode"
                      type="number"
                      className={`${style.countryCodebox}`}
                      /* Added by jhamman on 05-10-2024
                          Reason - Added max length */
                      onInput={(e) => {
                        if (e.target.value.length > 5) {
                          e.target.value = e.target.value.slice(0, 5); 
                        }
                      }}
                      /* End of addition by jhamman on 05-10-2024
                          Reason - Added max length */
                    />

                    {/* End of addition by Jhamman on 03-09-2024
                  Reason - added country code */}
                    <Field
                      name="contact_number"
                      placeholder="Mobile number"
                      type="number"
                      className={`${style.contactNumberInputBox}`}
                    />
                  </div>

                  {/* Commented by Jhamman on 22-09-2024
                        Reason - rendered below*/}
                  {/* <ErrorMessage name="name" render={renderError} /> */}
                  {/* End of commentation by Jhamman on 22-09-2024
                    Reason - rendred below*/}
                </div>
                <ErrorMessage name="contact_number" render={renderError} />
              </div>

              {/* Added by Jhamman on 22-09-2024
                  Reason - error is not rendering properly*/}
              <div className={`${style.inputAndErrorContainer}`}>
                {/* Added by by Jhamman on 22-09-2024
                    Reason - error is not rendering properly*/}
                <div className={`${style.inputHolder}`}>
                  <label
                    className={`${style.label}`}
                    style={{ paddingLeft: "1px" }}>
                    Email
                  </label>
                  <div className={`${style.colon}`}> : </div>
                  <div className={`${style.inputWithError}`}>
                    <Field
                      name="email"
                      placeholder="Email"
                      type="text"
                      className={`${style.inputBox}`}
                    />

                    {/* Commented by Jhamman on 22-09-2024
                        Reason - rendered below*/}
                    {/* <ErrorMessage name="name" render={renderError} /> */}
                    {/* End of commentation by Jhamman on 22-09-2024
                        Reason - rendred below*/}
                  </div>
                </div>
                <ErrorMessage name="email" render={renderError} />
              </div>

              {/* Added by Jhamman on 22-09-2024
                  Reason - error is not rendering properly*/}
              <div className={`${style.inputAndErrorContainer}`}>
                {/* Added by by Jhamman on 22-09-2024
                    Reason - error is not rendering properly*/}
                <div className={`${style.inputHolder}`}>
                  <label className={`${style.label}`}>
                    Upload Resume
                    <span style={{ color: "red" }}> * </span>
                  </label>
                  <div className={`${style.colon}`}> : </div>
                  <div className={`${style.inputWithError}`}>
                    <Field
                      name="resume"
                      component={FileInput}
                      innerRef={fileInputRef}
                    />

                    {/* Commented by Jhamman on 22-09-2024
                        Reason - rendered below*/}
                    {/* <ErrorMessage name="name" render={renderError} /> */}
                    {/* End of commentation by Jhamman on 22-09-2024
                        Reason - rendred below*/}
                  </div>
                </div>
                <span className={style.tip}>
                  (Note: Upload the file in Word/Pdf format )
                </span>
                <ErrorMessage name="resume" render={renderError} />
              </div>
              <div
                className={`${style.buttonContainer}`}
                style={{
                  paddingTop: "2%",
                  display: "flex",
                  justifyContent: "center",
                }}>
                <button className={`${style.button}`} type="submit">
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default Career;
