/**
 * Created by - Ashish Dewangan on 08-07-2024
 * Reason - Created home page
 */
import { useContext, useEffect, useState } from "react";

import homeStyle from "./Home.module.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/Context";
import { homepage } from "../../Api/services";
import config, { baseURL } from "../../Api/config";
import { FaCheck } from "react-icons/fa";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import { IoStar } from "react-icons/io5";

const Home = (props) => {
  const [homepageDetails, setHomepageDetails] = useState(null);
  const [whyUsStrength, setWhyUsStrength] = useState({});
  const [whyUsPointers, setWhyUsPointers] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  // Added by - Ashlekh on 14-08-2024
  // Reason - To add useState for company group and gallery youtube video
  const [companyGroup, setCompanyGroup] = useState([]);
  const [galleryVideo, setGalleryVideo] = useState([]);
  // End of code - Ashlekh on 14-08-2024
  // Reason - To add useState for company group and gallery youtube video

  var colorIndex = 0;
  // Added by - Ashlekh on 17-08-2024
  // Reason - To have useState for HomePageCustomerAndProduct and HomePageDropDown
  const [homePageCustomerAndProduct, setHomePageCustomerAndProduct] = useState(
    {}
  );
  const [homePageDropDown, setHomePageDropDown] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(-1);
  const [isToggled, setIsToggled] = useState(false);
  // const [companyInformation, setCompanyInformation] = useState({});
  // End of code - Ashlekh on 17-08-2024
  // Reason - To have useState for HomePageCustomerAndProduct and HomePageDropDown

  const navigate = useNavigate();
  const { user, setUser } = useContext(GlobalContext);

  // Added by Jhamman on 30-08-2024
  // Reason - to set loader
  const [isLoading, setIsLoading] = useState(true);
  // End of addition by Jhamman on 30-08-2024
  // Reason - to set loader

  // Added by - Ashlekh on 13-09-2024
  // Reason - To add useState for storing banner for home page
  const [companyGroupBanner, setCompanyGroupBanner] = useState([]);
  // End of code - Ashlekh on 13-09-2024
  // Reason - To add useState for storing banner for home page

  /**
   * Added by - Ashish Dewangan on 12-08-2024
   * Reason - to set slider item's responsive width and color
   */
  var colors = [
    // Code changed by - Ashlekh on 26-08-2024
    // Reason - To change color code
    // "#f59d00",
    // "#227fbb",
    // "#1aaf5d",
    // // "#c23824",
    // "#8f3faf",
    // "#2b3e51",
    // "#00a185",
    // // "#f2c500",

    /* Commented by Jhamman on 15-09-2024
    Reason - colors are not attrective*/
    // "#F5F5DC",
    // "#FFF8B2",
    // "#FAF0E6",
    // "#FFF8DC",
    // "#EEE8CD",
    // "#F5DEB3",
    /* End of commentation by Jhamman on 15-09-2024
    Reason - colors are not attrective*/

    // ("#8CC2E8"),
    // ("#A8E2B6"),
    // ("#F6A6A2"),
    // ("#F9D7B8"),
    // ("#7BCCB5"),
    // ("#E0F4F4"),

    // "#6AB9E2",
    // "#8DE0A0",
    // "#F67C6C",
    // "#F7C6A3",
    // "#5CC4A7",
    // "#CFF7F7",

    // '#F59D01',
    // '#227FBB',
    // '#2EAF5D',
    // '#C23825',
    // '#8F3FAF',
    // '#2B3F51',
    // '#2B3E51',
    // '#2AA185',
    // '#F2C502',

    "#227fbb",
    "#1aaf5d",
    "#8f3faf",
    "#2b3e51",
    "#00a185",
    "#f2c500",

    // "#4A9EE2",
    // "#6DE2A0",
    // "#F55C5C",
    // "#F7B587",
    // "#3CC1A3",
    // "#B3F7F7",

    // "#e3f2fd",
    // "#ffebee",
    // "#fff9c4",
    // "#e8f5e9",
    // "#f3e5f5",
    // "#dbe0e6",
    // End of code - Ashlekh on 26-08-2024
    // Reason - To change color code
  ];

  const getColor = () => {
    /**
     * Modified by - Ashish Dewangan on 21-08-2024
     * Reason - Because of random colors, some colors were shown multiple time one after another
     */
    // return colors[Math.floor(Math.random() * colors.length)];
    if (colorIndex < 6) {
      var color = colors[colorIndex];
      colorIndex++;
      return color;
    } else {
      colorIndex = 0;
      var color = colors[colorIndex];
      colorIndex++;

      return color;
    }
    /**
     * End of modification by - Ashish Dewangan on 21-08-2024
     * Reason - Because of random colors, some colors were shown multiple time one after another
     */
  };

  const [sliderRef, instanceRef] = useKeenSlider({
    /* Modified by Jhamman on 22-09-2024
    Reason - Changes min width and perview*/
    breakpoints: {
      "(min-width: 0px)": {
        slides: { perView: 1, spacing: 5 },
      },
      /* Added by Jhamman on 16-09-2024
      Reason - Preview two products on min width 300px*/
      "(min-width: 250px)": {
        slides: { perView: 2, spacing: 10 },
      },
      "(min-width: 300px)": {
        slides: { perView: 2, spacing: 40 },
      },
      /* End of addition by Jhamman on 16-09-2024
      Reason - Preview two products on min width 300px*/

      /* Modified by Jhamman on 16-09-2024
      Reason - Preview two products on min width 360px*/
      // "(min-width: 360px)": {
      //   slides: { perView: 1, spacing: 5 },
      // },

      // "(min-width: 360px)": {
      //   slides: { perView: 3, spacing: 5 },
      // },
      // "(min-width: 275px)": {
      //   slides: { perView: 3, spacing: 15 },
      // },
      /* End of modification by Jhamman on 16-09-2024
      Reason - Preview two products on min width 360px*/
      // "(min-width: 400px)": {
      //   slides: { perView: 2, spacing: 5 },
      // },
      "(min-width:350px)": {
        slides: { perView: 3, spacing: 10 },
      },
      // "(min-width: 600px)": {
      //   slides: { perView: 3, spacing: 5 },
      // },
      "(min-width: 450px)": {
        slides: { perView: 4, spacing: 5 },
      },
      // "(min-width: 720px)": {
      //   slides: { perView: 4, spacing: 10 },
      // },
      "(min-width: 800px)": {
        slides: { perView: 5, spacing: 10 },
      },
      // "(min-width: 900px)": {
      //   slides: { perView: 5, spacing: 10 },
      // },
      "(min-width: 1000px)": {
        slides: { perView: 6, spacing: 10 },
      },
      // "(min-width: 1200px)": {
      //   slides: { perView: 6, spacing: 10 },
      // },

      "(min-width: 1200px)": {
        slides: { perView: 7, spacing: 5 },
      },
      "(min-width: 1400px)": {
        slides: { perView: 8, spacing: 5 },
      },

      /* Commented by Jhamman on 25-09-2024
      Reason - we have just 8 products*/
      // "(min-width: 1400px)": {
      //   slides: { perView: 12, spacing: 5 },
      // },
      /* End of commentation by Jhamman on 25-09-2024
      Reason - we have just 8 products*/
    },
    // slides: { perView: 1 },
    slides: { perView: 2 },
    /* End of modification by Jhamman on 22-09-2024
    Reason - Changes min width and perview*/
    // Added by - Ashlekh on 09-09-2024
    // Reason - To start autoplay
    loop: true,
    // End of code - Ashlekh on 09-09-2024
    // Reason - To start autoplay
  });
  /**
   * End of code addition by - Ashish Dewangan on 12-08-2024
   * Reason - to set slider item's responsive width and color
   */

  {
    /* Commented by - Ashish Dewangan on 05-08-2024
          Reason - logged in user based redirection logic is not required in this project */
  }
  // useEffect(() => {
  //   if (localStorage.getItem("access")) {
  //   } else {
  //     localStorage.clear();
  //     setUser({});
  //     navigate("/login");
  //   }
  // }, []);
  {
    /* End of comment by - Ashish Dewangan on 05-08-2024
          Reason - logged in user based redirection logic is not required in this project */
  }

  const fetchData = async () => {
    // Function component to fetch homepage Details from backend
    try {
      const response = await homepage();
      setHomepageDetails(response.homepage_details);
      /**
       * Added by - Ashish Dewangan on 11-08-2024
       * Reason - To set values in variables for why us section
       */
      setWhyUsStrength(response.why_us_strength);
      setWhyUsPointers(response.why_us_pointers);
      /**
       * End of code addition by - Ashish Dewangan on 11-08-2024
       * Reason - To set values in variables for why us section
       */

      /**
       * Added by - Ashish Dewangan on 12-08-2024
       * Reason - To set values in variables for item types section
       */
      setItemTypes(response.item_types);
      /**
       * End of code addition by - Ashish Dewangan on 12-08-2024
       * Reason - To set values in variables for item types section
       */
      /**
       * Added by - Ashlekh on 14-08-2024
       * Reason - To set data in companyGroup and in galleryVideo
       */
      setCompanyGroup(response?.company_group);
      setGalleryVideo(response?.gallery_video);
      /**
       * End of code - Ashlekh on 14-08-2024
       * Reason - To set data in companyGroup and in galleryVideo
       */
      /**
       * Added by - Ashlekh on 17-08-2024
       * Reason - To set response in homePageCustomerAndProduct and homePageDropDown
       */
      setHomePageCustomerAndProduct(response?.home_page_customer_and_product);
      setHomePageDropDown(response?.home_page_drop_down);
      // setCompanyInformation(response?.home_page_company_information);
      /**
       * End of code - Ashlekh on 17-08-2024
       * Reason - To set response in homePageCustomerAndProduct and homePageDropDown
       */
      /**
       * Added by - Ashlekh on 13-09-2024
       * Reason - To set response in homePageLinkAndImage
       */
      setCompanyGroupBanner(response?.home_page_link_image);
      /**
       * End of code - Ashlekh on 13-09-2024
       * Reason - To set response in homePageLinkAndImage
       */
    } catch (error) {
      console.error("Error fetching homepage details:", error);
    } finally {
      // Added by Jhamman on 30-08-2024
      // Reason - To handle the case when manufacturing details are not available
      setIsLoading(false);
    }
    // End of addition by Jhamman on 30-08-2024
    // Reason - To handle the case when manufacturing details are not available
  };

  useEffect(() => {
    /**
     * Added by - Ashish Dewangan on 17-08-2024
     * Reason - To scroll to the top of the page when page loads
     */
    window.scrollTo(0, 0);
    /**
     * End of code addition by - Ashish Dewangan on 17-08-2024
     * Reason - To scroll to the top of the page when page loads
     */
    fetchData();
  }, []);

  // Added by - Ashlekh on 14-08-2024
  // Reason - To handle carousel in different breakpoints (youtube video)
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1201 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 1101 },
      items: 3,
      slidesToSlide: 2,
    },
    tablet2: {
      breakpoint: { max: 1100, min: 901 },
      items: 2,
      slidesToSlide: 2,
    },
    tablet3: {
      breakpoint: { max: 900, min: 721 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 720, min: 601 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile2: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  // End of code - Ashlekh on 14-08-2024
  // Reason - To handle Carousel in different breakpoints (youtube video)

  // Added by - Ashlekh on 17-08-2024
  // Reason - To handle dropdown (open/close)
  const toggleAnswer = (index) => {
    if (openDropDown === index) {
      setIsToggled(!isToggled);
      setOpenDropDown(null);
    } else {
      setOpenDropDown(index);
      setIsToggled(true);
    }
  };
  // End of code - Ashlekh on 17-08-2024
  // Reason - To handle drop down (open/close)

  // Commented by Jhamman on 30-08-2024
  // Reason - no longer need

  // if (!homepageDetails) return <p>No details available</p>;

  // End of commentation by Jhamman on 30-08-2024
  // Reason - no longer need

  // Added by - Ashlekh on 09-09-2024
  // Reason - To add autoplay
  useEffect(() => {
    const interval = setInterval(() => {
      instanceRef.current?.next();
    }, 3000);

    return () => clearInterval(interval);
  }, [instanceRef]);
  // End of code - Ashlekh on 09-09-2024
  // Reason - To add autoplay

  // Added by - Ashlekh on 13-09-2024
  // Reason - To handle Carousel in responsive for group of company banner
  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1201 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 1101 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet2: {
      breakpoint: { max: 1100, min: 901 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet3: {
      breakpoint: { max: 900, min: 721 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 601 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile2: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  // End of code - Ashlekh on 13-09-2024
  // Reason - To handle Carousel in responsive for group of company banner
  return (
    // Added by - Jhamman on  30-08-2024
    // Reason - Added loader
    <>
      {isLoading ? (
        <div
          className={homeStyle.loaderContent}

          // Commented by Jhamman on 01-09-2024
          // reason - define it in css file
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   minHeight: "90vh",
          // }}
          // End of commentation by Jhamman on 01-09-2024
          // reason - define it in css file
        >
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      ) : (
        // End of addition by - Jhamman on  30-08-2024
        // Reason - Added loader

        <div className={homeStyle.mainContainer}>
          {homepageDetails?.banner_video_file ? (
            <video autoPlay muted loop className={homeStyle.banner_video}>
              <source
                src={`${baseURL}${homepageDetails.banner_video_file}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : null}

          {/* 
        Added by - Ashish Dewangan on 11-08-2024
        Reason - To show item type section
      */}
          {itemTypes?.length > 0 && (
            <div className={homeStyle.itemTypesSection}>
              <div ref={sliderRef} className="keen-slider">
                {itemTypes.map((itemType, index) => {
                  return (
                    /* Added by Jhamman on 28-09-2024
                    Reason - when click on any product then we navigate in product screen*/
                    <Link
                      className={homeStyle.productListLink}
                      to={"/all-products"}
                      rel="noopener noreferrer">
                      {/* Added by Jhamman on 28-09-2024
                       Reason - when click on any product then we navigate in product screen */}
                      <div className="keen-slider__slide">
                        <div className={homeStyle.productList}>
                          <div
                            className={homeStyle.itemType}
                            style={{ backgroundColor: getColor() }}>
                            <img
                              className={homeStyle.itemTypeImage}
                              src={
                                itemType?.logo?.length > 0
                                  ? baseURL + itemType.logo
                                  : "medicine_1.png"
                              }
                            />
                            <div className={homeStyle.itemTypeName}>
                              {itemType.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}

          {/* 
        End of code addition by - Ashish Dewangan on 11-08-2024
        Reason - To show item type section
      */}

          {/* Added by - Jhamman on 07-09-2024
            Reason- Define it above because client said*/}

          {/* Added by - Ashlekh on 17-08-2024
      Reason - To add customer and products section */}
          {/* Code changed by - Ashlekh on 27-08-2024
      Reason - to add title from backend */}
          <div className={`${homeStyle.customerAndProduct}`}>
            {
              // homePageCustomerAndProduct.description ||
              // homePageCustomerAndProduct?.description?.trim()?.length > 0 ||
              // homePageCustomerAndProduct?.image != null ? (
              homePageCustomerAndProduct.title ||
              homePageCustomerAndProduct?.title?.trim()?.length > 0 ? (
                <div className={`${homeStyle.customerAndProductTitle}`}>
                  {/* WE ARE DEDICATED TO OUR CUSTOMERS AND PRODUCTS */}
                  {homePageCustomerAndProduct.title}
                </div>
              ) : null
            }
            {/* End of code - Ashlekh on 27-08-2024
        Reason - To add title from backend */}

            <div className={`${homeStyle.customerAndProductContainer}`}>
              {/* Added by - Ashlekh on 12-09-2024
              Reason - To apply condition for image and description (if both are not present then this div will not display) */}
              {homePageCustomerAndProduct?.image != null ||
              homePageCustomerAndProduct?.description ||
              homePageCustomerAndProduct?.description?.trim()?.length > 0 ? (
                // End of code - Ashlekh on 12-09-2024
                // Reason - To apply condition for image and description (if both are not present then this div will not display)
                <div className={`${homeStyle.customerAndProductSubContainer}`}>
                  {homePageCustomerAndProduct?.image != null ? (
                    <div
                      className={`${homeStyle.customerAndProductImageContainer}`}>
                      <img
                        className={`${homeStyle.customerAndProductImage}`}
                        src={config.baseURL + homePageCustomerAndProduct?.image}
                        alt=""
                      />
                    </div>
                  ) : null}

                  <div className={`${homeStyle.descriptionContainer}`}>
                    {homePageCustomerAndProduct?.description}
                  </div>
                </div>
              ) : null}

              <div className={`${homeStyle.dropDownContainer}`}>
                {homePageDropDown?.map((item, index) => (
                  <div
                    key={index}
                    className={`${homeStyle.dropDownSubContainer}`}>
                    <div className={`${homeStyle.titleContainer}`}>
                      <div
                        className={`${homeStyle.dropdownTitle}`}
                        onClick={() => toggleAnswer(index)}>
                        <div
                          className={`${homeStyle.titleSubContainer} ${
                            openDropDown === index && isToggled
                              ? homeStyle.active
                              : homeStyle.inactive
                          }`}>
                          <div> {item.title} </div>
                          {/* <div
                        className={`${homeStyle.iconContainer} ${
                          openDropDown === index
                            ? homeStyle.open
                            : homeStyle.closed
                        }`}
                      >
                        &#x25BC;
                      </div> */}
                          <div className={`${homeStyle.iconContainer}`}>
                            {openDropDown === index ? "-" : "+"}
                          </div>
                        </div>
                      </div>
                    </div>
                    {openDropDown === index && (
                      <div
                        className={`${homeStyle.dropDownDescriptionContainer}`}>
                        <img
                          className={`${homeStyle.dropDownDescriptionImage}`}
                          // Added by - Ashlekh on 29-08-2024
                          // Reason - To display dummy image, if not added from backend
                          // src={config.baseURL + item?.image}
                          src={
                            item?.image
                              ? `${config.baseURL + item?.image}`
                              : "/RightArrowImage.jpeg"
                          }
                          // End of code - Ashlekh on 29-08-2024
                          // Reason - To display dummy image, if not added from backend
                          alt=""
                        />
                        <div className={`${homeStyle.dropDownDescription}`}>
                          {item?.description}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End of code - Ashlekh on 17-08-2024
          Reason - To add customer and products section */}

          {/* End of addition by - Jhamman on 07-09-2024
            Reason- Define it above because client said*/}

          {/* 
        Added by - Ashish Dewangan on 11-08-2024
        Reason - To show why us section
      */}
          {whyUsPointers?.length > 0 ? (
            <>
              {/*Modified by Jhamman lal sahu on 28-08-2024
            Reason - changed style */}
              {/* <div className={`${homeStyle.title}`}>WHY US</div> */}
              <div className={`${homeStyle.title}`}>Why Us</div>
              {/*End of modification by Jhamman lal sahu on 28-08-2024
            Reason - changed style */}
              <div
                className={homeStyle.whyUsSection}
                style={{
                  //Modified by Jhamman lal sahu on 28-08-2024
                  //Reason - Image is not rendering proper in others system
                  // backgroundImage: `url(${baseURL}/${whyUsStrength.background_image})`,
                  backgroundImage:
                    "url(" + baseURL + whyUsStrength.background_image + ")",
                  //End of modification by Jhamman lal sahu on 28-08-2024
                  //Reason - Image is not rendering proper in others system
                }}>
                <div className={homeStyle.whyUsPointersContainer}>
                  {whyUsPointers.map((pointer, index) => {
                    {
                      return (
                        index < 8 &&
                        pointer?.description?.trim()?.length > 0 && (
                          <div className={homeStyle.whyUsePointer} key={index}>
                            {pointer?.icon?.trim()?.length > 0 ? (
                              <img
                                src={baseURL + pointer.icon}
                                className={homeStyle.whyUsePointerIcon}
                              />
                            ) : (
                              /* Modified by Jhamman on 04-10-2024
                              Reason - changed image */
                              /* <img
                                src={"RightArrowImage.jpeg"}
                                className={homeStyle.whyUsePointerIcon}
                              /> */
                              <IoStar className={homeStyle.whyUsePointerIcon} />
                              /* End of modification by Jhamman on 04-10-2024
                              Reason - changed image */
                            )}
                            {/* <FaCheck className={homeStyle.whyUsePointerIcon} /> {" "} */}
                            {/* Code changed by - Ashlekh on 16-09-2024
                            Reason - To remove html tag */}
                            {/* {pointer.description} */}
                            <div className={`${homeStyle.whyUseDescription}`}>
                              {parse(pointer?.description)}
                            </div>
                            {/* End of code - Ashlekh on 16-09-2024
                            Reason - To remove html tag */}
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              </div>
            </>
          ) : null}
          {/* 
        End of code addition by - Ashish Dewangan on 11-08-2024
        Reason - To show why us section
      */}

          {/* Commented by jhamman on 07-09-2024
      Reason - Define it above footer*/}
          {/* Added by - Ashlekh on 14-08-2024
      Reason - To display 9M Group of company */}
          {/* {companyGroup?.length > 0 ? (
            <div className={`${homeStyle.companyGroup}`}>
              <div className={`${homeStyle.title}`}>9M GROUP OF COMPANY</div>
              <div className={`${homeStyle.imageContainer}`}>
                {companyGroup?.map((item, index) => (
                  <div key={index} className={`${homeStyle.imageSubContainer}`}>
                    <img
                      className={`${homeStyle.image}`}
                      src={config.baseURL + item?.image}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null} */}
          {/* End of code - Ashlekh on 14-08-2024
      Reason - To display 9M Group of company */}
          {/* End of commentation by jhamman on 07-09-2024
      Reason - Define it above footer*/}

          {/* Added by - Ashlekh on 14-08-2024
      Reason - To display gallery video */}
          <div className={`${homeStyle.videoContainer}`}>
            <Carousel responsive={responsive}>
              {galleryVideo?.map((item, index) => {
                return (
                  <div key={index}>
                    <ReactPlayer
                      className={`${homeStyle.video}`}
                      url={item?.video}
                      controls={true}
                      width={290}
                      height={175}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          {/* End of code - Ashlekh on 14-08-2024
      Reason - To display gallery video */}

          {/* Added by - Jhamman on 07-09-2024
          Reason - Display it above footer */}
          {/* {companyGroup?.length > 0 ? (
            <div className={`${homeStyle.companyGroup}`}>
              <div className={`${homeStyle.title}`}>9M GROUP OF COMPANY</div>
              <div className={`${homeStyle.imageContainer}`}>
                {companyGroup?.map((item, index) => (
                  <div key={index} className={`${homeStyle.imageSubContainer}`}>
                    <img
                      className={`${homeStyle.image}`}
                      src={config.baseURL + item?.image}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null} */}
          {companyGroup?.length > 0 ? (
            <div className={`${homeStyle.companyGroup}`}>
              <div className={`${homeStyle.title}`}>9M GROUP OF COMPANY</div>
              <div className={`${homeStyle.imageContainer}`}>
                {companyGroup.map((item, index) => (
                  <div key={index} className={`${homeStyle.imageSubContainer}`}>
                    <img
                      className={`${homeStyle.image}`}
                      src={config.baseURL + item?.image}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* End of addition by - Jhamman on 07-09-2024
          Reason - Display it above footer */}
          {/* Added by - Ashlekh on 13-09-2024
          Reason - To display 9M Group of company banner */}
          {companyGroupBanner?.length > 0 ? (
            <div className={`${homeStyle.companyGroupBannerContainer}`}>
              <Carousel responsive={responsive2}>
                {companyGroupBanner?.map((item, index) => {
                  return (
                    /* Modifed by Jhamman on 22-09-2024
                    Reason - when click on link then open new tab*/
                    /* <Link to={item?.link}> */
                    <Link
                      style={{ display: "block" }}
                      to={item?.link}
                      target="_blank"
                      rel="noopener noreferrer">
                      {/* End of modification by Jhamman on 22-09-2024
                    Reason - when click on link then open new tab*/}
                      <img
                        className={`${homeStyle.companyGroupBanner}`}
                        src={config.baseURL + item?.image}
                        alt=""
                      />
                    </Link>
                  );
                })}
              </Carousel>
            </div>
          ) : null}

          {/* End of code - Ashlekh on 13-09-2024
          Reason - To display 9M Group of company banner */}
        </div>
      )}
    </>
  );
};

export default Home;
