// Added by - Ashish Dewangan on 07-08-2024
// Reason - UI to render Global Presence details

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { globalPresence } from "../../Api/services";
import globalPresenceStyle from "./GlobalPresence.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../Api/config";
import Globe from "react-globe.gl";
import { Modal } from "antd";
import { Tabs } from "antd";
import CountryCard from "./CountryCard";
import { GlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";
import NavigationPath from "../../components/NavigationPath/NavigationPath";
import { AiOutlineExclamation } from "react-icons/ai";
import { IoInformationCircleOutline } from "react-icons/io5";

const GlobalPresence = () => {
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);

  const [globalPresencePageDetails, setGlobalPresencePageDetails] =
    useState("");
  const [globalPresenceCountries, setGlobalPresenceCountries] = useState([]);
  const [globeData, setGlobeData] = useState([]);

  const globeElement = useRef();
  const [globeComponentWidth, setGlobeComponentWidth] = useState(
    window.innerWidth
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});

  const [countryTabs, setCountryTabs] = useState([]);

  const [toolTipForGlobeForMobile, setToolTipForGlobeForMobile] =
    useState(false);

  const [showContent, setShowContent] = useState(false);

  const MarkerSvg = `<svg viewBox="-4 0 36 36">
    <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
    <circle fill="black" cx="14" cy="14" r="7"></circle>
  </svg>`;

  /**
   * Added by - Ashish Dewangan on 07-08-2024
   * Reason - calling get API to get global presence page details
   */
  const fetchGlobalPresenceData = async () => {
    try {
      const response = await globalPresence();
      setGlobalPresencePageDetails(
        response.global_presence_page_details.page_content
      );
      setGlobalPresenceCountries(response.global_presence_countries);
    } catch (error) {
      console.error("Error fetching global presence details:", error);
    } finally {
      /* Addition by jhamman on 27-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 27-09-2024
    Reason - show nothing when data is loading*/
  };
  /**
   * End of code addition by - Ashish Dewangan on 07-08-2024
   * Reason - calling get API to get global presence page details
   */

  /**
   * Added by - Ashish Dewangan on 07-08-2024
   * Reason - calling fetchGlobalPresenceData on page load,
   * that will call API to get global presence page details.
   */
  useEffect(() => {
    /**
     * Added by - Ashish Dewangan on 08-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Global Presence", path: "/global-presence" },
    ]);

    /**
     * End of code addition by - Ashish Dewangan on 08-08-2024
     * Reason - To add paths to navigation path variable
     */

    /**
     * Added by - Ashish Dewangan on 17-08-2024
     * Reason - To scroll to the top of the page when page loads
     */
    window.scrollTo(0, 0);
    /**
     * End of code addition by - Ashish Dewangan on 17-08-2024
     * Reason - To scroll to the top of the page when page loads
     */

    fetchGlobalPresenceData();
  }, []);
  /**
   * End of code addition by - Ashish Dewangan on 07-08-2024
   * Reason - calling fetchGlobalPresenceData on page load,
   * that will call API to get global presence page details.
   */

  /**
   * Added by - Ashish Dewangan on 07-08-2024
   * Reason - To create custom data for world globe from globalPresenceCountries
   */
  useEffect(() => {
    setGlobeData(
      globalPresenceCountries.map((country) => ({
        size: 25,
        color: "red",
        lat: country.latitude,
        lng: country.longitude,
        id: country.id,
        continent: country.continent,
        country_name: country.country_name,
        calling_code: country.calling_code,
        calling_number: country.calling_number,
        email: country.email,
        address: country.address,
        google_map_link: country.google_map_link,
      }))
    );

    /**
     * Added by - Ashish Dewangan on 08-08-2024
     * Reason - Created country tab data from globalPresenceCountries
     */
    setCountryTabs([
      {
        key: "1",
        label: "Headquarters",
        children: (
          <div className={globalPresenceStyle.countryCardContainer}>
            {globalPresenceCountries.map((country, index) => {
              if (country.is_headquarter)
                return <CountryCard country={country} />;
            })}
          </div>
        ),
      },
      {
        /* Modified by Jhamman on 20-09-2024
        Reason - As per client requirment*/
        // key: "2",
        // label: "Other Locations",
        // children: (
        //   <div className={globalPresenceStyle.countryCardContainer}>
        //     {globalPresenceCountries.map((country, index) => {
        //       if (!country.is_headquarter)
        //         return <CountryCard country={country} />;
        //     })}
        //   </div>
        // ),
        key: "2",
        label: "Other Locations",
        children: (
          <div className={globalPresenceStyle.pageDetails}>
            {parse(globalPresencePageDetails)}
          </div>
        ),
      },
      /* End of modification by Jhamman on 20-09-2024
        Reason - As per client requirment*/
    ]);
    /**
     * End of code addition by - Ashish Dewangan on 08-08-2024
     * Reason - Created country tab data from globalPresenceCountries
     */
  }, [globalPresenceCountries]);
  /**
   * End of code addition by - Ashish Dewangan on 07-08-2024
   * Reason - To create custom data for world globe from globalPresenceCountries
   */

  /**
   * Added by - Ashish Dewangan on 07-08-2024
   * Reason - To calculate width of globe component dynamically
   */
  useLayoutEffect(() => {
    function updateSize() {
      var style = getComputedStyle(document.body);
      if (window.innerWidth > 720) {
        setGlobeComponentWidth(parseInt(window.innerWidth) - 17);
      } else {
        setGlobeComponentWidth(parseInt(window.innerWidth));
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  /**
   * End of code addition by - Ashish Dewangan on 07-08-2024
   * Reason - To calculate width of globe component dynamically
   */

  //   Addition by - Jhamman on 12-09-2024
  //  Reason - remove tooltip if screen width is greater then 720px
  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth > 720) {
        setToolTipForGlobeForMobile(false);
      }
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);
  //   End of code addition by - Jhamman on 12-09-2024
  //  Reason - remove tooltip if screen width is greater then 720px

  /*Addition by - Jhamman on 12-09-2024
  Reason - remove tool tip idf click anywhere in screen*/
  useEffect(() => {
    const handleClick = (event) => {
      let targetElement = event.target;
      const svgPath = document.querySelectorAll("#tooltipIconId path");
      console.log("setToolTipForGlobeForMobile:", svgPath);

      if (
        targetElement.id == "tooltipDivId" ||
        targetElement.id == "tooltipButtonId" ||
        targetElement.id == "tooltipIconId" ||
        /* Modified by Jhamman on 27-09-2024
        Reason - because use another icon that have a array of path*/
        // targetElement == svgPath
        Array.from(svgPath).some((path) => path === targetElement)
        /* End of modification by Jhamman on 27-09-2024
        Reason - because use another icon that have a array of path*/
      ) {
        return;
      } else {
        setToolTipForGlobeForMobile(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  /*End of addition by - Jhamman on 12-09-2024
  Reason - remove tool tip idf click anywhere in screen*/

  // Addition by - Jhamman on 12-09-2024
  //  Reason - handle tooltip button
  const handletooltipClick = () => {
    setToolTipForGlobeForMobile((prevState) => !prevState);
  };
  //   End of code addition by - Jhamman on 12-09-2024
  //  Reason - handle tooltip button
  /**
   * Added by - Ashish Dewangan on 07-08-2024
   * Reason - To show modal when user click on world map pointer
   */
  const showCountryDetails = (country) => {
    setSelectedCountry(country);
    setIsModalOpen(true);
  };
  /**
   * End of code addition by - Ashish Dewangan on 07-08-2024
   * Reason - To show modal when user click on world map pointer
   */

  // if (
  //   globalPresencePageDetails?.trim()?.length < 1 &&
  //   globalPresenceCountries?.length < 1
  // )
  //   return <p>No details available</p>;

  return (
    <>
      {showContent ? (
        <div className={globalPresenceStyle.mainContainer}>
          <div className={globalPresenceStyle.worldGlobeContainer}>
            {/* Added by - Jhamman Lal Sahu on 12-09-2024
     Reason - To add tooltip button */}
            {/* Modification by Jhamman on 25-09-2024
          Reason - when click on path of svg then not functioning appropriate so added path to condition*/}

            <button
              onClick={handletooltipClick}
              id="tooltipButtonId"
              className={globalPresenceStyle.tooltipButton}
              style={{ cursor: "pointer" }}>
              <div
                className={globalPresenceStyle.buttonContainer}
                id="tooltipDivId">
                {/* Modified by Jhamman on 27-09-2024
                Reason - Added diffrent icon*/}
                {/* <AiOutlineExclamation
                  className={globalPresenceStyle.tooltipIcon}
                  id="tooltipIconId"
                /> */}
                {/* End of modification by Jhamman on 27-09-2024
                Reason - Added diffrent icon*/}
                <IoInformationCircleOutline
                  className={globalPresenceStyle.tooltipIcon}
                  id="tooltipIconId"
                />
              </div>
            </button>

            {/* End of modification by Jhamman on 25-09-2024
          Reason - when click on path of svg then not functioning appropriate so added path to condition*/}
            {/* End of addition by - Jhamman Lal Sahu on 12-09-2024
     Reason - To add tool tipbutton */}

            {/* Added by jhamman 09-09-2024
        Reason - added tool tip for globe for mobile*/}
            {toolTipForGlobeForMobile && (
              <div className={globalPresenceStyle.toolTipForGlobeForMobile}>
                <strong>How to use the globe:</strong>
                <ul className={globalPresenceStyle.toolTips}>
                  <li>Tap on markers to view country details.</li>
                  <li>Swipe to rotate the globe.</li>
                  <li>Pinch to zoom in or out.</li>
                </ul>
              </div>
            )}
            {/* End of addition by jhamman 09-09-2024
        Reason - added tool tip for globe for mobile*/}

            {/* Added by jhamman 09-09-2024
        Reason - added tool tip for globe*/}
            <div className={globalPresenceStyle.toolTipForGlobe}>
              <strong>How to use the globe:</strong>

              {/* code changed by jhamman on 12-09-2024
          Reason - Added li tag*/}
              <ul className={globalPresenceStyle.toolTips}>
                <li>Click on markers to view country details.</li>
                <li>Drag to rotate the globe.</li>
                <li>Scroll to zoom in or out.</li>
              </ul>
              {/* End of code changed by jhamman on 12-09-2024
          Reason - Added li tag*/}
            </div>
            {/* End of addition by jhamman 09-09-2024
        Reason - added tool tip for globe*/}

            {/* Addition by Jhamman on 25-09-2024
        Reason - added 360 ratation icon*/}
            <div className={globalPresenceStyle.rotationIconContainer}>
              <img
                src="/rotate-removebg-preview.png"
                className={globalPresenceStyle.rotationIcon}
              />
            </div>
            {/* End of addition by Jhamman on 25-09-2024
          Reason - added 360 ratation icon*/}

            <Globe
              ref={globeElement}
              globeImageUrl="//unpkg.com/three-globe/example/img/earth-day.jpg"
              backgroundImageUrl={"bgimage1.jpg"}
              htmlElementsData={globeData}
              width={globeComponentWidth}
              height={400}
              backgroundColor="rgba(0,0,0,0)"
              onGlobeReady={(e) => {
                const MAP_CENTER = {
                  lat: 20.5937,
                  lng: 78.9629,
                  altitude: 2,
                };
                globeElement.current.pointOfView(MAP_CENTER, 500);
              }}
              htmlElement={(d) => {
                const el = document.createElement("div");
                el.innerHTML =
                  // `<span id="country-${d.country_name}" style="color:black;background-color:white;padding:2px; border: 2px solid black; border-radius:10px;">${d.country_name}</span>` +
                  MarkerSvg;
                el.style.color = d.color;
                el.style.width = `${d.size}px`;
                el.style["pointer-events"] = "auto";
                el.style.cursor = "pointer";
                el.onclick = () => {
                  showCountryDetails(d);
                };
                return el;
              }}
            />
          </div>
          <Modal
            open={isModalOpen}
            onCancel={(e) => {
              setIsModalOpen(false);
            }}
            onClose={(e) => {
              setIsModalOpen(false);
            }}
            footer={null}
            centered={true}>
            <CountryCard country={selectedCountry} />
          </Modal>
          <div className={globalPresenceStyle.pageContentContainer}>
            {/* Added by - Ashish Dewangan on 08-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashish Dewangan on 08-08-2024
        Reason - To show navigation path */}

            {/* Added by - Ashish Dewangan on 22-08-2024
        Reason - Added page title */}

            {/* Modification by Jhamman on 27-09-2024
        Reason - print no details available when there is no data*/}
            {/* <div className={globalPresenceStyle.pageTitle}>Global Presence</div> */}
            {globalPresencePageDetails?.trim()?.length < 1 &&
            globalPresenceCountries?.length < 1 ? (
              <div className={globalPresenceStyle.noDetails}>
                <p className={globalPresenceStyle.noDetailText}>
                  No details available
                </p>
              </div>
            ) : (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*  <div className={globalPresenceStyle.pageTitle}>
                Global Presence
              </div>*/
              <div className={globalPresenceStyle.pageTitle}>
                <img
                  className={globalPresenceStyle.headingImage}
                  src="headingdesign.png"
                />
                <div className={globalPresenceStyle.headingTitle}>
                  <span className={globalPresenceStyle.title1}>Gl</span>
                  <span className={globalPresenceStyle.title2}>
                    obal Presence
                  </span>
                </div>
              </div>
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
            )}
            {/* End of modification by Jhamman on 27-09-2024
        Reason - print no details available when there is no data*/}

            {/* End of code addition by - Ashish Dewangan on 22-08-2024
        Reason - Added page title */}

            {/* Added by - Ashish Dewangan on 08-08-2024
        Reason - Added tabs design for headquarter locations and other locations */}
            {globalPresenceCountries.length > 0 && (
              <div className={globalPresenceStyle.countriesContainer}>
                <Tabs
                  defaultActiveKey="1"
                  items={countryTabs}
                  tabBarStyle={{ fontSize: "140px" }}
                />
              </div>
            )}
            {/* End of code addition by - Ashish Dewangan on 08-08-2024
        Reason - Added tabs design for headquarter locations and other locations */}

            {/* Commented by Jhamman on 20-09-2024
        Reason - as per client requirment*/}
            {/* <div className={globalPresenceStyle.pageDetails}>
          {parse(globalPresencePageDetails)}
        </div> */}
            {/* End of commentation by Jhamman on 20-09-2024
        Reason - as per client requirment*/}
          </div>
        </div>
      ) : (
        /* Addition by Jhamman on 27-09-2024
        reason - Show nothing while loading the data*/
        <div className={globalPresenceStyle.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 27-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};

export default GlobalPresence;
