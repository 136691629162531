// Added by - Jhamman Lal Sahu on 26-07-2024
// Reason - UI to render manufacturing details

import React, { useEffect, useState, useContext } from "react";
import { manufacturing } from "../../../Api/services";
import Style from "./Manufacturing.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../../Api/config";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const Manufacturing = () => {
  const [manufacturingDetails, setManufacturingDetails] = useState(null);
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  const fetchData = async () => {
    try {
      const response = await manufacturing();
      setManufacturingDetails(response.manufacturing_details);
    } catch (error) {
      console.error("Error fetching manufacturing details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 18-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },

      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Infrastructure", path: "/infrastructure/manufacturing" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path

      { name: "Manufacturing", path: "/infrastructure/manufacturing" },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 18-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchData();
  }, []);

  //commented by - Jhamman lal sahu on 18-08-2024
  //Reason - dont want to display this

  // if (!manufacturingDetails) return <p>No details available</p>;

  //commented by - Jhamman lal sahu on 30-08-2024
  //Reason - dont want to display this
  //Modified by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available
  // if (!manufacturingDetails)
  //   return (
  //     <div className={Style.noDetails}>
  //       <p className={Style.noDetailText}>No details available</p>
  //     </div>
  //   );
  //End of the modification by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available
  //End of the commentation by - Jhamman lal sahu on 30-08-2024
  //Reason - dont want to display this

  //End of the commentation by - Jhamman lal sahu on 18-08-2024
  //Reason - dont want to display this

  return (
    <>
      {showContent ? (
        /*Added by Jhamman on 30-08-2024
    Reason - conditional rendering */
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        manufacturingDetails?.banner_video_file != null ||
        manufacturingDetails?.manufacturing_image != null ||
        manufacturingDetails?.title_and_description ||
        navigationPath ||
        manufacturingDetails?.title_and_description?.trim()?.length > 0 ||
        manufacturingDetails?.page_content ||
        manufacturingDetails?.page_content?.trim()?.length > 0 ? ( */}
          {/* End of addition by Jhamman on 30-08-2024
          Reason - conditional rendering */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          <div className={Style.mainContainer}>
            {/* Modified by - Jhamman lal sahu on 17-08-2024
          Reason - Given an alternative option for banner, it can be image to */}

            {manufacturingDetails?.banner_video_file ? (
              /\.(mp4|mkv|webm|avi|mov|flv|wmv)$/i.test(
                manufacturingDetails.banner_video_file
              ) ? (
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_video_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <video autoPlay muted loop className={Style.banner_video}>
                  <source
                    src={`${baseURL}${manufacturingDetails.banner_video_file}`}
                    type={`video/${manufacturingDetails.banner_video_file
                      .split(".")
                      .pop()}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                /* </div> */
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_image_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <img
                  src={`${baseURL}${manufacturingDetails.banner_video_file}`}
                  alt="Banner"
                  className={Style.banner_image}
                />
                /* </div> */
              )
            ) : null}
            {/* End of the modification by - Jhamman lal sahu on 17-08-2024
            Reason - Given an alternative option for banner, it can be image to */}

            {/* Added by - Jhamman lal sahu on 18-08-2024
            Reason - To add path in UI */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* Added by - Jhamman lal sahu on 18-08-2024
            Reason - To add path in UI */}

            <div className={Style.subContainer}>
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {
                /* Added by - Jhamman  on 04-10-2024
              Reason - when image is available then also showing no content available */
                manufacturingDetails?.manufacturing_image ||
                /* End of addition by - Jhamman  on 04-10-2024
              Reason - when image is available then also showing no content available */
                manufacturingDetails?.title_and_description ||
                manufacturingDetails?.title_and_description?.trim()?.length >
                  0 ||
                manufacturingDetails?.page_content ||
                manufacturingDetails?.page_content?.trim()?.length > 0 ? (

                  /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                  /* <div className={Style.pageTitle}><img style={{height:'60px', width:'60px'}} className={Style.headingImage} src="headingdesign.png"/><span className={Style.title1}>Manu</span><span className={Style.title2}>facturing</span></div> */
                  <div
                    className={Style.pageTitle}>
                    <img
                      className={Style.headingImage}
                      src="headingdesign.png"
                    />
                    <div className={Style.headingTitle}>
                    <span
                      className={Style.title1}>
                      Ma
                    </span>
                    <span className={Style.title2}>nufacturing</span></div>
                  </div>
                  /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                ) : (
                  /*Modified by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
                  /* null */
                  <div className={Style.noDetails}>
                    <p className={Style.noDetailText}>No details available</p>
                  </div>
                )
                /* End of modification by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
              }
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}

              {manufacturingDetails?.title_and_description ? (
                <div className={Style.titleAndDescription}>
                  {parse(manufacturingDetails.title_and_description)}
                </div>
              ) : null}

              {/* Added by Jhamman Lal sahu on 16-08-2024
        Reason - Show imag if available oand dont show icon if image is not available */}
              <div className={Style.manufacturing_image_field}>
                {manufacturingDetails?.manufacturing_image ? (
                  <img
                    src={baseURL + manufacturingDetails.manufacturing_image}
                    className={Style.manufacturingImage}
                  />
                ) : null}
              </div>

              {/* End of the addition by Jhamman Lal sahu on 16-08-2024
        Reason - Show imag if available oand dont show icon if image is not available */}

              {/* Added by Jhamman Lal sahu on 16-08-2024
        Reason - there is showing an icon when image is not available */}
              {/* <img
          src={baseURL + manufacturingDetails.manufacturing_image}
          className={Style.manufacturingImage}
        /> */}
              {/* End of the addition by Jhamman Lal sahu on 16-08-2024
        Reason - there is showing an icon when image is not available */}
              {manufacturingDetails?.page_content ? (
                <div className={Style.pageContent}>
                  {parse(manufacturingDetails.page_content)}
                </div>
              ) : null}
            </div>
          </div>

          {/* Commented by Jhamman on 06-09-2024
      Reason - we have to show navigation path wheater content have or not */}
          {/* ) : ( // Added by Jhamman on 30-08-2024 //Reason - No details available
      <div className={Style.noDetails}>
        <p className={Style.noDetailText}>No details available</p>
      </div>
      ) // End of the addition by Jhamman on 30-08-2024 //Reason - No details
      available } */}
          {/* End of commentation by Jhamman on 06-09-2024
      Reason - we have to show navigation path wheater content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={Style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};

export default Manufacturing;

// End of the addition by Jhamman Lal Sahu on 26-07-2024
// Reason - UI to render manufacturing details
