//Added by - Jhamman lal sahu on 05-08-2024
//Reason - To render UI of InstitutionalTenders.jsx

import styles from "./InstitutionalTenders.module.css";
import { institutionalTenders } from "../../../Api/services";
import { useState, useEffect, useContext } from "react";
import parse from "html-react-parser";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const InstitutionalTenders = () => {
  const [institutionalTendersDetails, setInstitutionalTendersDetails] =
    useState({});
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  //Added by - jhamman lal sahu on 05-08-2024
  //Reason - To get details of Institutional Tenders Details
  const fetchInstitutionalTendersDetails = async () => {
    try {
      const response = await institutionalTenders();
      setInstitutionalTendersDetails(response.institutionalTenders_details);
    } catch (error) {
      console.log("Error fetching institutional tenders details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },

      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Services", path: "/Services/third-party-manufacturing" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path

      {
        name: "Third Party Manufacturing",
        path: "/Services/third-party-manufacturing",
      },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchInstitutionalTendersDetails();
  }, []);

  // End of the addition by Jhamman lal sahu on 05-08-2025
  // Reason - To get details of Institutional Tenders Details

  //commented by - Jhamman lal sahu on 31-08-2024
  //Reason - dont want to display this

  //Modified by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  // if (!institutionalTendersDetails) return <p>No details available</p>;
  // if (!institutionalTendersDetails)
  //   return (
  //     <div className={styles.noDetails}>
  //       <p className={styles.noDetailText}>No details available</p>
  //     </div>
  //   );
  //End of the modification by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  //End of commentation by - Jhamman lal sahu on 31-08-2024
  //Reason - dont want to display this

  return (
    <>
      {showContent ? (
        // Added by Jhamman on 31-08-2024
        //Reason - conditional rendering

        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        institutionalTendersDetails?.title_and_description ||
        institutionalTendersDetails?.title_and_description?.trim()?.length >
          0 ? ( */}
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* End of addition by Jhamman on 31-08-2024
      //Reason - conditional rendering */}
          <div className={styles.mainContainer}>
            {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* Added by - Jhamman lal sahu on 22-08-2024
          Reason - To add path in UI */}
            <div className={styles.subContainer}>
              {/* Modified by Jhamman on 06-09-2024
        Reason - print page title based on condition*/}
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* <div className={styles.pageTitle}>Institutional tenders</div>{" "} */}
              {institutionalTendersDetails?.title_and_description ||
              institutionalTendersDetails?.title_and_description?.trim()
                ?.length > 0 ? (
                /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                /*<div className={styles.pageTitle}>Institutional Tenders</div>*/
                <div className={styles.pageTitle}>
                  <img
                    className={styles.headingImage}
                    src="headingdesign.png"
                  />
                  <div className={styles.headingTitle}>
                    <span className={styles.title1}>In</span>
                    <span className={styles.title2}>stitutional Tenders</span>
                  </div>
                </div>
              ) : (
                /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                <div className={styles.noDetails}>
                  <div className={styles.noDetailText}>
                    No details available
                  </div>
                </div>
              )}
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* End of modification by Jhamman on 06-09-2024
        Reason - print page title based on condition*/}

              {institutionalTendersDetails?.title_and_description ? (
                <div className={styles.titleAndDescription}>
                  {parse(institutionalTendersDetails?.title_and_description)}
                </div>
              ) : null}
            </div>
          </div>

          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* ) : ( // Added by Jhamman on 31-08-2024 //Reason - No details available
      <div className={styles.noDetails}>
        <p className={styles.noDetailText}>No details available</p>
      </div>
      ) // End of the addition by Jhamman on 31-08-2024 //Reason - No details
      available } */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
          reason - Show nothing while loading the data*/
        <div className={styles.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/}
    </>
  );
};

export default InstitutionalTenders;

//End of the addition by Jhamman lal sahu on 05-08-2024
//Reason - To render UI of InstitutionalTenders.jsx
