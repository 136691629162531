import React from "react";
import { getSocialIconsApi } from "../../Api/services";
import iconStyle from "./SocialIcons.module.css";
import { useState, useEffect } from "react";
import { baseURL } from "../../Api/config";
import { Link, useNavigate } from "react-router-dom";
import { IoShareSocial } from "react-icons/io5";

const SocialIcons = () => {
  const [socialIconData, setSocialIconData] = useState({});
  const [isVisible, setIsVisible] = useState(false);

  const fetchSocialIconData = async () => {
    try {
      const response = await getSocialIconsApi();
      setSocialIconData(response.social_icon_details);
    } catch (error) {
      console.error("Error fetching homepage details:", error);
    }
  };

  const clickIcon = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClick = (event) => {
      let targetElement = event.target;
      // const svgPath = document.querySelector("#socialIcon path");
      if (
        targetElement.id == "socialDiv" ||
        targetElement.id == "socialButton" ||
        targetElement.id == "socialIcon" ||
        targetElement.id == "social" ||
        targetElement.id == "media"
        // targetElement == svgPath
      ) {
        return;
      } else {
        setIsVisible(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth > 800) {
        setIsVisible(false);
      }
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  useEffect(() => {
    fetchSocialIconData();
  }, []);

  return (
    <>
      <div className={iconStyle.buttonContainer} id="socialDiv">
        <button
          id="socialButton"
          className={
            isVisible
              ? iconStyle.socialIconButtonClick
              : iconStyle.socialIconButton
          }
          onClick={clickIcon}>
          {/* Commented by Jhamman on 26-09-2024
          Reason - using image instead of icon*/}
          {/* <IoShareSocial
            className={`${iconStyle.socialIcon} ${
              isVisible ? iconStyle.socialIconInvisible : ""
            }`}
            id="socialIcon"
          /> */}
          {/* End of commented by Jhamman on 26-09-2024
          Reason - using image instead of icon*/}
          {/* Added by Jhamman on 26-09-2024
          Reason - using image instead of icon*/}
          {/* <div
            className={`${iconStyle.socialIcon} ${
              isVisible ? iconStyle.socialIconInvisible : ""
            }`}
            id="socialIcon">
            <span id='social'>Social</span>
            <span id='media'>media</span>
          </div> */}
           <div
            className={`${iconStyle.socialIcon} ${
              isVisible ? iconStyle.socialIconInvisible : ""
            }`}
            id="socialIcon">
            <img className={iconStyle.image} id='social' src="sm1.png"/>
          </div>
          {/* End of addition by Jhamman on 26-09-2024
          Reason - using image instead of icon*/}
          {isVisible && socialIconData?.length > 0 ? (
            <div className={iconStyle.circleContainer}>
              {socialIconData.map((icon, index) => (
                <Link
                  to={icon.link}
                  target="_blank"
                  className={`${iconStyle.circle}`}>
                  <img
                    src={baseURL + icon.logo}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              ))}
            </div>
          ) : null}
        </button>
      </div>
    </>
  );
};

export default SocialIcons;
