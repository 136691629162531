/**
 * Created by - Ashlekh on 12-08-2024
 * Reason - To have Blog Detailed section
 */

import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import style from "./blogDetailed.module.css";
import config from "../../Api/config";
import { getBlogDetailsApi } from "../../Api/services";
import parse from "html-react-parser";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const BlogDetailed = () => {
  const [blogDetails, setBlogDetails] = useState([]);
  const [filteredBlogDetails, setFilteredBlogDetails] = useState([]);
  const location = useLocation();
  const blogId = location.state;
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);

  // Added by - Ashlekh on 12-08-2024
  // Reason - This useEffect will executes when page loads
  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 28-09-2024
     * Reason - Scroll to top when refresh
     */
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 28-09-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-09-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Blog", path: "/blog" },
      { name: "Blog Details", path: "/blog_detailed" },
    ]);
    /**
     * End of addition by - Jhamman lal sahu on 22-09-2024
     * Reason - To add paths to navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 12-08-2024
  // Reason - This useEffect will executes when page loads

  useEffect(() => {
    if (blogId != null && blogId != "") {
      getBlogDetails();
    }
  }, [blogId]);

  useEffect(() => {
    if (blogDetails?.blog != null) {
      const filteredBlog = blogDetails.blog.filter((blog) => blog.id == blogId);
      setFilteredBlogDetails(filteredBlog);
    }
  }, [blogDetails, blogId]);

  const getBlogDetails = async () => {
    const response = await getBlogDetailsApi();
    setBlogDetails(response);
  };
  return (
    <div className={`${style.pageContainer}`}>
      {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}
      <NavigationPath navigationPathArray={navigationPath} />
      {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}

      <div className={`${style.content}`}>
        {filteredBlogDetails?.map((blog) => (
          <div key={blog.id} className={`${style.blogDetailsContainer}`}>
            <div className={`${style.blogTitle}`}>{blog.title}</div>
            <div className={`${style.imageContainer}`}>
              <img
                src={config.baseURL + blog?.image}
                alt=""
                className={`${style.image}`}
              />
            </div>
            <div className={`${style.description}`}>
              {" "}
              {parse(blog?.description)}{" "}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default BlogDetailed;
