/**
 * Added by - Jhamman Lal Sahu on 14-08-2024
 * Reason - To render UI for Request Callback and handle form submission
 */

import React, { useState, useEffect } from "react";
import Style from "./RequestCallback.module.css";
import { RiCloseCircleLine } from "react-icons/ri";
import { requestCallbackAPI } from "../../Api/services";
import notificationObject from "../../components/Widgets/Notification/Notification";
import RequestCallbackButton from "../../components/Buttons/RequestCallbackButton/RequestCallbackButton";
import { FaPhoneAlt } from "react-icons/fa";

// Modified by Jhamman Lal Sahu on 01-09-2024
// Reason - Added onClose prop to handle closing the modal from the parent component
const RequestCallback = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [errors, setErrors] = useState({});

  //  Added by - Jhamman Lal Sahu on 02-09-2024
  //  Reason - handle on button click
  const handleRequestCallbackClick = () => {
    setIsVisible(true);
  };
  //  End of addition by - Jhamman Lal Sahu on 02-09-2024
  //  Reason - handle on button click

  //  Added by - Jhamman Lal Sahu on 14-08-2024
  //  Reason - To validate email

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  //  End of the addition by  Jhamman Lal Sahu on 14-08-2024
  //  Reason - To validate email

  //  Added by - Jhamman Lal Sahu on 14-08-2024
  //  Reason - To handle form submission and validation
  const handleSubmit = async (e) => {
    /*Commented by Jhamman on 21-09-2024
    Reason - have to remove data after submiting*/
    // e.preventDefault();
    /*End of commentation by Jhamman on 21-09-2024
    Reason - have to remove data after submiting*/

    const newErrors = {};

    if (!name.trim()) {
      /* Modification by Jhamman on 26-09-2024
      Reason - Altered message*/
      // newErrors.name = "*Name is required.";
      newErrors.name = "Please enter name";
      /* End of modification by Jhamman on 26-09-2024
      Reason - Altered message*/
    }

    if (!phone.trim()) {
      /* Modification by Jhamman on 26-09-2024
      Reason - Altered message*/
      // newErrors.phone = "*Phone number is required.";
      newErrors.phone = "Please enter your mobile number";
      /* End of modification by Jhamman on 26-09-2024
      Reason - Altered message*/
    } else {
      const regex = /^[6-9]\d{9}$/;
      if (!regex.test(phone)) {
        newErrors.phone = "Invalid mobile number";
      }
    }

    if (!message.trim()) {
      newErrors.message = "Please write your query";
    }

    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    //Added by - jhamman lal sahu on 17-08-2024
    //Reason - If there are validation errors, update the errors state and exit the function

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    //End of the addition  by - jhamman lal sahu on 17-08-2024
    //Reason - If there are validation errors, update the errors state and exit the function

    try {
      const formData = { name, email, country, phone, message, countryCode};
      const response = await requestCallbackAPI(formData);
      notificationObject.success(response.message);
    } catch (error) {
      console.error("Error submitting callback request:", error);
    }

    //  Added by - Jhamman Lal Sahu on 21-09-2024
    // Reason - To clear all section after data sent
    setName("");
    setEmail("");
    setCountry("");
    setPhone("");
    setMessage("");
    setErrors({});
    setCountryCode("");
    // End of addition by - Jhamman Lal Sahu on 21-09-2024
    // Reason - To clear all section after data
  };

  //  End of the addition by - Jhamman Lal Sahu on 14-08-2024
  //  Reason - To handle form submission and validation

  //  Added by - Jhamman Lal Sahu on 14-08-2024
  // Reason - To close the request callback form

  const handleClose = () => {
    setIsVisible(false);
    //  Added by - Jhamman Lal Sahu on 02-09-2024
    // Reason - To clear all section after close
    setName("");
    setEmail("");
    setCountry("");
    setPhone("");
    setMessage("");
    setErrors({});
    setCountryCode("91");
    // End of addition by - Jhamman Lal Sahu on 02-09-2024
    // Reason - To clear all section after close
  };

  //  End of the addition by - Jhamman Lal Sahu on 14-08-2024
  // Reason - To close the request callback form

  //  Added by - Jhamman Lal Sahu on 14-08-2024
  // Reason - To open the request callback form after 5 sec

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsVisible(true);
  //   }, 5000);
  // }, []);

  //  End of the addition by - Jhamman Lal Sahu on 14-08-2024
  // Reason - To open the request callback form after 5 sec

  return (
    <>
      {/* Added by - Jhamman Lal Sahu on 02-09-2024
     Reason - To add request callback button */}
      <div className={Style.buttonContainer}>
        <button
          className={Style.requestCallbackButton}
          onClick={handleRequestCallbackClick}>
          <div className={Style.requestCallbackIconField}>
            <FaPhoneAlt className={Style.requestCallbackIcon} />
          </div>
          <div className={Style.requestCallbackText}>Request a Call Back</div>
        </button>
      </div>
      {/* End of addition by - Jhamman Lal Sahu on 02-09-2024
     Reason - To add request callback button */}
      {isVisible && <div className={Style.overlay} />}
      {isVisible && (
        <div className={Style.mainContainer}>
          <div
            className={Style.model_container}
            style={{ display: isVisible ? "block" : "none" }}>
            <div className={Style.model_header}>
              <div className={Style.model_heading}>Inquiry</div>
              <div className={Style.close_button_field} onClick={handleClose}>
                <RiCloseCircleLine className={Style.close_button} />
              </div>
            </div>
            <div className={Style.model_contactContainer}>
              <div className={Style.name_email_field}>
                <div className={Style.name_container}>
                  <input
                    type="text"
                    name="name"
                    placeholder="*Your Name"
                    className={Style.inputBox}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  {errors.name && (
                    <div className={Style.error_message}>{errors.name}</div>
                  )}
                </div>
                <div className={Style.email_container}>
                  <input
                    type="email"
                    name="email"
                    placeholder="*Email"
                    className={Style.inputBox}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className={Style.error_message}>{errors.email}</div>
                  )}
                </div>
              </div>

              <div className={Style.country_mobile_message_container}>
                <div className={Style.input_field}>
                  <div className={Style.country_container}>
                    <input
                      type="text"
                      name="country"
                      placeholder="Country"
                      className={Style.inputBox}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                  <div className={Style.phone_container}>
                    <div className={Style.phone_number_and_country_code}>
                      <div className={Style.countryCodeContainer}>
                        <input
                          type="text"
                          name="countryCode"
                          className={Style.inputCountryCode}
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                          /* Added by jhamman on 05-10-2024
                          Reason - Added max length */
                          maxLength={5}
                          /* End of addition by jhamman on 05-10-2024
                          Reason - Added max length */
                          required
                        />
                      </div>
                      <div className={Style.phoneNumberContainer}>
                        <input
                          type="text"
                          name="phone"
                          placeholder="*Phone"
                          className={Style.phoneInputBox}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </div>
                      {errors.phone && (
                        <div className={Style.error_message}>
                          {errors.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={Style.message_container}>
                    <textarea
                      name="message"
                      placeholder="*Message"
                      className={Style.message_box}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    {errors.message && (
                      <div className={Style.error_message}>
                        {errors.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={Style.submit_field}>
                <button
                  type="button"
                  className={Style.submit_button}
                  onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestCallback;

/**
 * End of the addition by Jhamman Lal Sahu on 14-08-2024
 * Reason - To render UI for Request Callback and handle form submission
 */
