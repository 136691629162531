// Added by - Jhamman Lal Sahu on 26-07-2024
// Reason - UI to render manufacturing details

import React, { useEffect, useState, useContext } from "react";
import { packaging } from "../../../Api/services";
import Style from "./Packaging.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../../Api/config";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const Packaging = () => {
  const [packagingDetails, setPackagingDetails] = useState(null);
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  const fetchData = async () => {
    // Function component to fetch details from backend
    try {
      const response = await packaging();
      setPackagingDetails(response.packaging_details);
    } catch (error) {
      console.error("Error fetching packaging details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 18-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 18-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Infrastructure", path: "/infrastructure/packaging" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      { name: "Packaging", path: "/infrastructure/packaging" },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchData();
  }, []);

  //Commented by Jhamman on 30-08-2024
  //Reason - handle it below, with condition.
  //Modified by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available
  // if (!packagingDetails)
  //   return (
  //     <div className={Style.noDetails}>
  //       <p className={Style.noDetailText}>No details available</p>
  //     </div>
  //   );
  //End of the modification by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available
  //End of commentation by Jhamman on 30-08-2024
  //Reason - handle it below, with condition.

  return (
    <>
      {showContent ? (
        // Added by Jhamman on 30-08-2024
        //Reason - conditional rendering
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        packagingDetails?.banner_video_file != null ||
        packagingDetails?.page_content ||
        packagingDetails?.page_content?.trim()?.length > 0 ? ( */}
          {/* End of addition by Jhamman on 30-08-2024
          Reason - conditional rendering */}
          {/* End of commntation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}

          <div className={Style.mainContainer}>
            {/* Modified by - Jhamman lal sahu on 17-08-2024
    Reason - Given an alternative option for banner, it can be image to */}

            {packagingDetails?.banner_video_file ? (
              /\.(mp4|mkv|webm|avi|mov|flv|wmv)$/i.test(
                packagingDetails.banner_video_file
              ) ? (
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_video_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <video autoPlay muted loop className={Style.banner_video}>
                  <source
                    src={`${baseURL}${packagingDetails.banner_video_file}`}
                    type={`video/${packagingDetails.banner_video_file
                      .split(".")
                      .pop()}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                /* </div> */
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_image_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <img
                  src={`${baseURL}${packagingDetails.banner_video_file}`}
                  alt="Banner"
                  className={Style.banner_image}
                />
                /* </div> */
              )
            ) : null}

            {/* End of the modification by - Jhamman lal sahu on 17-08-2024
    Reason - Given an alternative option for banner, it can be image to */}

            {/* Added by - Jhamman lal sahu on 22-08-2024
        Reason - To add path in UI */}

            <NavigationPath navigationPathArray={navigationPath} />

            {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}

            <div className={Style.subContainer}>
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {
                packagingDetails?.page_content ||
                packagingDetails?.page_content?.trim()?.length > 0 ? (
                  /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                  /* <div className={Style.pageTitle}>Packaging</div> */
                  <div className={Style.pageTitle}>
                    <img
                      className={Style.headingImage}
                      src="headingdesign.png"
                    />
                    <div className={Style.headingTitle}>
                      <span className={Style.title1}>Pa</span>
                      <span className={Style.title2}>ckaging</span>
                    </div>
                  </div>
                ) : (
                  /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                  /*Modified by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
                  /* null */
                  <div className={Style.noDetails}>
                    <p className={Style.noDetailText}>No details available</p>
                  </div>
                )
                /* End of modification by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
              }
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}

              {/*Modified by Jhamman on 06-09-2024
              Reason - Not working properly */}
              {packagingDetails?.page_content ? (
                <div className={Style.pageContent}>
                  {parse(packagingDetails?.page_content)}
                </div>
              ) : null}
              {/* End of modification by Jhamman on 06-09-2024
              Reason - Not working properly */}
            </div>
          </div>

          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* ) : (
          // Added by Jhamman on 30-08-2024
          //Reason - No details available
          <div className={Style.noDetails}>
            <p className={Style.noDetailText}>No details available</p>
          </div>
        )
         End of the addition by Jhamman on 30-08-2024
        Reason - No details available 
      } */}
          {/* End of the commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={Style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};

export default Packaging;

// End of the addition by Jhamman Lal Sahu on 26-07-2024
// Reason - UI to render Packaging details
