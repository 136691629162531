/**
 * Created by - Ashlekh on 12-08-2024
 * Reason - To have Blog section
 */
import { useContext, useEffect, useState } from "react";
import style from "./blog.module.css";
import { getBlogDetailsApi } from "../../Api/services";
import config from "../../Api/config";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const Blog = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [blogData, setBlogData] = useState([]);
  // Added by - Ashlekh on 12-08-2024
  // Reason - This useEffect will executes when page loads
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    blogDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Blog", path: "/blog" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 12-08-2024
  // Reason - This useEffect will executes when page loads

  // Added by - Ashlekh on 12-08-2024
  // Reason - To get Blog details from backend
  const blogDetails = async () => {
    try {
      const response = await getBlogDetailsApi();
      setBlogData(response?.blog);
    } catch (error) {
      console.error("Error fetching blog details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 12-08-2024
  // Reason - To get Blog details from backend
  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition for No details available and navigation path */}
          {/* {blogData?.length > 0 ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available and navigation path */}
          <div className={`${style.mainContainer}`}>
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 30-08-2024
        Reason - To provide page title */}
            {/* Code changed by - Ashlekh on 07-09-2024
        Reason - To apply condition for page title */}
            {/* <div className={style.pageTitle}>Blog</div> */}
            {blogData?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={style.pageTitle}>Blog</div>*/
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Bl</span>
                  <span className={style.title2}>og</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
          Reason - To apply condition for page title */}
            {/* End of code - Ashlekh on 30-08-2024
        Reason - To provide page title */}
            {blogData?.length > 0 ? (
              <div className={`${style.subContainer}`}>
                {blogData?.map((item, index) => {
                  return (
                    <div
                      className={`${style.imageAndDescriptionContainer}`}
                      key={index}>
                      <div className={`${style.imageContainer}`}>
                        <img
                          src={config.baseURL + item?.image}
                          alt=""
                          className={`${style.image}`}
                        />
                      </div>
                      <div className={`${style.titleContainer}`}>
                        {item?.title}
                      </div>
                      <div className={`${style.readMore}`}>
                        <Link
                          to={"/blog_detailed"}
                          state={item?.id}
                          className={`${style.navigationLink}`}>
                          Read more
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          {/* Comment - Ashlekh on 07-09-2024
        Reason - To change condition for Title and navigation path */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for Title and navigation path */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
    reason - Show nothing while loading the data*/}
    </>
  );
};
export default Blog;
