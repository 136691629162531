/**
 * Created by - Ashish Dewangan on 08-07-2024
 * Reason - To specify API calling methods
 */
import API from "./api";

/**
 * Added by - Ashish Dewangan on 08-07-2024
 * Reason - To post login details
 */
export const login = async (userDetails) => {
  const response = await API.post(`/login/`, userDetails, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 404) {
      console.log(response.response.data);
    }
    if (response.response.status == 401) {
      console.log(response.response.data);
    }
    return {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code addition by - Ashish Dewangan on 08-07-2024
 * Reason - To post login details
 */

/**
 * Added by - Ashish Dewangan on 08-07-2024
 * Reason - To get user details
 */
export const userDetails = async (access) => {
  const response = await API.get(`/user/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
  }).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 401) {
      console.log(response.response.data);
    }
    return {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code addition by - Ashish Dewangan on 08-07-2024
 * Reason - To get user details
 */

/**
 * Added by - Ashish Dewangan on 08-07-2024
 * Reason - To get new access token using refresh token
 */

export const refresh = async () => {
  const data = {
    refresh_token: localStorage.getItem("refresh"),
  };
  const response = await API.post(`/refresh/`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status != 200) {
      console.log(response.response.data);
      localStorage.clear();
    }
    return {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code addition by - Ashish Dewangan on 08-07-2024
 * Reason - To get new access token using refresh token
 */

/**
 * Added by - Ashish Dewangan on 08-07-2024
 * Reason - To post login details
 */
export const signup = async (userDetails) => {
  const response = await API.post(`/signup/`, userDetails, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    }
    return {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code addition by - Ashish Dewangan on 08-07-2024
 * Reason - To post login details
 */

/**
 * Added by - Jhamman Lal Sahu on 26-07-2024
 * Reason - To get manufacturing details
 */
export const manufacturing = async () => {
  const response = await API.get(`/manufacturing/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code addition by - Jhamman Lal Sahu on 26-07-2024
 * Reason - To get manufacturing details
 */

//  Added by - Jhamman Lal Sahu on 26-07-2024
//  Reason - To get packaging details

export const packaging = async () => {
  const response = await API.get(`/packaging/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Jhamman Lal Sahu on 26-07-2024
// Reason - To get packaging details

//  Added by - Jhamman Lal Sahu on 26-07-2024
//  Reason - To get research and development details

export const researchAndDevelopment = async () => {
  const response = await API.get(`research-and-development/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Jhamman Lal Sahu on 26-07-2024
// Reason - To get To get research and development details

//  Added by - Jhamman Lal Sahu on 29-07-2024
//  Reason - To get research and development details

export const homepage = async () => {
  const response = await API.get(`homepage/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Jhamman Lal Sahu on 29-07-2024
// Reason - To get To get research and development details

//  Added by - Om Kumar Shrivastava on 31-07-2024
//  Reason - To get setting details

export const getSettingDetailsApi = async () => {
  const response = await API.get(`setting/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Om Kumar Shrivastava on 31-07-2024
// Reason - To get To get setting details

//  Added by - Om Kumar Shrivastava on 31-07-2024
//  Reason - To get social icon details

export const getSocialIconsApi = async () => {
  const response = await API.get(`social-link/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Om Kumar Shrivastava on 31-07-2024
// Reason - To get social icon details

/**
 * Added by - Ashlekh on 02-08-2024
 * Reason - To get about us details
 */
export const getAboutUsDetailsApi = async () => {
  const response = await API.get(`about-us/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 02-08-2024
 * Reason - To get about us details
 */

// Modified by - Jhamman Lal Sahu on 04-08/2024
//  Reason -  Because instead of making diffrent API to get Accreditation data we created a single one to get all data
//  Added by - Jhamman Lal Sahu on 31-07-2024
//  Reason - To get accreditation details

export const accreditation = async () => {
  const response = await API.get(`accreditation/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Jhamman Lal Sahu on 31-07-2024
// Reason - To get To get accreditation details
// End of the Modification by - Jhamman Lal Sahu on 04-08/2024
//  Reason -  Because instead of making diffrent API to get Accreditation data we created a single one to get all data

// Commented by Jhamman Lal sahu on 04-08-2024
// Reason - Because instead of making diffrent API to get Accreditation data we created a single one to get all data

//  Added by - Jhamman Lal Sahu on 31-07-2024
//  Reason - To get GMP accreditation details

// export const gmpAccreditation = async () => {
//   const response = await API.get(`gmp-accreditation/`).catch((err) => console.log(err));
//   if (response.response) {
//     if (response.response.status == 500) {
//       console.log(response.response.data);
//     } else if (response.response.status == 404) {
//       console.log(response.response.data);
//     }
//     return response ? response.data : {};
//   } else {
//     if (response.status == 200) {
//       return response ? response.data : {};
//     }
//   }
// };

// End of code addition by - Jhamman Lal Sahu on 31-07-2024
// Reason - To get To get GMP accreditation details

//  Added by - Jhamman Lal Sahu on 02-08-2024
//  Reason - To get GMP approval details

// export const gmpApproval = async () => {
//   const response = await API.get(`gmp-approval/`).catch((err) => console.log(err));
//   if (response.response) {
//     if (response.response.status == 500) {
//       console.log(response.response.data);
//     } else if (response.response.status == 404) {
//       console.log(response.response.data);
//     }
//     return response ? response.data : {};
//   } else {
//     if (response.status == 200) {
//       return response ? response.data : {};
//     }
//   }
// };

// End of code addition by - Jhamman Lal Sahu on 02-08-2024
// Reason - To get To get GMP approval details

//  Added by - Jhamman Lal Sahu on 02-08-2024
//  Reason - To get Export Business details

// export const exportBusiness = async () => {
//   const response = await API.get(`export-business/`).catch((err) => console.log(err));
//   if (response.response) {
//     if (response.response.status == 500) {
//       console.log(response.response.data);
//     } else if (response.response.status == 404) {
//       console.log(response.response.data);
//     }
//     return response ? response.data : {};
//   } else {
//     if (response.status == 200) {
//       return response ? response.data : {};
//     }
//   }
// };

// End of code addition by - Jhamman Lal Sahu on 02-08-2024
// Reason - To get To get Export Business details

//  Added by - Jhamman Lal Sahu on 02-08-2024
//  Reason - To get Product Registered details

// export const productRegistered = async () => {
//   const response = await API.get(`product-registered/`).catch((err) => console.log(err));
//   if (response.response) {
//     if (response.response.status == 500) {
//       console.log(response.response.data);
//     } else if (response.response.status == 404) {
//       console.log(response.response.data);
//     }
//     return response ? response.data : {};
//   } else {
//     if (response.status == 200) {
//       return response ? response.data : {};
//     }
//   }
// };

// End of code addition by - Jhamman Lal Sahu on 02-08-2024
// Reason - To get To get product Registered details

//  Added by - Jhamman Lal Sahu on 02-08-2024
//  Reason - To get Upcoming Certification details

// export const upcomingCertification = async () => {
//   const response = await API.get(`upcoming-certification/`).catch((err) => console.log(err));
//   if (response.response) {
//     if (response.response.status == 500) {
//       console.log(response.response.data);
//     } else if (response.response.status == 404) {
//       console.log(response.response.data);
//     }
//     return response ? response.data : {};
//   } else {
//     if (response.status == 200) {
//       return response ? response.data : {};
//     }
//   }
// };

// End of code addition by - Jhamman Lal Sahu on 02-08-2024
// Reason - To get To get Upcoming Certification details

// End of the Code Commented by Jhamman Lal sahu on 04-08-2024
// Reason - Because instead of making diffrent API to get Accreditation data we created a single one to get all data

//  Added by - Jhamman Lal Sahu on 04-08-2024
//  Reason - To get quality control details

export const qualityControl = async () => {
  const response = await API.get(`quality-control/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Jhamman Lal Sahu on 04-08-2024
// Reason - To get To get quality control details

//  Added by - Jhamman Lal Sahu on 04-08-2024
//  Reason - To get contract manufacturing details

//Modified by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code
export const contractManufacturing = async () => {
  const response = await API.get(`/contract-manufacturing/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

//End of the modification by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code

// End of code addition by - Jhamman Lal Sahu on 04-08-2024
// Reason - To get To get contract manufacturing details

//  Added by - Jhamman Lal Sahu on 05-08-2024
//  Reason - To get Third Party manufacturing details

//Modified by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code
export const thirdPartyManufacturing = async () => {
  const response = await API.get(`/third-party-manufacturing/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

//End of the modification by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code

// End of code addition by - Jhamman Lal Sahu on 05-08-2024
// Reason - To get To get third party manufacturing details

//  Added by - Jhamman Lal Sahu on 05-08-2024
//  Reason - To get institutional tenders details

//Modified by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code
export const institutionalTenders = async () => {
  const response = await API.get(`/institutional-tenders/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

//End of the modification by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code

// End of code addition by - Jhamman Lal Sahu on 05-08-2024
// Reason - To get To get institutional tenders details

//  Added by - Jhamman Lal Sahu on 05-08-2024
//  Reason - To get generic medicines details

//Modified by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code
export const genericMedicines = async () => {
  const response = await API.get(`/generic-medicines/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

//End of the modification by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code

// End of code addition by - Jhamman Lal Sahu on 05-08-2024
// Reason - To get generic medicines details

//  Added by - Jhamman Lal Sahu on 05-08-2024
//  Reason - To get OTC products details

//Modified by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code
export const otcProducts = async () => {
  const response = await API.get(`/otc-products/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

//End of the modification by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code

// End of code addition by - Jhamman Lal Sahu on 05-08-2024
// Reason - To get OTC products details

/**
 * Added by - Ashish Dewangan on 07-08-2024
 * Reason - To get global presence data from backend
 */

export const globalPresence = async () => {
  const response = await API.get(`global-presence/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

/**
 * End of code addition by - Ashish Dewangan on 07-08-2024
 * Reason - To get global presence data from backend
 */

// Addition by - Om Kumar Shrivastava on 01-08-2024
// Reason - To post the search details data
export const postSearchDataApi = async (value) => {
  const response = await API.post(`/search/`, value, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
// End of code addition by - Om Kumar Shrivastava on 01-08-2024
// Reason - To post the search details data

//  Added by - Om Kumar Shrivastava on 08-08-2024
//  Reason - To get Product details

export const getProductDetailsApi = async () => {
  const response = await API.get(`all-product-categories-item/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Om Kumar Shrivastava on 08-08-2024
// Reason - To get Product details

/**
 * Added by - Ashlekh on 08-08-2024
 * Reason - To get Business details
 */
export const getBusinessValueDetailsApi = async () => {
  const response = await API.get(`business-value/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 08-08-2024
 * Reason - To get Business details
 */

/**
 * Added by - Ashlekh on 08-08-2024
 * Reason - To get Management details
 */
export const getManagementDetailsApi = async () => {
  const response = await API.get(`management/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 08-08-2024
 * Reason - To get Management details
 */

//  Added by - Jhamman Lal Sahu on 07-08-2024
//  Reason - To get Regulatory Services details

//  Added by - Jhamman Lal Sahu on 04-08-2024
//  Reason - To get quality control details

export const regulatoryServices = async () => {
  const response = await API.get(`regulatory-services/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

// End of code addition by - Jhamman Lal Sahu on 04-08-2024
// Reason - To get To get quality control details

// End of code addition by - Jhamman Lal Sahu on 07-08-2024
// Reason - To get Regulatory Services details

//  Added by - Jhamman Lal Sahu on 07-08-2024
//  Reason - To get Quality Control Quality Assurance details

//Modified by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code
export const qualityControlQualityAssurance = async () => {
  const response = await API.get(`/quality-control-quality-assurance/`).catch(
    (err) => console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

//End of the modification by - Jhamman lal sahu on 22-08-2024
//Reason - having some issues in previous code

// End of code addition by - Jhamman Lal Sahu on 07-08-2024
// Reason - To get Quality Control Quality Assurance Services details

//  Added by - Jhamman Lal Sahu on 08-08-2024
//  Reason - To get events details

export const events = async () => {
  const response = await API.get(`events/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
// End of code addition by - Jhamman Lal Sahu on 08-08-2024
// Reason - To get events Services details

//  Added by - Jhamman Lal Sahu on 29-09-2024
//  Reason - To get single events details
export const singleEvent = async (eventId) => {
  const response = await API.get(`singleEvent/${eventId}`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
//  End of addition by - Jhamman Lal Sahu on 29-09-2024
//  Reason - To get single events details


/**
 * Added by - Ashlekh on 09-08-2024
 * Reason - To get MissionAndVision details
 */
export const getMissionAndVisionDetailsApi = async () => {
  const response = await API.get(`mission_and_vision/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 09-08-2024
 * Reason - To get MissionAndVision details
 */

/**
 * Added by - Ashlekh on 09-08-2024
 * Reason - To get QualityPolicy details
 */
export const getQualityPolicyDetailsApi = async () => {
  const response = await API.get(`quality_policy/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 09-08-2024
 * Reason - To get QualityPolicy details
 */

/**
 * Added by - Ashlekh on 10-08-2024
 * Reason - To get Why Us details
 */
export const getWhyUsDetailsApi = async () => {
  const response = await API.get(`why_us/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 10-08-2024
 * Reason - To get Why us details
 */

/**
 * Added by - Ashlekh on 11-08-2024
 * Reason - To get ClientSatisfaction details from backend
 */
export const getClientSatisfactionDetailsApi = async () => {
  const response = await API.get(`client_satisfaction/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 11-08-2024
 * Reason - To get ClientSatisfaction details from backend
 */

/**
 * Added by - Ashlekh on 12-08-2024
 * Reason - To get Blog details from backend
 */
export const getBlogDetailsApi = async () => {
  const response = await API.get(`blog/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 12-08-2024
 * Reason - To get Blog details from backend
 */

/**
 * Added by - Ashlekh on 12-08-2024
 * Reason - To get Gallery details from backend
 */
export const getGalleryDetailsApi = async () => {
  const response = await API.get(`gallery/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 12-08-2024
 * Reason - To get Gallery details from backend
 */
/**
 * Added by - Ashlekh on 13-08-2024
 * Reason - API to save Career form
 */
export const postCareerRequestApi = async (data) => {
  // const response = await API.post(`career_request/`, data).catch((err) =>
  //   console.log(err)
  // );
  // if (response.response) {
  //   if (response.response.status == 500) {
  //     console.log(response.response.data);
  //   } else if (response.response.status == 404) {
  //     console.log(response.response.data);
  //   }
  //   return response ? response.data : {};
  // } else {
  //   if (response.status == 200) {
  //     return response ? response.data : {};
  //   }
  // }
  const response = await API.post(`/career_request/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code - Ashlekh on 13-08-2024
 * Reason - API to save Career form
 */

/**
 * Added by - Ashlekh on 13-08-2024
 * Reason - To get career page details from backend
 */
export const getCareerPageDetailsApi = async () => {
  const response = await API.get(`career/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 13-08-2024
 * Reason - To get career page details from backend
 */

/**
 * Added by - Ashlekh on 14-08-2024
 * Reason - To get details of Texfer from backend
 */
export const getTexferDetailsApi = async () => {
  const response = await API.get(`texfer/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 14-08-2024
 * Reason - To get details of Texfer from backend
 */

/**
 * Added by - Ashlekh on 16-08-2024
 * Reason - To post Contact Request
 */
export const postContactRequestApi = async (data) => {
  const response = await API.post(`/contact_request/`, data, {
    headers: {
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code - Ashlekh on 16-08-2024
 * Reason - To post Contact Request
 */

/**
 * Added by - Ashlekh on 16-08-2024
 * Reason - To get Contact details from backend
 */
export const getContactDetailsApi = async () => {
  const response = await API.get(`contact/`).catch((err) => console.log(err));
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Ashlekh on 16-08-2024
 * Reason - To get Contact details from backend
 */

/**
 * Added by - Ashish Dewangan on 17-08-2024
 * Reason - To get certificate data from backend
 */

export const certificate = async () => {
  const response = await API.get(`certificate/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

/**
 * End of code addition by - Ashish Dewangan on 17-08-2024
 * Reason - To get certificate data from backend
 */

/**
 * Added by - Jhamman on 12-08-2024
 * Reason - To post request callback details
 */
export const requestCallbackAPI = async (formData) => {
  // const details=JSON.stringify(formData);
  const response = await API.post(`request-callback/`, formData, {
    headers: {
      "Content-Type": "application/JSON",
    },
  });

  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};

/**
 * End of code - Jhamman on 12-08-2024
 * Reason - To post request callback details
 */

/**
 * Added by - Jhamman lal sahu on 17-08-2024
 * Reason - To get privacy policy details from backend
 */
export const privacyPolicy = async () => {
  const response = await API.get(`privacy-policy/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Jhamman lal sahu on 17-08-2024
 * Reason - To get privacy policy details from backend
 */

/**
 * Added by - Jhamman lal sahu on 17-08-2024
 * Reason - To get terms and conditions details from backend
 */
export const termsAndConditionsDetailsApi = async () => {
  const response = await API.get(`terms-and-conditions/`).catch((err) =>
    console.log(err)
  );
  if (response.response) {
    if (response.response.status == 500) {
      console.log(response.response.data);
    } else if (response.response.status == 404) {
      console.log(response.response.data);
    }
    return response ? response.data : {};
  } else {
    if (response.status == 200) {
      return response ? response.data : {};
    }
  }
};
/**
 * End of code - Jhamman lal sahu on 17-08-2024
 * Reason - To get terms and conditions details from backend
 */
