/* Added by Jhamman Lal Sahu on 07-08-2024
  Reason - to render UI for Regulatory services*/

import { regulatoryServices } from "../../../Api/services";
import Style from "./RegulatoryServices.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../../Api/config";
import { useState, useEffect, useContext } from "react";
import image from "../../../assets/image/regulatory_service_default.png";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const RegulatoryServices = () => {
  const [regulatoryServicesDetails, setRegulatoryServicesDetails] = useState(
    {}
  );
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  /* Added by Jhamman Lal Sahu on 07-08-2024
  Reason - to fetch Regulatory services details*/

  const fetchRegulatoryServicesDetails = async () => {
    try {
      const response = await regulatoryServices();
      setRegulatoryServicesDetails(response);
    } catch (error) {
      console.error("Error fetching Regulatory Services details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },

      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Services", path: "/services/regulatory-services" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path

      { name: "Regulatory Services", path: "/services/regulatory-services" },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchRegulatoryServicesDetails();
  }, []);

  console.log(
    regulatoryServicesDetails?.regulatoryServicesAll_details?.value[2]
      ?.type_of_service_and_Services.length
  );

  /* End of the addition by Jhamman Lal Sahu on 07-08-2024
  Reason - to fetch Regulatory services details*/

  // Modified by Jhamman on 06-09-2024
  // Reason - No need to truncate
  //   Added by - jhamman lal sahu on 25-08-2024
  //   Reason - To truncate title if the word is exceed then 10
  // const truncateTitle = (title) => {
  //   const words = title.split(" ");
  //   if (words.length > 8) {
  //     return words.slice(0, 10).join(" ") + ".....";
  //   }
  //   return title;
  // };
  //End of the addition jhamman lal sahu on 25-08-2024
  //   Reason - To truncate title if the word is exceed then 10
  // Modified by Jhamman on 06-09-2024
  // Reason - No need to truncate

  //commented by - Jhamman lal sahu on 31-08-2024
  //Reason - conditionally handled this below
  // if (!regulatoryServicesDetails) return <p> No deatils found</p>;
  //End of commentation by - Jhamman lal sahu on 31-08-2024
  //Reason - conditionally handled this below

  return (
    <>
      {showContent ? (
        // Added by Jhamman on 31-08-2024
        //Reason - conditional rendering
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        regulatoryServicesDetails
          ?.regulatoryServicesBannerAndDescription_details?.banner_file !=
          null ||
        regulatoryServicesDetails
          ?.regulatoryServicesBannerAndDescription_details
          ?.title_and_description ||
        regulatoryServicesDetails?.regulatoryServicesBannerAndDescription_details?.title_and_description?.trim()
          ?.length > 0 || 
        regulatoryServicesDetails?.regulatoryServicesAll_details?.value
          ?.type_of_service_and_Services ||
        regulatoryServicesDetails?.regulatoryServicesAll_details?.value?.type_of_service_and_Services?.trim()
          ?.length > 0 ? ( */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* // End of addition by Jhamman on 31-08-2024
          //Reason - conditional rendering */}
          <div className={Style.mainContainer}>
            {/* Modified by - Jhamman lal sahu on 17-08-2024
    Reason - Given an alternative option for banner, it can be image to */}

            {regulatoryServicesDetails
              ?.regulatoryServicesBannerAndDescription_details?.banner_file ? (
              /\.(mp4|mkv|webm|avi|mov|flv|wmv)$/i.test(
                regulatoryServicesDetails
                  ?.regulatoryServicesBannerAndDescription_details?.banner_file
              ) ? (
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_video_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <video autoPlay muted loop className={Style.banner_video}>
                  <source
                    src={`${baseURL}${regulatoryServicesDetails?.regulatoryServicesBannerAndDescription_details?.banner_file}`}
                    type={`video/${regulatoryServicesDetails?.regulatoryServicesBannerAndDescription_details?.banner_file
                      .split(".")
                      .pop()}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                /* </div> */
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_image_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <img
                  src={`${baseURL}${regulatoryServicesDetails?.regulatoryServicesBannerAndDescription_details?.banner_file}`}
                  alt="Banner"
                  className={Style.banner_image}
                />
                /* </div> */
              )
            ) : null}

            {/* End of the modification by - Jhamman lal sahu on 17-08-2024
            Reason - Given an alternative option for banner, it can be image to */}

            {/* Added by - Jhamman lal sahu on 18-08-2024
            Reason - To add path in UI */}

            <NavigationPath navigationPathArray={navigationPath} />

            {/* Added by - Jhamman lal sahu on 18-08-2024
            Reason - To add path in UI */}

            <div className={Style.subContainer}>
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* {
                regulatoryServicesDetails
                  ?.regulatoryServicesBannerAndDescription_details
                  ?.title_and_description ||
                regulatoryServicesDetails?.regulatoryServicesBannerAndDescription_details?.title_and_description?.trim()
                  ?.length > 0 ||
                regulatoryServicesDetails?.regulatoryServicesAll_details?.value
                  ?.type_of_service_and_Services ||
                regulatoryServicesDetails?.regulatoryServicesAll_details?.value?.type_of_service_and_Services?.trim()
                  ?.length > 0 ? ( */}
              {
                regulatoryServicesDetails
                  ?.regulatoryServicesBannerAndDescription_details
                  ?.title_and_description ||
                regulatoryServicesDetails?.regulatoryServicesBannerAndDescription_details?.title_and_description?.trim()
                  ?.length > 0 ||
                regulatoryServicesDetails?.regulatoryServicesAll_details?.value
                  ?.length > 0 ? (
                  /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                  /*<div className={Style.pageTitle}>Regulatory Services</div>*/
                  <div className={Style.pageTitle}>
                    <img
                      className={Style.headingImage}
                      src="headingdesign.png"
                    />
                    <div className={Style.headingTitle}>
                      <span className={Style.title1}>Re</span>
                      <span className={Style.title2}>gulatory Services</span>
                    </div>
                  </div>
                ) : (
                  /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                  /*Modified by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
                  /* null */
                  <div className={Style.noDetails}>
                    <p className={Style.noDetailText}>No details available</p>
                  </div>
                )
                /* End of modification by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
              }
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}

              <div className={Style.titleAndDescription}>
                {regulatoryServicesDetails
                  ?.regulatoryServicesBannerAndDescription_details
                  ?.title_and_description &&
                  parse(
                    regulatoryServicesDetails
                      ?.regulatoryServicesBannerAndDescription_details
                      ?.title_and_description
                  )}
              </div>
              <div className={Style.titleAndcardsContainer}>
                {/* Modified by _ jhamman lal sahu on 22-08-2024
          Reason - to fix bug issue */}
                {regulatoryServicesDetails?.regulatoryServicesAll_details?.value
                  .length > 0 ? (
                  <div className={Style.all_cards_title}>
                    {/* Modified by _ jhamman lal sahu on 18-08-2024
          Reason - to fix bug issue */}
                    {parse(
                      regulatoryServicesDetails?.regulatoryServicesAll_details
                        ?.title
                    )}
                  </div>
                ) : null}

                {/* End of the modification _ jhamman lal sahu on 18-08-2024
          Reason - to fix bug issue */}
                {/* End of the modification _ jhamman lal sahu on 22-08-2024
          Reason - to fix bug issue */}
                <div className={Style.cardsContainer}>
                  {regulatoryServicesDetails?.regulatoryServicesAll_details?.value?.map(
                    (data, index) => (
                      <div className={Style.icon_and_cards_container}>
                        {/* Added by - Jhamman Lal Sahu on 08-08-2024
                Reason - Add Icon at the top of every cards */}
                        <div className={Style.relatedIconField}>
                          {data.related_icon ? (
                            <img
                              src={baseURL + data.related_icon}
                              className={Style.relatedIcon}
                            />
                          ) : (
                            <img
                              src={image}
                              className={Style.temporary_image}
                            />
                          )}
                        </div>
                        {/* End of the addition by Jhamman Lal Sahu on 08-08-2024
                Reason - Add Icon at the top of every cards */}

                        <div key={index} className={Style.cards}>
                          {data?.type_of_service_and_Services ? (
                            <div className={Style.cardContent}>
                              {/* Modified by - Jhamman lal sahu on 25-08-2024
                      Reason - sentence that words are more then 10 are will not show */}
                              {parse(
                                /* Commented by Jhamman on 06-09-2024
                            Reason - No need to truncate */
                                /* truncateTitle(data.type_of_service_and_Services) */
                                /* End of commentation by Jhamman on 06-09-2024
                            Reason - No need to truncate */

                                data.type_of_service_and_Services
                              )}
                              {/* End of the modification by - Jhamman lal sahu on 25-08-2024
                      Reason - sentence that words are more then 10 are will not show */}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Commented by Jhamman on 06-09-2024
      Reason - we have to show navigation path wheater content have or not */}
          {/* ) : ( // Added by Jhamman on 31-08-2024 //Reason - No details available
      <div className={Style.noDetails}>
        <p className={Style.noDetailText}>No details available</p>
      </div>
      ) // End of the addition by Jhamman on 31-08-2024 //Reason - No details
      available } */}
          {/* End of commentation by Jhamman on 06-09-2024
      Reason - we have to show navigation path wheater content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
          reason - Show nothing while loading the data*/
        <div className={Style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/}
    </>
  );
};

export default RegulatoryServices;

/* End of the addition by Jhamman Lal Sahu on 07-08-2024
  Reason - to render UI for Regulatory services*/
