// Added by - Jhamman Lal Sahu on 17-08-2024
// Reason - UI to render Privacy policy details

import React, { useEffect, useState, useContext } from "react";
import Style from "./PrivacyPolicy.module.css";
import { privacyPolicy } from "../../Api/services";
import parse from "html-react-parser";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const PrivacyPolicy = () => {
  const [privacyPolicyDetails, setPrivacyPolicyDetails] = useState({});
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);

  //Added by Jhamman Lal sahu on 17-08-2024
  //Reason - API call to get privacy policy details

  const fetchPrivacyPolicyData = async () => {
    const response = await privacyPolicy();
    setPrivacyPolicyDetails(response);
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 18-09-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 18-09-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 17-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Privacy Policy", path: "/privacy-policy" },
    ]);
    /**
     * End of code addition by - Jhamman lal sahu on 017-08-2024
     * Reason - To add paths to navigation path variable
     */

    fetchPrivacyPolicyData();
  }, []);

  //End of the addition by Jhamman Lal sahu on 17-08-2024
  //Reason - API call to get privacy policy details

  if (!privacyPolicyDetails) return <p>No details available</p>;

  return (
    <div className={Style.mainContainer}>
      <div className={Style.subContainer}>
        <NavigationPath navigationPathArray={navigationPath} />

        {privacyPolicyDetails?.privacyPolicy_details?.title ? (
          <div className={Style.title_field}>
            <div className={Style.title}>
              {parse(privacyPolicyDetails.privacyPolicy_details.title)}
            </div>
          </div>
        ) : null}

        {privacyPolicyDetails?.privacyPolicy_details?.description ? (
          <div className={Style.description_field}>
            <div className={Style.description}>
              {parse(privacyPolicyDetails.privacyPolicy_details.description)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PrivacyPolicy;

// End of the addition by Jhamman Lal Sahu on 17-08-2024
// Reason - UI to render Privacy Policy details
