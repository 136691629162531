/**
 * Added by - Ashish Dewangan on 08-08-2024
 * Reason - Created Country card to show details of the country
 */
import countryCardStyle from "./CountryCard.module.css";
const CountryCard = ({ country }) => {
  return (
    <div className={countryCardStyle.card}>
      <div
        className={`${countryCardStyle.label} ${countryCardStyle.modalCountryName}`}
      >
        {country.country_name}
      </div>
      {country.calling_code && country.calling_number && (
        /**
         * Modified by - Ashish Dewangan on 21-08-2024
         * Reason - To align colons in same line
         */
        //     <div>
        // <span className={countryCardStyle.label}>Contact Number :</span>{" "}
        // <span>
        //   {country?.calling_code} {country.calling_number}
        // </span>

        <div className={`${countryCardStyle.column}`}>
          <div className={` ${countryCardStyle.column}`}>
            <div className={`${countryCardStyle.label}`}>Contact Number</div>

            <div style={{ width: "10px", fontWeight: "bold" }}>: </div>
          </div>

          <div>
            {country?.calling_code} {country.calling_number}
          </div>
        </div>
        /**
         * End modification and modified by - Ashish Dewangan on 21-08-2024
         * Reason - To align colons in same line
         */
      )}
      {country.email && (
        /**
         * Modified by - Ashish Dewangan on 21-08-2024
         * Reason - To align colons in same line
         */
        // <div>
        //     <span className={countryCardStyle.label}>Email : </span>{" "}
        //     {country.email}
        //   </div>

        <div className={`${countryCardStyle.column}`}>
          <div className={` ${countryCardStyle.column}`}>
            <div className={`${countryCardStyle.label}`}> Email </div>
            <div style={{ width: "10px", fontWeight: "bold" }}>: </div>
          </div>{" "}
          <div>{country.email}</div>
        </div>
        /**
         * End of code modification by - Ashish Dewangan on 21-08-2024
         * Reason - To align colons in same line
         */
      )}
      {country.address && (
        /**
         * Modified by - Ashish Dewangan on 21-08-2024
         * Reason - To align colons in same line
         */
        //  <div>
        //     <span className={countryCardStyle.label}>Address : </span>{" "}
        //     {country.address}{" "}
        //   </div>

        <div className={`${countryCardStyle.column}`}>
          <div className={` ${countryCardStyle.column}`}>
            <div className={`${countryCardStyle.label}`}>Address </div>
            <div style={{ width: "10px", fontWeight: "bold" }}>: </div>
          </div>

          <div style={{ wordBreak: "break-all", wordWrap: "break-word" }}>
            {country.address}
          </div>
        </div>
        /**
         * End of modification by - Ashish Dewangan on 21-08-2024
         * Reason - To align colons in same line
         */
      )}

      {country.google_map_link?.length > 0 ? (
        <div>
          <iframe
            src={country.google_map_link}
            className={countryCardStyle.map}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CountryCard;
