/**
 * Created by - Ashlekh on 09-08-2024
 * Reason - To have MissionVision section
 */

import { useContext, useEffect, useState } from "react";
import style from "./missionVision.module.css";
import { getMissionAndVisionDetailsApi } from "../../../Api/services";
import config from "../../../Api/config";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";
const MissionVision = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [missionVisionDetails, setMissionAndVisionDetails] = useState([]);
  const [showContent, setShowContent] = useState(false);
  // Added by - Ashlekh on 09-08-2024
  // Reason - This useEffect will executes when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    getMissionVisionDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // { name: "Company Profile", path: "/company_profile/mission-vision" },
      { name: "Mission Vision", path: "/company_profile/mission-vision" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 09-08-2024
  // Reason - This useEffect will executes when page loads

  // Added by - Ashlekh on 09-08-2024
  // Reason - To get Mission Vision details from backend
  const getMissionVisionDetails = async () => {
    try {
      const response = await getMissionAndVisionDetailsApi();
      setMissionAndVisionDetails(response?.mission_and_vision);
    } catch (error) {
      console.error("Error fetching Mission and Vision details");
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 09-08-2024
  // Reason - To get Mission Vision details from backend
  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition of No details available */}
          {/* {missionVisionDetails?.length > 0 ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition of No details available */}
          <div className={`${style.mainContainer}`}>
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 07-09-2024
        Reason - To display No details available */}
            {missionVisionDetails?.length == 0 && (
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
          Reason - To display No details available */}
            {missionVisionDetails?.length > 0 ? (
              <div className={`${style.titleContainer}`}>
                {/* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading */}
                <div className={`${style.title}`}>Mission and Vision</div>
                {/* <div className={style.pageTitle}>
                  <img className={style.headingImage} src="headingdesign.png" />
                  <div className={style.headingTitle}>
                    <span className={style.title1}>Mi</span>
                    <span className={style.title2}>ssion and Vision</span>
                  </div>
                </div> */}
                {/* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading */}
                <div className={`${style.verticalLine}`}>
                  <div className={`${style.line}`}></div>
                </div>
              </div>
            ) : null}
            <div className={`${style.container}`}>
              {missionVisionDetails?.map((item, index) => (
                <div className={`${style.subContainer}`}>
                  <div className={`${style.iconContainer}`}>
                    <img
                      className={`${style.icon}`}
                      src={config.baseURL + item?.icon}
                      alt=""
                    />
                  </div>
                  <div className={`${style.verticalLine}`}>
                    <div className={`${style.line2}`}></div>
                  </div>
                  <div className={`${style.titleContainer2}`}>{item.title}</div>
                  <div className={`${style.descriptionContainer}`}>
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Commented by - Ashlekh on 07-09-2024
        Reason - To change condition for No details available */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
    reason - Show nothing while loading the data*/}
    </>
  );
};
export default MissionVision;
