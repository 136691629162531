/**
 * Created by - Ashlekh on 08-08-2024
 * Reason - To have Management section
 */

import { useContext, useEffect, useState } from "react";
import style from "./management.module.css";
import { getManagementDetailsApi } from "../../../Api/services";
import config from "../../../Api/config";
import parse from "html-react-parser";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";
import { Link } from "react-router-dom";
import { Modal } from "antd";

const Management = () => {
  const [managementDetails, setManagementDetails] = useState({});
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  // Added by - Ashlekh on 02-09-2024
  // Reason - To add useState for Member
  const [managementMember, setManagementMember] = useState([]);
  // End of code - Ashlekh on 02-09-2024
  // Reason - To add useState for Member
  // Added by - Ashlekh on 03-09-2024
  // Reason - To have useState for filter member
  const [filterMember, setFilterMember] = useState({});
  // End of code - Ashlekh on 03-09-2024
  // Reason - To have useState for filter member
  // Added by - Ashlekh on 04-09-2024
  // Reason - To have useState for antd modal
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [modelWidth, setModelWidth] = useState();
  const [showContent, setShowContent] = useState(false);
  // End of code - Ashlekh on 04-09-2024
  // Reason - To have useState for antd modal
  // Added by - Ashlekh on 08-08-2024
  // Reason - This useEffect will execute when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    getManagementDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // { name: "Company Profile", path: "/company_profile/about_us" },
      { name: "Management", path: "/company_profile/management" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    /**
     * Added by - Ashlekh on 04-09-2024
     * Reason - To handle antd model size for different screens size
     */
    handleScreenWidth();
    window.addEventListener("resize", handleScreenWidth);
    return () => window.removeEventListener("resize", handleScreenWidth);
    /**
     * End of code - Ashlekh on 04-09-2024
     * Reason - To handle antd model size for different screens size
     */
  }, []);
  // End of code - Ashlekh on 08-08-2024
  // Reason - This useEffect will execute when page loads

  // Added by - Ashlekh on 04-09-2024
  // Reason - To set width for different screen size
  const handleScreenWidth = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 320) {
      setModelWidth("90%");
    } else if (screenWidth <= 415) {
      setModelWidth("95%");
    } else if (screenWidth <= 600) {
      setModelWidth("500px");
    } else if (screenWidth <= 720) {
      setModelWidth("600px");
    } else if (screenWidth <= 900) {
      setModelWidth("700px");
    } else if (screenWidth <= 1200) {
      setModelWidth("800px");
    } else {
      setModelWidth("50%");
    }
  };
  // End of code - Ashlekh on 04-09-2024
  // Reason - To set width for different screen size

  // Added by - Ashlekh on 08-08-2024
  // Reason - To get Management details from backend
  const getManagementDetails = async () => {
    try {
      const response = await getManagementDetailsApi();
      setManagementDetails(response?.management);
      // Added by - Ashlekh on 02-09-2024
      // Reason - To set response of management
      setManagementMember(response?.management_member);
      // End of code - Ashlekh on 02-09-2024
      // Reason - To set response of management
    } catch (error) {
      console.error("Error fetching Management details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 08-08-2024
  // Reason - To get Management details from backend

  // Added by - Ashlekh on 03-09-2024
  // Reason - When we click on view more then to filter details and store in member variable
  const openMemberDetailPopup = (itemId) => {
    const member = managementMember.find((item) => item.id === itemId);
    setFilterMember(member);
    // Added by - Ashlekh on 04-09-2024
    // Reason -
    setSelectedMember(member);
    setIsModelVisible(true);
    // End of code - Ashlekh on 04-09-2024
    // Reason -
  };
  // End of code - Ashlekh on 03-09-2024
  // Reason - When we click on view more then to filter details and store in member variable

  // Added by - Ashlekh on 03-09-2024
  // Reason - To close popup
  const closeMemberDetailPopup = () => {
    setFilterMember(null);
    // Added by - Ashlekh on 04-09-2024
    // Reason -
    setIsModelVisible(false);
    // End of code - Ashlekh on 04-09-2024
    // Reason -
  };
  // End of code - Ashlekh on 03-09-2024
  // Reason - To close popup

  // Added by - Ashlekh on 10-09-2024
  // Reason - To handle the closing of antd model when browser back button is pressed
  useEffect(() => {
    const handlePopState = () => {
      if (isModelVisible) {
        closeMemberDetailPopup();
      }
    };

    if (isModelVisible) {
      window.history.pushState({ isPopupOpen: true }, "");
      window.addEventListener("popstate", handlePopState);
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
      if (window.history.state && window.history.state.isPopupOpen) {
        window.history.back();
      }
    };
  }, [isModelVisible]);
  // End of code - Ashlekh on 10-09-2024
  // Reason - To handle the closing of antd model when browser back button is pressed
  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition of No details available */}
          {/* {managementDetails?.banner_image || managementDetails?.description ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition of No details available */}
          <div className={`${style.mainContainer}`}>
            {managementDetails?.banner_image ? (
              <div className={`${style.bannerImageContainer}`}>
                <img
                  className={`${style.bannerImage}`}
                  src={config.baseURL + managementDetails?.banner_image}
                  alt=""
                />
              </div>
            ) : null}
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 07-09-2024
        Reason - To display page title and No details available */}
            {managementDetails?.description ||
            managementDetails?.description?.trim()?.length > 0 ||
            managementMember?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={`${style.pageTitle}`}>Management</div> */
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Ma</span>
                  <span className={style.title2}>nagement</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
        Reason - To display page title and No details available */}
            {managementDetails?.description ? (
              <div className={`${style.description}`}>
                {" "}
                {parse(managementDetails?.description)}{" "}
              </div>
            ) : null}
            {/* Added by - Ashlekh on 02-09-2024
          Reason - To add Member section */}
            {managementMember?.length > 0 ? (
              <div className={`${style.managementMemberContainer}`}>
                {managementMember.map((item, index) => (
                  <div
                    key={index}
                    className={`${style.managementMemberSubContainer}`}>
                    <img
                      className={`${style.managementMemberImage}`}
                      src={config.baseURL + item.image}
                      alt=""
                    />
                    <div className={`${style.managementMemberName}`}>
                      {item?.name}
                    </div>
                    <div className={`${style.managementMemberDesignation}`}>
                      {item?.designation}
                    </div>
                    <div className={`${style.viewMore}`}>
                      <div
                        className={`${style.navigationLink}`}
                        onClick={() => openMemberDetailPopup(item.id)}>
                        View more
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {/* End of code - Ashlekh on 02-09-2024
          Reason - To add Member section */}
            {/* Added by - Ashlekh on 03-09-2024
          Reason - To share filterMember details */}
            {/* {filterMember && (
            <MemberDetailPopup
              memberDetail={filterMember}
              isClose={closeMemberDetailPopup}
            />
          )} */}
            {/* Added by - Ashlekh on 04-09-2024
          Reason - To open antd modal */}
            <Modal
              title=""
              visible={isModelVisible}
              onCancel={closeMemberDetailPopup}
              footer={null}
              width={modelWidth}>
              {selectedMember && (
                <div className={`${style.popupContainer}`}>
                  <div className={`${style.filterMemberImageContainer}`}>
                    <img
                      src={config.baseURL + selectedMember?.image}
                      className={`${style.filterMemberImage}`}
                    />
                  </div>
                  <div className={`${style.popupSubContainer}`}>
                    <div className={`${style.popupName}`}>
                      {selectedMember.name}
                    </div>
                    <div className={`${style.popupDesignation}`}>
                      {selectedMember.designation}
                    </div>
                    <div className={`${style.popupDescription}`}>
                      {/* {selectedMember.description} */}{" "}
                      {parse(selectedMember.description)}{" "}
                    </div>
                  </div>
                </div>
              )}
            </Modal>
            {/* End of code - Ashlekh on 04-09-2024
          Reason - To open antd modal */}
            {/* End of code - Ashlekh on 03-09-2024
          Reason - To share filterMember details */}
          </div>
          {/* Commented by - Ashlekh on 07-09-2024
        Reason - To change condition of No details available */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition of No detail available */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
    reason - Show nothing while loading the data*/}
    </>
  );
};
export default Management;
