/**
 * Created by - Ashish Dewangan on 08-07-2024
 * Reason - To have header component
 */
import React, { useContext, useEffect, useState } from "react";
import headerStyle from "./header.module.css";
import config from "../../Api/config";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/Context";
import TopHeader from "./TopHeader";
import { GiHamburgerMenu } from "react-icons/gi";
import { Drawer } from "antd";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdDownload } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
// import topHeaderStyle from "../Header/topHeader.module.css";
import { FaMobileAlt } from "react-icons/fa";
// import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { IoHome } from "react-icons/io5";
import { GrHomeRounded } from "react-icons/gr";
import Marquee from "react-fast-marquee";

const Header = () => {
  // Added by - Ashlekh on 10-09-2024
  // Reason - To fix header size dynamically (when user scrolls in page)
  // const [headerSize, setHeaderSize] = useState("22vh");
  // End of code - Ashlekh on 10-09-2024
  // Reason - To fix header size dynamically (when user scrolls in page)
  const { user, setUser, settingDetail } = useContext(GlobalContext);
  // const [logo, setLogo] = useState("");
  // const navigate = useNavigate();
  // Added by - Ashlekh on 23-08-2024
  // Reason - To have useState for highlighting header
  const [activeLink, setActiveLink] = useState("");
  // End of code - Ashlekh on 23-08-2024
  // Reason - To have useState for highlighting header

  //Added by -Jhamman Lal Sahu on 29-07-2024
  // Reason - To get the location of current page

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");

  const [isOpen, setIsOpen] = React.useState(false); //Addition by Om on 12-08-2024 because handle the antd drawer

  const [showSubmenu1, setShowSubmenu1] = useState(false); //Addition by on 12-08-2024 because open the nested menus
  const [showSubmenu2, setShowSubmenu2] = useState(false); //Addition by on 12-08-2024 because open the nested menus
  const [showSubmenu3, setShowSubmenu3] = useState(false); //Addition by on 29-08-2024 because open the nested menus

  useEffect(() => {
    setCurrentPath(location.pathname);
    // Added by - Ashlekh on 23-08-2024
    // Reason - To highlight header
    setActiveLink(location.pathname);
    // End of code - Ashlekh on 23-08-2024
    // Reason - To highlight header
  }, [location.pathname]);

  //End of the addition by Jhamman Lal Sahu on 29-07-2024
  // Reason - To get the location of current page

  //Added by -Jhamman Lal Sahu on 31-07-2024
  //Reason - To Modify Home header ( Top Header will hide and Main header get color after scroll down)

  const [colorChange, setColorChange] = useState(" ");
  const [topHeaderVisibility, setTopHeaderVisibility] = useState(" ");
  useEffect(() => {
    const changeNavbarColor = () => {
      //Commented by - Jhamman Lal Sahu on 05-08-2024
      //Reason - It showing padding in bottom of main header after scrolling

      //if (window.scrollY >= 80) {

      //End of the Comment by Jhamman Lal Sahu on 05-08-2024
      //Reason - It showing padding in bottom of main header after scrolling

      if (window.scrollY >= 120) {
        // Modified by - Jhamman on 31-08-2024
        // Reason - added background image instead of background color
        // setColorChange("var(--header-background-color)");
        setColorChange("var(--header-background-image)");
        // Modified by - Jhamman on 31-08-2024
        // Reason - added background image instead of background color
        setTopHeaderVisibility("none");
        // Added by - Ashlekh on 10-09-2024
        // Reason - To set header size dynamically when we scroll page
        // setHeaderSize("6.6vh");
        // End of code - Ashlekh on 10-09-2024
        // Reason - To set header size dynamically when we scroll page
      } else {
        setColorChange("var(--transparent-background)");
        /**
         * Modified by - Ashish Dewangan on 25-08-2024
         * Reason - To arrange header items which were restricted if we use block
         */
        // setTopHeaderVisibility("block");
        setTopHeaderVisibility("flex");
        /**
         * End of modification by - Ashish Dewangan on 25-08-2024
         * Reason - To arrange header items which were restricted if we use block
         */
        // Added by - Ashlekh on 10-09-2024
        // Reason - To set header size dynamically when user scrolls page
        // setHeaderSize("22vh");
        // End of code - Ashlekh on 10-09-2024
        // Reason - To set header size dynamically when user scrolls page
      }
    };
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  //End of the addition by Jhamman Lal Sahu on 31-07-2024
  //Reason - To Modify Home header ( Top Header will hide and Main header get color after scroll down)//

  useEffect(() => {
    window.scrollTo(0, 0);
    getHeaderLogo();
  }, []);

  const getHeaderLogo = async () => {};

  /**
   * Commented by - Ashish Dewangan on 05-08-2024
   * Reason - Logout functionality is not required in this project
   */
  // const handleLogout = () => {
  //   localStorage.clear();
  //   setUser({});
  //   navigate("/login");
  // };
  /**
   * End of comment by - Ashish Dewangan on 05-08-2024
   * Reason - Logout functionality is not required in this project
   */

  const closeDrawer = () => {
    setIsOpen((prevState) => !prevState);
    // Added by - Ashlekh on 14-09-2024
    // Reason - To close all submenus when drawer is closed
    setShowSubmenu1(false);
    setShowSubmenu2(false);
    setShowSubmenu3(false);
    // End of code - Ashlekh on 14-09-2024
    // Reason - To close all submenus when drawer is closed
  };

  return (
    <div
      className={`${headerStyle.header} `}
      // End of code - Ashlekh on 10-09-2024
      // Reason - To change header size dynamically
      // style={{ height: headerSize,}}
      // Added by - Ashlekh on 10-09-2024
      // Reason - To change header size dynamically
    >
      <div
        className={headerStyle.topHeaderContainer}
        style={
          currentPath == "/"
            ? { display: topHeaderVisibility }
            : { display: topHeaderVisibility }
        }>
        {/* File added by Om Shrivastava on 31-07-2024 */}
        <TopHeader />
        {/* End of file addition by Om Shrivastava on 31-07-2024 */}
      </div>
      <div
        className={`${headerStyle.mainHeaderContainer}  `}
        /* Commented by Jhamman on 04-09-2024
    Reason - define external css on header.module.css*/
        // style={
        /* Added by - Jhamman lal Sahu on 01-09-2024
      Reason - Set image in main header */
        // { backgroundImage: "var(--mainheader-background-image)" }
        // }
        /* End of addition by - Jhamman lal Sahu on 01-09-2024
  Reason - Set image in main header */
        /* End of commentation by Jhamman on 04-09-2024
    Reason - define external css on header.module.css*/

        // Commented by Jhamman on 01-09-2024
        // Reason - removed transparency
        // currentPath == "/" ?
        // Modified by - Jhamman on 31-08-2024
        // Reason - added background image instead of background color
        //  { backgroundColor: colorChange }
        // : { backgroundColor: "var(--header-background-color)" }
        // { backgroundImage: colorChange }
        // : { backgroundImage: "var(--header-background-image)" }
        // Modified by - Jhamman on 31-08-2024
        // Reason - added background image instead of background color
        // Commented by Jhamman on 01-09-2024
        // Reason - removed transparency
      >
        {/* Commented by -Jhamman Lal Sahu on 05-08-2024
        Reason - Ashish sir said Logo will not appear in main header */}

        {/* <div className={`${headerStyle.logoContainer}`}>
          <div className={`${headerStyle.navbarItem} `}>
            {logo?.length > 0 ? (
              <Link to="/">
                <img
                  src={config.baseURL + logo}
                  alt=""
                  className={`${headerStyle.logo}`}
                />
              </Link>
            ) : (
              <div className={`${headerStyle.logo}`}>Pharma</div>
            )}
          </div>
        </div> */}

        {/* End of the commentation by Jhamman Lal Sahu on 05-08-2024
        Reason - Ashish sir said Logo will not appear in main header */}

        {/* Added by - Jhamman lal Sahu on 28-07-2024
          Reason - Create a NavBar */}
        {/* Addition by Om Shrivastava on 12-08-2024
          Reason : Add the hamburger icon  */}
        <div className={`${headerStyle.iconContainer} `}>
          <GiHamburgerMenu
            onClick={closeDrawer}
            className={`${headerStyle.button}`}
          />
        </div>
        {/* End of addition by Om Shrivastava on 12-08-2024
          Reason : Add the hamburger icon  */}
        {/* Addition by Om Shrivastava on 12-08-2024
Reason : Add the drawer  */}
        <Drawer
          headerStyle={{
            //Modified by Jhamman on 30-08-2024
            //Reason - Adde image instead of color
            // backgroundColor: "red",
            // backgroundImage: "var(--header-background-image)",
            backgroundImage: "var(--main-header-background-image)",
            //Ed of modification by Jhamman on 30-08-2024
            //Reason - Adde image instead of color
            display: "flex",

            //commented by Jhamman on 30-08-2024
            //Reason - company name and cross sign is not properly adjusted
            // flexDirection: "column",
            //End of commentation by Jhamman on 30-08-2024
            //Reason - company name and cross sign is not properly adjusted
          }}
          open={isOpen}
          onClose={closeDrawer}
          placement="right"
          className={`${headerStyle.hamburgerDrawer}`}
          /* Modified by Jhamman on 29-08-2024
          Reason - changing name from "Pharma 9M" to "9m India"*/
          // title="Pharma 9M"
          title="9m India"
          /* End of modification by Jhamman on 29-08-2024
        Reason - changing name from "Pharma 9M" to "9m India"*/

          /* Added by - Jhamman lal Sahu on 05-09-2024
          Reason - Added width of drawer */
          width={280}
          /* End of addition by - Jhamman lal Sahu on 05-09-2024
      Reason - Added width of drawer */
        >
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
            }}
            className={`${headerStyle.drawerMenu}}`}>
            <li className={headerStyle.li}>
              <Link onClick={closeDrawer} to="/" className={headerStyle.link}>
                Home
              </Link>
            </li>
            <hr className={`${headerStyle.horizontalLine}`} />
            <li className={headerStyle.li}>
              <Link
                className={headerStyle.link}
                onClick={() => setShowSubmenu1(!showSubmenu1)}
                style={{ textDecoration: "none", color: "black" }}>
                Infrastructure
                {showSubmenu1 ? (
                  <FaChevronUp className={headerStyle.icon} />
                ) : (
                  <FaChevronDown className={headerStyle.icon} />
                )}
              </Link>
              {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
              {/* <hr className={`${headerStyle.dropdownElementsHorizontalLine}`} /> */}
              {showSubmenu1 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When Manufacturing is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu1(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When Manufacturing is clicked then menu drop down will close
                      to="/infrastructure/manufacturing"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Manufacturing
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu1 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When Packaging is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu1(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When Packaging is clicked then menu drop down will close
                      to="/infrastructure/packaging"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Packaging{" "}
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu1 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When R&D is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu1(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When R&D is clicked then menu drop down will close
                      to="/infrastructure/research-and-development"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      R&D{" "}
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu1 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When Accredetation is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu1(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When Accredation is clicked then menu drop down will close
                      to="/infrastructure/accreditation"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Accredetation
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu1 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When Quality Control is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu1(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When Quality Control is clicked then menu drop down will close
                      to="/infrastructure/quality-control"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Quality Control
                    </Link>
                  </li>
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                </div>
              )}
            </li>
            <hr className={`${headerStyle.horizontalLine}`} />
            <li className={headerStyle.li}>
              {/* <div>
                <img style={{ width: "5vw" }} src="about.png" alt="" />
              </div> */}

              <Link
                onClick={() => setShowSubmenu2(!showSubmenu2)}
                style={{ textDecoration: "none", color: "black" }}
                className={headerStyle.link}>
                Company Profile
                {showSubmenu2 ? (
                  <FaChevronUp className={headerStyle.icon} />
                ) : (
                  <FaChevronDown className={headerStyle.icon} />
                )}
              </Link>

              {showSubmenu2 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When about us is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu2(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When about us is clicked then menu drop down will close
                      to="/company_profile/about_us"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      About Us
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu2 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When mission vision is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu2(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When mission vision is clicked then menu drop down will close
                      to="/company_profile/mission-vision"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Mission Vision
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu2 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When business value is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu2(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When business value is clicked then menu drop down will close
                      to="/company_profile/business-value"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Business Value
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu2 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When management is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu2(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When management is clicked then menu drop down will close
                      to="/company_profile/management"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Management
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu2 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When why us is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu2(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When why us is clicked then menu drop down will close
                      to="/company_profile/why-us"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Why Us
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu2 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When quality policy is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu2(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When quality policy is clicked then menu drop down will close
                      to="/company_profile/quality-policy"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      Quality Policy
                    </Link>
                  </li>
                  {/* Modified by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                  {/* End of modification by - Jhamman on 29-08-2024
                  reason - change bottom horizontal line design */}
                </div>
              )}
              {showSubmenu2 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When client statisfaction is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu2(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When client satisfaction is clicked then menu drop down will close
                      to="/company_profile/client-satisfaction"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* Client satisfaction */}
                      Client Satisfaction
                    </Link>
                  </li>
                </div>
              )}
            </li>
            <hr className={`${headerStyle.horizontalLine}`} />

            <li className={headerStyle.li}>
              {/* <div>
                <img style={{ width: "5vw" }} src="contact.png" alt="" />
              </div> */}

              <Link
                onClick={closeDrawer}
                to="/global-presence"
                className={headerStyle.link}>
                Global Presence
              </Link>
            </li>
            <hr className={`${headerStyle.horizontalLine}`} />

            {/* Commented by - Jhamman on 29-08-2024
                Reason - i create service dropdown below */}
            {/* <li className={headerStyle.li}>
              <div>
                <img
                  style={{ width: "6.5vw" }}
                  src="donation_drawer_img.png"
                  alt=""
                />
              </div>
              <Link
                // onClick={closeDrawer}
                // to="/donation"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                Services
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li> */}
            {/* End of commentation by - Jhamman on 29-08-2024
                Reason - i create service dropdown below */}

            {/* Added by - jhamman on 29-08-2024
            Reason - to add service dropdown */}

            <li className={headerStyle.li}>
              <Link
                className={headerStyle.link}
                onClick={() => setShowSubmenu3(!showSubmenu3)}
                style={{ textDecoration: "none", color: "black" }}>
                Services
                {showSubmenu3 ? (
                  <FaChevronUp className={headerStyle.icon} />
                ) : (
                  <FaChevronDown className={headerStyle.icon} />
                )}
              </Link>
              {showSubmenu3 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When contract manufacturing is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu3(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When contract manufacturing is clicked then menu drop down will close
                      to="/services/contract-manufacturing"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* Contract manufacturing */}
                      Contract Manufacturing
                    </Link>
                  </li>
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                </div>
              )}
              {showSubmenu3 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When Third party manufacturing is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu3(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When Third party manufacturing is clicked then menu drop down will close
                      to="/services/third-party-manufacturing"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* Third party manufacturing{" "} */}
                      Third Party Manufacturing
                    </Link>
                  </li>
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                </div>
              )}
              {showSubmenu3 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When Institutional tenders is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu3(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When Institutional tenders is clicked then menu drop down will close
                      to="/services/institutional-tenders"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* Institutional tenders{" "} */}
                      Institutional Tenders
                    </Link>
                  </li>
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                </div>
              )}
              {showSubmenu3 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When generic medicine is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu3(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When generic medicine is clicked then menu drop down will close
                      to="/services/generic-medicines"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* Generic medicines */}
                      Generic Medicines
                    </Link>
                  </li>
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                </div>
              )}
              {showSubmenu3 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When OTC Product is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu3(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When OTC Product is clicked then menu drop down will close
                      to="/services/otc-products"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* OTC products */}
                      OTC Products
                    </Link>
                  </li>
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                </div>
              )}
              {showSubmenu3 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When Regulatory services is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu3(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When regulatory services is clicked then menu drop down will close
                      to="/services/regulatory-services"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* Regulatory services */}
                      Regulatory Services
                    </Link>
                  </li>
                  <hr
                    className={`${headerStyle.dropdownElementsHorizontalLine}`}
                  />
                </div>
              )}
              {showSubmenu3 && (
                <div>
                  {/* Modfied by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                  {/* <li className={headerStyle.li}> */}
                  <li className={headerStyle.submenuLi}>
                    {/* End of modification by Jhamman on 01-09-2024
                Reason - Have to provide different css */}
                    <Link
                      // Code changed by - Ashlekh on 11-09-2024
                      // Reason - When QC QA is clicked then menu drop down will close
                      // onClick={closeDrawer}
                      onClick={() => {
                        setShowSubmenu3(false);
                        closeDrawer();
                      }}
                      // End of code - Ashlekh on 11-09-2024
                      // Reason - When QC QA is clicked then menu drop down will close
                      to="/services/quality-control-quality-assurance"
                      /*Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink*/
                      // className={`${headerStyle.link}`}>
                      className={`${headerStyle.subLink}`}
                      /* Modified by Jhamman on 26-09-2024
                      Reason- Changed css from link to subLink */
                      style={{ padding: "9px 10px 9px 25px" }}>
                      {/* Modified by jhamman on 21-09-2024
                      Reason - changed name*/}
                      {/* QCQA */}
                      QC&QA
                      {/* End of commentation by jhamman on 21-09-2024
                Reason - changed name*/}
                    </Link>
                  </li>
                  {/* <hr className={`${headerStyle.horizontalLine}`} /> */}
                </div>
              )}
            </li>
            <hr className={`${headerStyle.horizontalLine}`} />

            {/* End of addition by Jhamman on 29-08-2024
            Reason - to add service dropdown */}

            <li className={headerStyle.li}>
              {/* <div>
                <img
                  style={{ width: "6.5vw" }}
                  src="donation_drawer_img.png"
                  alt=""
                />
              </div> */}
              <Link
                /**
                 * Added by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                onClick={closeDrawer}
                /**
                 * End of code addition by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                // to="/product-details"
                to="all-products"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                Products
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li>
            <li className={headerStyle.li}>
              <Link
                /**
                 * Added by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                onClick={closeDrawer}
                /**
                 * End of code addition by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                to="/events"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                Events
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li>
            {/* Added by - Ashlekh on 17-08-2024
            Reason - To add Gallery Blog Career Texfer and Contact Us */}
            <li className={headerStyle.li}>
              <Link
                /**
                 * Added by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                onClick={closeDrawer}
                /**
                 * End of code addition by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                to="/gallery"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                Gallery
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li>
            <li className={headerStyle.li}>
              <Link
                /**
                 * Added by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                onClick={closeDrawer}
                /**
                 * End of code addition by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                to="/blog"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                Blog
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li>
            <li className={headerStyle.li}>
              <Link
                /**
                 * Added by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                onClick={closeDrawer}
                /**
                 * End of code addition by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                to="/career"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                Career
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li>
            <li className={headerStyle.li}>
              <Link
                /**
                 * Added by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                onClick={closeDrawer}
                /**
                 * End of code addition by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                to="/texfer"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                Texfer
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li>
            <li className={headerStyle.li}>
              <Link
                /**
                 * Added by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                onClick={closeDrawer}
                /**
                 * End of code addition by - Ashish Dewangan on 23-08-2024
                 * Reason - To close the drawer when we click on header link
                 */
                to="/contact"
                className={`${headerStyle.link} `}
                style={{ textDecoration: "none", color: "black" }}>
                {/* Modified by jhamman on 21-09-2024
                Reason - changed name*/}
                {/* Contact */}
                Contact Us
                {/* End of commentation by jhamman on 21-09-2024
                Reason - changed name*/}
              </Link>
              <hr className={`${headerStyle.horizontalLine}`} />
            </li>
            {/* End of code - Ashlekh on 17-08-2024
            Reason - To add Gallery Blog Career Texfer and Contact Us */}

            {/*
             * Added by - Ashish Dewangan on 23-08-2024
             * Reason - Added download brochure button
             */}
            {settingDetail.brochure &&
              settingDetail.brochure?.trim()?.length > 0 && (
                <li className={`${headerStyle.li}`}>
                  <Link
                    onClick={closeDrawer}
                    className={`${headerStyle.link}`}
                    to={`${config.baseURL}${settingDetail?.brochure}`}
                    target="_blank">
                    {/* Download brochure */}
                    Download Brochure
                    <MdDownload className={`${headerStyle.downloadBroucherIconForDrawer}`} style={{ fontSize: "25px" }} />
                  </Link>
                  <hr className={`${headerStyle.horizontalLine}`} />
                </li>
              )}

            {/*
             * End of addition by - Ashish Dewangan on 23-08-2024
             * Reason - Added download brochure button
             */}
          </ul>
        </Drawer>
        {/* End of addition by Om Shrivastava on 12-08-2024
        Reason : Add the drawer  */}

        {/* Addition by Jhamman on 14-09-2024
          Reason - Added mobile number and email in main header in mobile view*/}

        <div className={`${headerStyle.contactContainer}`}>
          <Marquee>
            {settingDetail?.career_contact_number ? (
              <div className={headerStyle.contacts}>
                {/* <FaMobileAlt className={headerStyle.callIcon}/> */}
                <IoMdCall className={headerStyle.callIcon} />
                <b
                  className={headerStyle.titleOfContacts}
                  style={{ fontWeight: "500" }}>
                  For Career :
                </b>
                <span>+91-{settingDetail?.career_contact_number}</span>
              </div>
            ) : null}

            {/* {settingDetail?.email ? (
            <div className={headerStyle.contacts}>
              <MdEmail className={headerStyle.emailIcon} />
              <span>{settingDetail?.email}</span>
            </div>
          ) : null} */}

            {settingDetail?.business_contact_number ? (
              <div className={headerStyle.contacts}>
                <IoMdCall className={headerStyle.callIcon} />
                <b
                  className={headerStyle.titleOfContacts}
                  style={{ fontWeight: "500" }}>
                  For Business :
                </b>
                <span>+91-{settingDetail?.business_contact_number}</span>
              </div>
            ) : null}

            {settingDetail?.email || settingDetail?.second_email ? (
              <div className={headerStyle.contacts}>
                <MdEmail className={headerStyle.emailIcon} />
                <b
                  className={headerStyle.titleOfContacts}
                  style={{ fontWeight: "500" }}>
                  Email :
                </b>
                <span> {settingDetail?.email}</span>
                {settingDetail?.email ? <> || </> : null}
                <span> {settingDetail?.second_email}</span>
              </div>
            ) : null}
          </Marquee>
        </div>

        {/* End of addition by Jhamman on 14-09-2024
          Reason - Added mobile number and email in main header in mobile view*/}

        <div className={`${headerStyle.navbar} `}>
          {/* Modified by Jhamman on 05-09-2024
          Reason - to show activation of this nav Item */}
          <div
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}>
            <Link
              to="/"
              // className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}>
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
              ${activeLink == "/" && headerStyle.active}`}>
              {/* Home */}
              <IoHome style={{height:'20px', width:'20px'}}/>
            </Link>
          </div>
          {/* End of modification by Jhamman on 05-09-2024
          Reason - to show activation of this nav Item*/}

          <div
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${
              headerStyle.navbarFont
            }
            ${activeLink.startsWith("/infrastructure") && headerStyle.active}`}>
            Infrastructure
            {/* Modified by Jhamman on 05-09-2024
                  Reason- Added margin left */}
            {/* Added by - Jhamman lal sahu on 26-08-2024
            Reason - to add arrow icon */}
            {/* <MdArrowDropDown style={{ height: 20, width: 20 }} /> */}
            <MdArrowDropDown style={{ height: 20, width: 20, marginLeft: 8 }} />
            {/* End of addition by - Jhamman lal sahu on 26-08-2024
            Reason - to add arrow icon */}
            {/* End of modification by - Jhamman lal sahu on 05-09-2024
              Reason- Added margin left */}
            <div className={`${headerStyle.dropdown}`}>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/infrastructure/manufacturing"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Manufacturing
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/infrastructure/packaging"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Packaging
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/infrastructure/research-and-development"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  R&D
                </Link>
              </li>
              {/* Added by _ Jhamman Lal Sahu on 04-07-2024 
                  Reason - Add a new item Accredetation in the Infrastructure dropdown*/}

              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/infrastructure/accreditation"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Accreditation
                </Link>
              </li>

              {/* End of the addition by Jhamman Lal Sahu on 04-07-2024 
                  Reason - Add a new item Accredetation in the Infrastructure dropdown */}

              {/* Added by _ Jhamman Lal Sahu on 04-07-2024 
                  Reason - Add a new item Quality Control in the Infrastructure dropdown */}

              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/infrastructure/quality-control"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Quality Control
                </Link>
              </li>

              {/* End of the addition by Jhamman Lal Sahu on 04-07-2024 
                  Reason - Add a new item Quality Control in the Infrastructure dropdown */}
            </div>
          </div>

          {/* Added by - Ashlekh on 01-08-2024
          Reason - To add company profile option */}
          <div
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${
              headerStyle.navbarFont
            }
            ${
              activeLink.startsWith("/company_profile") && headerStyle.active
            }`}>
            Company Profile
            {/* Modified by Jhamman on 05-09-2024
                  Reason- Added margin left */}
            {/* Added by - Jhamman lal sahu on 26-08-2024
            Reason - to add arrow icon */}
            {/* <MdArrowDropDown style={{ height: 20, width: 20 }} /> */}
            <MdArrowDropDown style={{ height: 20, width: 20, marginLeft: 8 }} />
            {/* End of addition by - Jhamman lal sahu on 26-08-2024
            Reason - to add arrow icon */}
            {/* End of modification by Jhamman on 05-09-2024
                  Reason- Added margin left */}
            <div className={`${headerStyle.dropdown}`}>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/company_profile/about_us"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  About Us
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/company_profile/mission-vision"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Mission Vision
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/company_profile/business-value"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Business Value
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/company_profile/management"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Management
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/company_profile/why-us"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Why Us
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/company_profile/quality-policy"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Quality Policy
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/company_profile/client-satisfaction"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  {/* Client satisfaction */}
                  Client Satisfaction
                </Link>
              </li>
            </div>
          </div>
          {/* End of code - Ashlekh on 01-08-2024
          Reason - To add company profile option  */}

          {/* 
          Added by - Ashish Dewangan on 07-08-2024
          Reason - Added global presence meny to header links */}
          <div
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}>
            <Link
              to="/global-presence"
              // Modified by Jhamman on 04-09-2024
              // Reason - Because previously its not highlighted on header
              // className={`${headerStyle.link}
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
              ${activeLink == "/global-presence" && headerStyle.active}`}>
              Global Presence
            </Link>
            {/* Modified by Jhamman on 04-09-2024
            Reason - Because previously its not highlighted on header */}
          </div>
          {/* 
          End of code addition by - Ashish Dewangan on 07-08-2024
          Reason - Added global presence meny to header links */}

          {/* Added by - Jhamman lal sahu on 05-08-2024
            Reason - Add a new item Services in NavBar */}
          <div
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${
              headerStyle.navbarFont
            }
            ${activeLink.startsWith("/services") && headerStyle.active}`}>
            Services
            {/* Modified by Jhamman on 05-09-2024
                  Reason- Added margin left */}
            {/* Added by - Jhamman lal sahu on 26-08-2024
            Reason - to add arrow icon */}
            {/* <MdArrowDropDown style={{ height: 20, width: 20 }} /> */}
            <MdArrowDropDown style={{ height: 20, width: 20, marginLeft: 8 }} />
            {/* End of addition by - Jhamman lal sahu on 26-08-2024
            Reason - to add arrow icon */}
            {/* End of modification by Jhamman on 05-09-2024
                  Reason- Added margin left */}
            <div className={`${headerStyle.dropdown}`}>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/services/contract-manufacturing"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Contract Manufacturing
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/services/third-party-manufacturing"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  3rd Party Manufacturing
                </Link>
              </li>
              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/services/institutional-tenders"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Institutional Tenders
                </Link>
              </li>

              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/services/generic-medicines"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Generic Medicines
                </Link>
              </li>

              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/services/otc-products"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  OTC Products
                </Link>
              </li>

              {/* Added by _ Jhamman Lal Sahu on 07-08-2024 
                  Reason - Add a new item Regulatory Services in the Services dropdown */}

              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/services/regulatory-services"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  Regulatory Services
                </Link>
              </li>

              {/* End of the addition by Jhamman Lal Sahu on 07-08-2024 
                  Reason - Add a new item Regulatory Services in the Services dropdown */}

              {/* Added by _ Jhamman Lal Sahu on 07-08-2024 
                  Reason - Add a new item Quality Control Quality Assurance in the Services dropdown */}

              <li className={`${headerStyle.dropdownItem}`}>
                <Link
                  to="/services/quality-control-quality-assurance"
                  /*Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink*/
                  // className={`${headerStyle.link}`}>
                  className={`${headerStyle.subLink}`}>
                  {/* Modified by Jhamman on 04-09-2024
                  Reason- Changed css from link to subLink */}
                  QC&QA
                </Link>
              </li>

              {/* End of the addition by Jhamman Lal Sahu on 07-08-2024 
                  Reason - Add a new item Quality Control Quality Assurance in the Services dropdown */}

              {/* End of the addition by Jhamman Lal Sahu on 04-07-2024 
                  Reason - Add a new item Services in Navbar */}
            </div>
          </div>
          {/* Added by - Om Shrivastava on 03-08-2024
          Reason - To add Product option */}
          <div
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
            style={{ cursor: "pointer" }}>
            <Link
              // to="/product-details"
              to="all-products"
              // Modified by Jhamman on 04-09-2024
              // Reason - Because previously its not highlighted on header
              // className={`${headerStyle.link}
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
            ${activeLink == "/all-products" && headerStyle.active}`}>
              Products
            </Link>
            {/* Modified by Jhamman on 04-09-2024
            Reason - Because previously its not highlighted on header */}
          </div>
          {/* End of code - Om Shrivastava on 03-08-2024
          Reason - To add Product option  */}

          {/* 
                Added by -Jhamman lal sahu on 09-08-2024
                Reason - to add new item Events in navbar */}
          <div
            // Added by - Ashlekh on 23-08-2024
            // Reason - For styling of header
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
            style={{ cursor: "pointer" }}
            // End of code - Ashlekh on 23-08-2024
            // Reason - For styling of header
          >
            <Link
              to="/events"
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
              ${activeLink == "/events" && headerStyle.active}`}>
              Events
            </Link>
          </div>

          {/* 
                End of the addition by -Jhamman lal sahu on 09-08-2024
                Reason - to add new item Events in navbar */}
          {/* End of Addition by Jhamman lal Sahu on 28-07-2024
          Reason - Create a NavBar */}
          {/* Commented by - Ashish Dewangan on 05-08-2024
          Reason - Logout button is not required in this project */}
          {/* {user && user.email && (
            <div
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
              onClick={handleLogout}
              style={{ cursor: "pointer" }}
            >
              LOGOUT
            </div>
          )} */}
          {/* End of comment by - Ashish Dewangan on 05-08-2024
          Reason - Logout button is not required in this project */}

          {/* Added by - Ashlekh on 12-08-2024
            Reason - To have Blog & Gallery section */}
          <div
            // Added by - Ashlekh on 23-08-2024
            // Reason - For styling of header
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
            style={{ cursor: "pointer" }}
            // End of code - Ashlekh on 23-08-2024
            // Reason - For styling of header
          >
            <Link
              to="/gallery"
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
              ${activeLink == "/gallery" && headerStyle.active}`}>
              Gallery
            </Link>
          </div>
          <div
            // Added by - Ashlekh on 23-08-2024
            // Reason - For styling of header
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
            style={{ cursor: "pointer" }}
            // End of code - Ashlekh on 23-08-2024
            // Reason - For styling of header
          >
            <Link
              to="/blog"
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
              ${activeLink == "/blog" && headerStyle.active}`}>
              Blog
            </Link>
          </div>
          {/* End of code - Ashlekh on 12-08-2024
          Reason - To have Gallery & Blog section  */}
          {/* Added by - Ashlekh on 13-08-2024
          Reason - To have Career section */}
          <div
            // Added by - Ashlekh on 23-08-2024
            // Reason - For styling of header
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
            style={{ cursor: "pointer" }}
            // End of code - Ashlekh on 23-08-2024
            // Reason - For styling of header
          >
            <Link
              to="/career"
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
              ${activeLink == "/career" && headerStyle.active}`}>
              Career
            </Link>
          </div>
          {/* End of code - Ashlekh on 13-08-2024
          Reason - To have Career section */}
          {/* Added by - Ashlekh on 14-08-2024
          Reason - To have Texfer section */}
          <div
            // Added by - Ashlekh on 23-08-2024
            // Reason - For styling of header
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
            style={{ cursor: "pointer" }}
            // End of code - Ashlekh on 23-08-2024
            // Reason - For styling of header
          >
            <Link
              to="/texfer"
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              }
              ${activeLink == "/texfer" && headerStyle.active}`}>
              Texfer
            </Link>
          </div>
          {/* End of code - Ashlekh on 14-08-2024
          Reason - To have Texfer section */}
          {/* Added by - Ashlekh on 17-08-2024
          Reason - To have contact us section */}
          <div
            // Added by - Ashlekh on 23-08-2024
            // Reason - For styling of header
            className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
            style={{ cursor: "pointer" }}
            // End of code - Ashlekh on 23-08-2024
            // Reason - For styling of header
          >
            <Link
              to="/contact"
              className={`${headerStyle.navbarItem} ${headerStyle.link} ${
                headerStyle.navbarFont
              } ${activeLink == "/contact" && headerStyle.active}`}>
              Contact Us
            </Link>
          </div>
          {/* End of code - Ashlekh on 17-08-2024
          Reason - To have contact us section */}

          {/*
           * Added by - Ashish Dewangan on 23-08-2024
           * Reason - Added download brochure button
           */}
          {settingDetail.brochure &&
            settingDetail.brochure?.trim()?.length > 0 && (
              <div
                // className={`${headerStyle.downloadBrochure}`}
                className={`${headerStyle.downloadBrochure}`}>
                <Link
                  // Commented by - Ashlekh on 27-08-2024
                  // Reason - Drawer opens in desktop view
                  // onClick={closeDrawer}
                  // End of comment - Ashlekh on 27-08-2024
                  // Reason - Drawer opens in desktop view
                  className={`${headerStyle.downloadBrochureButton}`}
                  // className={`${headerStyle.navbarItem} ${headerStyle.link} ${headerStyle.navbarFont}`}
                  // className={`${headerStyle.link}`}
                  to={`${config.baseURL}${settingDetail?.brochure}`}
                  target="_blank"
                  // Commented by - Jhamman on 06-09-2024
                  // Reason - define it externally
                  // style={{
                  //   display: "flex",
                  //   alignItems: "center",
                  //   alignContent: "baseline",
                  //   lineHeight: "0px",
                  // }}
                  // End of commentation by - Jhamman on 06-09-2024
                  // Reason - define it externally
                >
                  <div className={`${headerStyle.downloadBroucherText}`}>
                    Download Brochure
                  </div>

                  {/* Modified by Jhamman on 27-09-2024
                  Reason - Added classname*/}
                  {/* <MdDownload style={{ fontSize: "25px" }} /> */}
                  <MdDownload className={`${headerStyle.downloadBroucherIcon}`} style={{ fontSize: "25px" }} />
                   {/* End of modification by Jhamman on 27-09-2024
                  Reason - Added classname*/}
                </Link>
              </div>
            )}

          {/*
           * End of addition by - Ashish Dewangan on 23-08-2024
           * Reason - Added download brochure button
           */}
        </div>
      </div>
    </div>
  );
};

export default Header;
