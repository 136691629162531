/**
 * Created by - Ashlekh on 12-08-2024
 * Reason - To have gallery section
 */
import { useContext, useEffect, useState } from "react";
import style from "./gallery.module.css";
import { getGalleryDetailsApi } from "../../Api/services";
import config from "../../Api/config";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const Gallery = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [galleryImage, setGalleryImage] = useState([]);
  const [galleryVideo, setGalleryVideo] = useState([]);
  const [showContent, setShowContent] = useState(false);

  // Added by - Ashlekh on 12-08-2024
  // Reason - This useEffect will executes when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    galleryDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Gallery", path: "/gallery" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 12-08-2024
  // Reason - This useEffect will executes when page loads

  // Added by - Ashlekh on 12-08-2024
  // Reason - To get details from backend
  const galleryDetails = async () => {
    try {
      const response = await getGalleryDetailsApi();
      setGalleryImage(response?.gallery_image);
      setGalleryVideo(response?.gallery_video);
    } catch (error) {
      console.error("Error fetching gallery details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 12-08-2024
  // Reason - To get details from backend

  // Added by - Ashlekh on 12-08-2024
  // Reason - To handle carousel in different breakpoints
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1201 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 901 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet2: {
      breakpoint: { max: 900, min: 721 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 720, min: 601 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile2: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  // End of code - Ashlekh on 12-08-2024
  // Reason - To handle Carousel in different breakpoints
  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition for No details available and navigation path */}
          {/* {galleryImage?.length > 0 || galleryVideo?.length > 0 ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available and navigation path */}
          <div className={`${style.mainContainer}`}>
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 04-09-2024
        Reason - To add page title */}
            {/* Code changed by - Ashlekh on 07-09-2024
        Reason - To add No details available text */}
            {/* <div className={`${style.pageTitle}`}>Gallery</div> */}
            {galleryImage?.length > 0 || galleryVideo?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={`${style.pageTitle}`}>Gallery</div>*/
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Ga</span>
                  <span className={style.title2}>llery</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
        Reason - To add No details available text */}
            {/* End of code - Ashlekh on 04-09-2024
        Reason - To add page title */}
            {galleryImage?.length > 0 ? (
              <div className={`${style.imageContainer}`}>
                {galleryImage?.map((item, index) => (
                  <div key={index} className={`${style.imageSubContainer}`}>
                    <img
                      className={`${style.image}`}
                      src={config.baseURL + item?.image}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            ) : null}
            {galleryVideo?.length > 0 ? (
              <div className={`${style.videoContainer}`}>
                <Carousel responsive={responsive}>
                  {galleryVideo?.map((item, index) => {
                    return (
                      <div key={index}>
                        <ReactPlayer
                          className={`${style.video}`}
                          url={item?.video}
                          controls={true}
                          width={290}
                          height={175}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            ) : null}
          </div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition for No details available and navigation path */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available and navigation path */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};
export default Gallery;
