//Added by - Jhamman lal sahu on 05-08-2024
//Reason - To render UI of OTCProducts.jsx

import styles from "./OTCProducts.module.css";
import { otcProducts } from "../../../Api/services";
import { useState, useEffect, useContext } from "react";
import parse from "html-react-parser";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const OTCProducts = () => {
  const [otcProductsDetails, setOtcProductsDetails] = useState({});
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  //Added by - jhamman lal sahu on 05-08-2024
  //Reason - To get details of otc products details
  const fetchOTCProductsDetails = async () => {
    try {
      const response = await otcProducts();
      setOtcProductsDetails(response.otcProducts_details);
    } catch (error) {
      console.log("Error fetching otc products details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },

      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Services", path: "/Services/otc-products" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path

      {
        name: "OTC Products",
        path: "/Services/otc-products",
      },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchOTCProductsDetails();
  }, []);

  // End of the addition by Jhamman lal sahu on 05-08-2025
  // Reason - To get details of fetch fetch OTC products details

  //commented by - Jhamman lal sahu on 31-08-2024
  //Reason - conditionally handled this below

  //Modified by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  // if (!otcProductsDetails) return <p>No details available</p>;
  // if (!otcProductsDetails)
  //   return (
  //     <div className={styles.noDetails}>
  //       <p className={styles.noDetailText}>No details available</p>
  //     </div>
  //   );
  //End of the modification by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  //End of commentation by - Jhamman lal sahu on 31-08-2024
  //Reason - conditionally handled this below

  return (
    <>
      {showContent ? (
        // Added by Jhamman on 31-08-2024
        //Reason - conditional rendering
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        otcProductsDetails?.title_and_description ||
        otcProductsDetails?.title_and_description?.trim()?.length > 0 ? ( */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* // End of addition by Jhamman on 31-08-2024
          //Reason - conditional rendering */}

          <div className={styles.mainContainer}>
            {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* Added by - Jhamman lal sahu on 22-08-2024
          Reason - To add path in UI */}
            <div className={styles.subContainer}>
              {/* Modified by Jhamman on 06-09-2024
        Reason - print page title based on condition*/}
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* <div className={styles.pageTitle}>OTC products</div>{" "} */}
              {otcProductsDetails?.title_and_description ||
              otcProductsDetails?.title_and_description?.trim()?.length > 0 ? (
                /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                /*<div className={styles.pageTitle}>OTC Products</div>*/
                <div className={styles.pageTitle}>
                  <img
                    className={styles.headingImage}
                    src="headingdesign.png"
                  />
                  <div className={styles.headingTitle}>
                    <span className={styles.title1}>OT</span>
                    <span className={styles.title2}>C Products</span>
                  </div>
                </div>
              ) : (
                /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                <div className={styles.noDetails}>
                  <div className={styles.noDetailText}>
                    No details available
                  </div>
                </div>
              )}
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* Modified by Jhamman on 06-09-2024
        Reason - print page title based on condition*/}

              {otcProductsDetails?.title_and_description ? (
                <div className={styles.titleAndDescription}>
                  {parse(otcProductsDetails?.title_and_description)}
                </div>
              ) : null}
            </div>
          </div>

          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* ) : ( // Added by Jhamman on 31-08-2024 //Reason - No details available
      <div className={styles.noDetails}>
        <p className={styles.noDetailText}>No details available</p>
      </div>
      ) // End of the addition by Jhamman on 31-08-2024 //Reason - No details
      available } */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
          reason - Show nothing while loading the data*/
        <div className={styles.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/}
    </>
  );
};

export default OTCProducts;

//End of the addition by Jhamman lal sahu on 05-08-2024
//Reason - To render UI of GenericMedicines.jsx
