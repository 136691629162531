// Added by - Jhamman Lal Sahu on 04-08-2024
// Reason - UI to render Quality Control details

import React, { useEffect, useState, useContext } from "react";
import { qualityControl } from "../../../Api/services";
import qualityControlStyle from "./QualityControl.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../../Api/config";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const QualityControl = () => {
  const [qualityControlDetails, setqualityControlDetails] = useState(null);
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  // Added by - Jhamman Lal Sahu on 04-08-2024
  // Reason - Function Component to get Quality Control data
  const fetchQualityControlData = async () => {
    try {
      const response = await qualityControl();
      setqualityControlDetails(response.qualityControl_details);
    } catch (error) {
      console.error("Error fetching quality Control details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 18-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 18-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Infrastructure", path: "/infrastructure/quality-control" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path

      { name: "Quality Control", path: "/infrastructure/quality-control" },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchQualityControlData();
  }, []);

  //Commented by Jhamman on 30-08-2024
  //Reason - handle it below, with condition.
  //Modified by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  // if (!qualityControlDetails) return <p>No details available</p>;
  // if (!qualityControlDetails)
  //   return (
  //     <div className={qualityControlStyle.noDetails}>
  //       <p className={qualityControlStyle.noDetailText}>No details available</p>
  //     </div>
  //   );
  //End of the modification by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  return (
    <>
      {showContent ? (
        // Added by Jhamman on 30-08-2024
        //Reason - conditional rendering
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        qualityControlDetails?.banner_video_file != null ||
        qualityControlDetails?.title_and_description ||
        qualityControlDetails?.title_and_description?.trim()?.length > 0 ||
        qualityControlDetails?.our_commitment ||
        qualityControlDetails?.our_commitment?.trim()?.length > 0 ||
        qualityControlDetails?.quality_image != null ||
        qualityControlDetails?.page_content ||
        qualityControlDetails?.page_content?.trim()?.length > 0 ? (
          // End of addition by Jhamman on 30-08-2024
          //Reason - conditional rendering */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}

          <div className={qualityControlStyle.mainContainer}>
            {/* Modified by - Jhamman lal sahu on 22-08-2024
    Reason - Given an alternative option for banner, it can be image to */}

            {qualityControlDetails?.banner_video_file ? (
              /\.(mp4|mkv|webm|avi|mov|flv|wmv)$/i.test(
                qualityControlDetails.banner_video_file
              ) ? (
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_video_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <video
                  autoPlay
                  muted
                  loop
                  className={qualityControlStyle.banner_video}>
                  <source
                    src={`${baseURL}${qualityControlDetails.banner_video_file}`}
                    type={`video/${qualityControlDetails.banner_video_file
                      .split(".")
                      .pop()}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                /* </div> */
                /* Commented by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                /* <div className={Style.banner_image_container}> */
                /* End of commentation by Jhamman on 20-09-2024
                Reason - to reduce gap*/
                <img
                  src={`${baseURL}${qualityControlDetails.banner_video_file}`}
                  alt="Banner"
                  className={qualityControlStyle.banner_image}
                />
                /* </div> */
              )
            ) : null}

            {/* End of the modification by - Jhamman lal sahu on 22-08-2024
    Reason - Given an alternative option for banner, it can be image to */}

            {/* Added by - Jhamman lal sahu on 22-08-2024
        Reason - To add path in UI */}

            <NavigationPath navigationPathArray={navigationPath} />

            {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}

            <div className={qualityControlStyle.subContainer}>
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {
                qualityControlDetails?.title_and_description ||
                qualityControlDetails?.title_and_description?.trim()?.length >
                  0 ||
                qualityControlDetails?.our_commitment ||
                qualityControlDetails?.our_commitment?.trim()?.length > 0 ||
                qualityControlDetails?.quality_image != null ||
                qualityControlDetails?.page_content ||
                qualityControlDetails?.page_content?.trim()?.length > 0 ? (
                  /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                  /* <div className={Style.pageTitle}>Quality Control</div> */
                  <div className={qualityControlStyle.pageTitle}>
                    <img
                      className={qualityControlStyle.headingImage}
                      src="headingdesign.png"
                    />
                    <div className={qualityControlStyle.headingTitle}>
                      <span className={qualityControlStyle.title1}>Qu</span>
                      <span className={qualityControlStyle.title2}>
                        ality Control
                      </span>
                    </div>
                  </div>
                ) : (
                  /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                  /*Modified by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
                  /* null */
                  <div className={qualityControlStyle.noDetails}>
                    <p className={qualityControlStyle.noDetailText}>
                      No details available
                    </p>
                  </div>
                )
                /* End of modification by Jhamman on 06-09-2024
                Reason - Added no details available instead of null */
              }
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              <div
                className={
                  qualityControlStyle.titleDescriptionAndCommitmentBothSectionContainer
                }>
                {qualityControlDetails?.our_commitment ? (
                  <div className={qualityControlStyle.ourCommitment}>
                    {parse(qualityControlDetails.our_commitment)}
                  </div>
                ) : null}

                {qualityControlDetails?.title_and_description ? (
                  <div className={qualityControlStyle.titleAndDescription}>
                    {parse(qualityControlDetails.title_and_description)}
                  </div>
                ) : null}
              </div>

              {/* Added by Jhamman on 30-08-2024
          reason - to add images in quality control section */}
              {qualityControlDetails?.quality_image ? (
                <div className={qualityControlStyle.quality_image_container}>
                  <img
                    src={`${baseURL}${qualityControlDetails.quality_image}`}
                    className={qualityControlStyle.quality_image}
                  />
                </div>
              ) : null}
              {/* Added by Jhamman on 30-08-2024
          reason - to add images in quality control section */}

              {qualityControlDetails?.page_content ? (
                <div className={qualityControlStyle.pageContent}>
                  {parse(qualityControlDetails.page_content)}
                </div>
              ) : null}
            </div>
          </div>
          {/* Commented by Jhamman on 06-09-2024
        Reason - We have to print navigation path wheather content have or not */}
          {/* ) : (
          // Added by Jhamman on 30-08-2024
          //Reason - No details available
          <div className={qualityControlStyle.noDetails}>
            <p className={qualityControlStyle.noDetailText}>
              No details available
            </p>
          </div>
        )
        // End of the addition by Jhamman on 30-08-2024
        /* Reason - No details available */}
          {/* End of commentation by Jhamman on 06-09-2024
        Reason - We have to print navigation path wheather content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={qualityControlStyle.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};

export default QualityControl;

// End of the addition by Jhamman Lal Sahu on 26-07-2024
// Reason - UI to render manufacturing details
