// Created by Om Shrivastava on 10-08-2024
import React, { useEffect, useRef } from "react";
import styles from "./ItemDetailPopup.module.css";

const ItemDetailPopup = ({ item, onClose }) => {
  const popupRef = useRef(null);
  const {
    name,
    brand,
    category_name,
    item_type,
    merged_name,
    storage_condition,
    therapeutic_use,
    description,
    usage,
    item_strength,
    dosage_form,
    packing,
    trade_Name,
  } = item;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Added by - Ashlekh on 10-09-2024
    // Reason - Handle the back button functionality by closing the popup when the state changes (when the user presses the back button of browser)
    const handlePopState = () => {
      onClose();
    };
    // End of code - Ashlekh on 10-09-2024
    // Reason - Handle the back button functionality by closing the popup when the state changes (when the user presses the back button of browser)

    window.history.pushState({ isPopupOpen: true }, "");

    document.addEventListener("mousedown", handleClickOutside);
    // Added by - Ashlekh on 10-09-2024
    // Reason - This will check the changes of browser history (when we click back from browser this will approriately close the popup)
    window.addEventListener("popstate", handlePopState);
    // End of code - Ashlekh on 10-09-2024
    // Reason - This will check the changes of browser history (when we click back from browser this will approriately close the popup)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      // Added by - Ashlekh on 10-09-2024
      // Reason - To close popup and to take user to previous state (when we click back from browser)
      window.removeEventListener("popstate", handlePopState);

      if (window.history.state && window.history.state.isPopupOpen) {
        window.history.back();
      }
      // End of code - Ashlekh on 10-09-2024
      // Reason - To close popup and to take user to previous state (when we click back from browser)
    };
  }, [onClose]);

  if (!item) return null;
  console.log(item, "check data");

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent} ref={popupRef}>
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
        {name && <h2 className={styles.itemName}>{name}</h2>}

        <div className={styles.itemDetails}>
          {brand && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Brand</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{brand}</span>
            </div>
          )}
          {category_name && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Category Name</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{category_name}</span>
            </div>
          )}
          {item_type && (
            <div className={styles.detailRow}>
              {/* Modified by - Ashish Dewangan on 23-08-2024
              Reason - Renamed label */}
              {/* <span className={styles.label}>Item Type</span> */}
              <span className={styles.label}>Product Type</span>
              {/* End of modification by - Ashish Dewangan on 23-08-2024
              Reason - Renamed label */}
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{item_type}</span>
            </div>
          )}
          {/* {merged_name && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Merged Name</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{merged_name}</span>
            </div>
          )} */}
          {storage_condition && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Storage Condition</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{storage_condition}</span>
            </div>
          )}
          {/* 
          Added by - Ashish Dewangan on 23-08-2024
          Reason - To show trade name */}
          {trade_Name && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Trade Name</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{trade_Name}</span>
            </div>
          )}
          {/* 
          End of code addition by - Ashish Dewangan on 23-08-2024
          Reason - To show trade name */}
          {therapeutic_use && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Therapeutic Use</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{therapeutic_use}</span>
            </div>
          )}
          {description && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Description</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{description}</span>
            </div>
          )}
          {usage && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Usage</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{usage}</span>
            </div>
          )}
          {item_strength && (
            <div className={styles.detailRow}>
              {/* Modified by - Ashish Dewangan on 23-08-2024
              Reason - Renamed label */}
              {/* <span className={styles.label}>Item Strength</span> */}
              <span className={styles.label}>Product Strength</span>
              {/* End of modification by - Ashish Dewangan on 23-08-2024
              Reason - Renamed label */}
              <span className={styles.colon}>:</span>

              <span className={styles.value}>{item_strength}</span>
            </div>
          )}
          {dosage_form && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Dosage Form</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{dosage_form}</span>
            </div>
          )}
          {packing && (
            <div className={styles.detailRow}>
              <span className={styles.label}>Packing</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{packing}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemDetailPopup;
