/**
 * Created by - Ashish Dewangan on 08-07-2024
 * Reason - Created a page where content will change when we change the route
 */

import basePageStyle from "./BasePage.module.css";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import Manufacturing from "../Infrastructure/Manufacturing/Manufacturing";
import Packaging from "../Infrastructure/Packaging/Packaging";
import ResearchAndDevelopment from "../Infrastructure/R&D/ResearchAndDevelopment";
import AboutUs from "../CompanyProfile/AboutUs/AboutUs";
import Accreditation from "../Infrastructure/Accreditation/Accreditation";
import QualityControl from "../Infrastructure/QualityControl/QualityControl";
import ContractManufacturing from "../Services/ContractManufacturing/ContractManufacturing";
import ThirdPartyManufacturing from "../Services/ThirdPartyManufacturing/ThirdPartyManufacturing";
import InstitutionalTenders from "../Services/InstitutionalTenders/InstitutionalTenders";
import GenericMedicines from "../Services/GenericMedicines/GenericMedicines";
import OTCProducts from "../Services/OTCProducts/OTCProducts";
import GlobalPresence from "../GlobalPresence/GlobalPresence";
import ProductDetail from "../ProductDetails/ProductDetail";
import SearchProductDetail from "../ProductDetails/SearchProductDetail";
import BusinessValue from "../CompanyProfile/BusinessValue/BusinessValue";
import Management from "../CompanyProfile/Management/Management";
import RegulatoryServices from "../Services/RegulatoryServices/RegulatoryServices";
import QualityControlQualityAssurance from "../Services/QualityControlQualityAssurance/QualityControlQualityAssurance";
import Events from "../Events/Events";
import MissionVision from "../CompanyProfile/MissionVision/MissionVision";
import QualityPolicy from "../CompanyProfile/QualityPolicy/QualityPolicy";
import WhyUs from "../CompanyProfile/WhyUs/WhyUs";
import ClientSatisfaction from "../CompanyProfile/ClientSatisfaction/ClientSatisfaction";
import RequestCallback from "../RequestCallback/RequestCallback";
import Blog from "../Blog/Blog";
import BlogDetailed from "../Blog/BlogDetailed";
import Gallery from "../Gallery/Gallery";
import Career from "../Career/Career";
import Texfer from "../Texfer/Texfer";
import ContactUs from "../ContactUs/ContactUs";
import Certificates from "../Certificates/Certificates";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import RequestCallbackButton from "../../components/Buttons/RequestCallbackButton/RequestCallbackButton";
import NewProductsDetails from "../ProductDetails/NewProductsDetails";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import PopupEvent from "../Events/PopupEvent";
import { useEffect } from "react";

const BasePage = () => {
  /* Added by Jhamman on 27-09-2024
  Reason - remove pageFrame background from homepage*/
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);
  /* End of addition by Jhamman on 27-09-2024
  Reason - remove pageFrame background from homepage*/

  return (
    <div
      className={`${basePageStyle.pageFrame}`}
      /* Added by Jhamman on 27-09-2024
      Reason - remove pageFrame background from homepage*/
      style={currentPath.endsWith("/") ? { backgroundImage: "none" } : {}}>
      {/* End of addition by Jhamman on 27-09-2024
       Reason - remove pageFrame background from homepage */}
      {/* <div className={`${basePageStyle.coloredBackground}`}> */}
      <div className={`${basePageStyle.pageContainer}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Commented by - Ashish Dewangan on 05-08-2024
          Reason - Login/Registration is not required in this project */}
          {/* <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} /> */}
          {/* End of comment by - Ashish Dewangan on 05-08-2024
          Reason - Login/Registration is not required in this project */}
          <Route
            path="/infrastructure/manufacturing"
            element={<Manufacturing />}
          />
          <Route path="/infrastructure/packaging" element={<Packaging />} />
          <Route
            path="/infrastructure/research-and-development"
            element={<ResearchAndDevelopment />}
          />
          {/* Added by - Ashlekh on 01-08-2024
          Reason - To have about us section from company profile */}
          <Route path="/company_profile/about_us" element={<AboutUs />} />
          {/* End of code - Ashlekh on 01-08-2024
          Reason - To have about us section from company profile */}
          <Route
            path="/infrastructure/accreditation"
            element={<Accreditation />}
          />

          {/* Added by _ Jhamman Lal Sahu on 04-08-2024 
         Reason - Add a path for quality control */}

          <Route
            path="/infrastructure/quality-control"
            element={<QualityControl />}
          />

          {/* End of the addition by - Jhamman Lal Sahu on 04-08-2024 
         Reason - Add a path for quality control */}

          {/* Added by _ Jhamman Lal Sahu on 04-08-2024 
         Reason - Add a path for contract manufacturing */}

          <Route
            path="/services/contract-manufacturing"
            element={<ContractManufacturing />}
          />

          {/* End of the addition by - Jhamman Lal Sahu on 04-08-2024 
         Reason - Add a path for contract manufacturing */}

          {/* Added by _ Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for third party manufacturing */}

          <Route
            path="/services/third-party-manufacturing"
            element={<ThirdPartyManufacturing />}
          />

          {/* End of the addition by - Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for third party manufacturing  */}

          {/* Added by _ Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for institutional tenders */}

          <Route
            path="/services/institutional-tenders"
            element={<InstitutionalTenders />}
          />

          {/* End of the addition by - Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for institutional tenders  */}

          {/* Added by _ Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for Generic Medicines */}

          <Route
            path="/services/generic-medicines"
            element={<GenericMedicines />}
          />

          {/* End of the addition by - Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for Generic Medicines  */}

          {/* Added by _ Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for otc products */}

          <Route path="/services/otc-products" element={<OTCProducts />} />

          {/* End of the addition by - Jhamman Lal Sahu on 05-08-2024 
         Reason - Add a path for otc products  */}

          {/* Added by - Ashish Dewangan on 07-08-2024 
         Reason - Added route for global presence page  */}

          <Route path="/global-presence" element={<GlobalPresence />} />

          {/* End of code addition by - Ashish Dewangan on 07-08-2024 
         Reason - Added route for global presence page  */}

          {/* Commented by Jhamman on 21-09-2024
            Reason - as per client requirment*/}
          {/* Addition by Om Shrivastava on 02-08-2024
          Reason : Create a Product details page  */}
          {/* <Route path="/product-details" element={<ProductDetail />} /> */}
          {/* End of addition by Om Shrivastava on 02-08-2024
          Reason : Create a Product details page */}
          {/* End of addition by Jhamman on 21-09-2024
            Reason - as per client requirment*/}

          {/* Addition by Jhamman on 21-09-2024
          Reason : Create a Product details page  */}
          <Route path="/all-products" element={<NewProductsDetails />} />
          {/* End of addition by Jhamman on 21-09-2024
          Reason : Create a Product details page  */}

          {/* Addition by Om Shrivastava on 03-08-2024
          Reason : Create a Search Product details page  */}
          <Route
            path="/search-product-details"
            element={<SearchProductDetail />}
          />
          {/* End of addition by Om Shrivastava on 02-08-2024
          Reason : Create a Search Product details page */}

          {/* Added by - Ashlekh on 08-08-2024
          Reason - To have business section  and manaagement section from company profile */}
          <Route
            path="/company_profile/business-value"
            element={<BusinessValue />}
          />
          <Route path="/company_profile/management" element={<Management />} />
          {/* End of code - Ashlekh on 08-08-2024
          Reason - To have business section and management section  from company profile */}

          {/* Added by _ Jhamman Lal Sahu on 07-08-2024 
         Reason - Add a path for Regulatory Services */}

          <Route
            path="/services/regulatory-services"
            element={<RegulatoryServices />}
          />

          {/* End of the addition by - Jhamman Lal Sahu on 07-08-2024 
         Reason - Add a path for Regulatory Services  */}

          {/* Added by _ Jhamman Lal Sahu on 07-08-2024 
         Reason - Add a path for Quality Control Quality Assurance */}

          <Route
            path="/services/quality-control-quality-assurance"
            element={<QualityControlQualityAssurance />}
          />

          {/* End of the addition by - Jhamman Lal Sahu on 07-08-2024 
         Reason - Add a path for Quality Control Quality Assurance  */}

          {/* Added by _ Jhamman Lal Sahu on 08-08-2024 
         Reason - Add a path for events */}

          <Route path="/events" element={<Events />} />

          {/* End of the addition by - Jhamman Lal Sahu on 08-08-2024 
         Reason - Add a path for Events  */}

          {/* Added by - Ashlekh on 09-08-2024
          Reason - To have MissionVision section and Quality Policy from company profile */}
          <Route
            path="/company_profile/mission-vision"
            element={<MissionVision />}
          />
          <Route
            path="/company_profile/quality-policy"
            element={<QualityPolicy />}
          />
          {/* End of code - Ashlekh on 09-08-2024
          Reason - To have MissionVision section and Quality Policy from company profile */}

          {/* Added by - Ashlekh on 10-08-2024
          Reason - To have WhyUs section from Company profile */}
          <Route path="/company_profile/why-us" element={<WhyUs />} />
          {/* End of code - Ashlekh on 10-08-2024
          Reason - To have WhyUs section from Company profile */}

          {/* Added by - Ashlekh on 11-08-2024
          Reason - To have ClientSatisfaction section from Company profile */}
          <Route
            path="/company_profile/client-satisfaction"
            element={<ClientSatisfaction />}
          />
          {/* End of code - Ashlekh on 11-08-2024
          Reason - To have ClientSatisfaction section from Company profile */}

          {/* Added by - Ashlekh on 12-08-2024
          Reason - To have Blog */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog_detailed" element={<BlogDetailed />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* End of code - Ashlekh on 12-08-2024
          Reason - To have Blog */}

          {/* Added by - Ashlekh on 13-08-2024
          Reason - To import Career section */}
          <Route path="/career" element={<Career />} />
          {/* End of code - Ashlekh on 13-08-2024
          Reason - To import Career section */}

          {/* Added by - Ashlekh on 14-08-2024
          Reason - To import Texfer section */}
          <Route path="/texfer" element={<Texfer />} />
          {/* End of code - Ashlekh on 14-08-2024
          Reason - To import Texfer section */}

          {/* Added by - Ashlekh on 16-08-2024
          Reason - To import Contact Us page */}
          <Route path="/contact" element={<ContactUs />} />
          {/* End of code - Ashlekh on 16-08-2024
          Reason - To import Contact Us page */}

          {/* Added by - Ashish Dewangan on 17-08-2024
          Reason - Added route for certification page */}
          <Route path="/certificates" element={<Certificates />} />
          {/* End of code - Ashish Dewangan on 17-08-2024
          Reason - Added route for certification page */}

          {/* Added by - Jhamman lal sahu on 16-08-2024
          Reason - Url for request callback */}
          <Route path="/request-callback" element={<RequestCallback />} />
          {/* End of the addition by Jhamman lal sahu on 16-08-2024
          Reason - Url for request callback */}

          {/* Added by - Jhamman lal sahu on 17-08-2024
          Reason - Url for privacy policy */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* End of the addition by Jhamman lal sahu on 17-08-2024
          Reason - Url for privacy policy */}

          {/* Added by - Jhamman lal sahu on 17-08-2024
          Reason - Url for Terms and conditions */}
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          {/* End of the addition by Jhamman lal sahu on 17-08-2024
          Reason - Url for terms and conditions */}

          {/* Added by - Jhamman lal sahu on 23-09-2024
          Reason - added link for popupEvent*/}
          <Route path="/event" element={<PopupEvent />} />
          {/* End of the addition by Jhamman lal sahu on 17-08-2024
          Reason - added link for popupEvent*/}
        </Routes>
      </div>

      {/* Added by - jhamman lal sahu on 01-09-2024
      Reason - to add Request callback popup */}
      <RequestCallback />
      {/* End of the addition by - jhamman lal sahu on 01-09-2024
      Reason - to add Request callback popup*/}
      {/* Commented by - jhamman lal sahu on 02-09-2024
      Reason - Button add on RequestCallback.jsx*/}
      {/* Added by - jhamman lal sahu on 18-08-2024
      Reason - to add Request callback button*/}
      {/* <RequestCallbackButton /> */}
      {/* End of the addition by - jhamman lal sahu on 18-08-2024
      Reason - to add Request callback button*/}
      {/* End of commentation by - jhamman lal sahu on 02-09-2024
      Reason - Button add on RequestCallback.jsx*/}

      {/* Added by - jhamman lal sahu on 22-09-2024
      Reason - to add social icons*/}
      <SocialIcons />
      {/* End of addition by - jhamman lal sahu on 22-09-2024
      Reason - to add social icons*/}
    </div>
  );
};

export default BasePage;
