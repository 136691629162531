// Created by Om Shrivastava on 31-07-24
import React, { useEffect, useRef, useState } from "react";
import topHeaderStyle from "./topHeader.module.css";
import { IoSearch } from "react-icons/io5";
import {
  getSettingDetailsApi,
  getSocialIconsApi,
  postSearchDataApi,
} from "../../Api/services";
import config, { baseURL } from "../../Api/config";
import { Link, useNavigate } from "react-router-dom";
import { FaMobileAlt } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

export default function TopHeader() {
  // Addition by Om Shrivastava on 31-07-2024
  // Reason : Set setting details and social icon detail
  const [settingDetail, setSettingDetails] = useState({});
  const [socialIconData, setSocialIconData] = useState({});

  // Addition by Om Shrivastava on 02-08-2024
  // Reason : Set search details, search value error message
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const searchContainerRef = useRef(null);
  // End of addition by Om Shrivastava on 02-08-2024
  // Reason : Set search details, search value error message

  // End of addition by Om Shrivastava on 31-07-2024
  // Reason : Set setting details and social icon detail

  const navigate = useNavigate();

  // Addition by Om Shrivastava on 31-07-2024
  // Reason : When page is load, then this method is called
  useEffect(() => {
    fetchSettingData();
    fetchSocialIconData();
  }, []);
  // End of addition by Om Shrivastava on 31-07-2024
  // Reason : When page is load, then this method is called

  // Addition by Om Shrivastava on 31-07-2024
  // Reason : API calling of setting details
  const fetchSettingData = async () => {
    try {
      const response = await getSettingDetailsApi();
      setSettingDetails(response.setting_details);
    } catch (error) {
      console.error("Error fetching homepage details:", error);
    }
  };
  // End of addition by Om Shrivastava on 31-07-2024
  // Reason : API calling of setting details

  // Addition by Om Shrivastava on 31-07-2024
  // Reason : API calling of social icons details

  const fetchSocialIconData = async () => {
    try {
      const response = await getSocialIconsApi();
      setSocialIconData(response.social_icon_details);
    } catch (error) {
      console.error("Error fetching homepage details:", error);
    }
  };
  // End of addition by Om Shrivastava on 31-07-2024
  // Reason : API calling of social icons details

  // Addition by Om Shrivastava on 02-08-2024
  // When user change in input fields then show the search results
  const handleInputChange = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setErrorMessage(""); // Clear error message when typing
    if (term === "") {
      setSearchResults([]);
    }
    // Commented by Om Shrivastava on 03-08-2024
    // When we need to apply search in onchange then below method is work
    // else {
    //   const response = await postSearchDataApi({ search: term });
    //   setSearchResults(response);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchTerm === "") {
      setErrorMessage("Please enter a search term.");
      setSearchResults([]);
    } else {
      const response = await postSearchDataApi({ search: searchTerm });
      // setSearchResults(response);
      // setErrorMessage("");
      if (response.length === 0) {
        setErrorMessage("Search result not found.");
        setSearchResults([]);
      } else {
        setSearchResults(response);
        setErrorMessage("");
      }
    }
  };
  // End of addition by Om Shrivastava on 02-08-2024
  // When user change in input fields then show the search results

  // Addition by Om Shrivastava on 02-08-2024
  // When user click outside the search box then close the div and also clear the search results
  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setSearchResults([]);
      setSearchTerm("");
      setErrorMessage(""); // Clear error message on outside click
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // End of addition by Om Shrivastava on 02-08-2024
  // When user click outside the search box then close the div and also clear the search results

  const openProductDetails = (data) => {
    navigate("/search-product-details", { state: { searchData: data } });
  };

  return (
    <div className={`${topHeaderStyle.parentDiv} `}>
      {/* Commented by - Ashish Dewangan on 25-08-2024. 
      Reason - To remove unnecessary div*/}
      {/* <div> */}
      <div className={`${topHeaderStyle.logoContainer} `}>
        {/* Commented by - Ashish Dewangan on 25-08-2024. 
      Reason - To remove unnecessary div*/}
        {/* <div> */}
        {settingDetail?.logo?.length > 0 ? (
          <Link to="">
            <img
              src={config.baseURL + settingDetail?.logo}
              alt=""
              className={`${topHeaderStyle.logo}`}
            />
          </Link>
        ) : (
          ""
        )}
        {/* Commented by - Ashish Dewangan on 25-08-2024. 
      Reason - To remove unnecessary div*/}
        {/* </div> */}
        {/* </div> */}
      </div>
      <div className={topHeaderStyle.contactSocialContainer}>
        <div className={topHeaderStyle.contactContainer}>
          {/* Modified by Jhamman on 19-09-2024
        Reason - interchanged the position of both div */}
          {settingDetail?.business_contact_number ? (
            /* Modified by Jhamman on 23-09-2024
            Reason - given classname to div*/
            /* <div> */
            <div className={topHeaderStyle.contactField1}>
              {/* Modified by Jhamman on 23-09-2024
            Reason - given classname to div*/}
              {/* <div className={topHeaderStyle.headingField}> */}
                <IoCall style={{ fontSize: "12px" }} />
                {/* Modified by Jhamman on 13-09-2024
              Reason - Added classname*/}
                {/* <b style={{ fontWeight: "500" }}> For Business : </b> */}
                <b
                  className={topHeaderStyle.titleOfContacts}
                  style={{ fontWeight: "500" }}>
                  {" "}
                  For Business :{" "}
                </b>
              {/* </div> */}
              {/* End of modification by Jhamman on 13-09-2024
              Reason - Added classname*/}
              <span> + 91 {settingDetail?.business_contact_number}</span>
            </div>
          ) : null}
          {settingDetail?.career_contact_number ? (
            /* Modified by Jhamman on 23-09-2024
            Reason - given classname to div*/
            /* <div> */
            <div className={topHeaderStyle.contactField2}>
              {/* Modified by Jhamman on 23-09-2024
            Reason - given classname to div*/}
              {/* <div className={topHeaderStyle.headingField}> */}
                <FaMobileAlt style={{ fontSize: "12px" }} />{" "}
                {/* Modified by Jhamman on 13-09-2024
              Reason - Added classname*/}
                {/* <b style={{ fontWeight: "500" }}>For Career :</b>{" "} */}
                <b
                  className={topHeaderStyle.titleOfContacts}
                  style={{ fontWeight: "500" }}>
                  For Career :
                </b>{" "}
              {/* </div> */}
              {/* End of modification by Jhamman on 13-09-2024
              Reason - Added classname*/}
              <span> + 91 {settingDetail?.career_contact_number}</span>
            </div>
          ) : null}

          {/* End of modification by Jhamman on 19-09-2024
        Reason - interchanged the position of bot div */}

          {settingDetail?.email || settingDetail?.second_email ? (
            /* Modified by Jhamman on 23-09-2024
            Reason - given classname to div*/
            /* <div> */
            <div className={topHeaderStyle.contactField3}>
              {/* Modified by Jhamman on 23-09-2024
            Reason - given classname to div*/}

              {/* <div className={topHeaderStyle.headingField}> */}
                <MdEmail style={{ fontSize: "12px" }} />
                {/* Modified by Jhamman on 13-09-2024
              Reason - Added classname*/}
                {/* <b style={{ fontWeight: "500" }}> Email : </b> */}
                <b
                  className={topHeaderStyle.titleOfContacts}
                  style={{ fontWeight: "500" }}>
                  {" "}
                  Email :{" "}
                </b>
              {/* </div> */}
              {/* Modified by Jhamman on 13-09-2024
              Reason - Added classname*/}
              <span> {settingDetail?.email}</span>

              {/* Added by Jhamman on 19-09-2024
              Reason - Added secound email to top header*/}
           {settingDetail?.email ? <> || </> : null}
              <span> {settingDetail?.second_email}</span>
              {/* End of addition by Jhamman on 19-09-2024
              Reason - Added secound email to top header*/}
            </div>
          ) : null}
        </div>
        <div className={topHeaderStyle.socialSearchConbtainer}>
          {/*Commentation by Jhamman on 22-09-2024
            Reason - Removed search container*/}
          {/* <div ref={searchContainerRef}>
            <form onSubmit={handleSubmit}>
              <div className={topHeaderStyle.searchContainer}>
                <input
                  type="text"
                  name="search"
                  className={topHeaderStyle.searchInput}
                  // Code changed by - Ashlekh on 26-08-2024
                  // Reason - To remove category
                  // placeholder="Search by Prodct name, Item type and Category"
                  placeholder="Search by product name and product type..."
                  // End of code - Ashlekh on 26-08-2024
                  // Reason - To remove category
                  value={searchTerm}
                  onChange={handleInputChange}
                />
                <button type="submit" className={topHeaderStyle.searchButton}>
                // <i className={topHeaderStyle.searchIcon}>
                  <IoSearch className={topHeaderStyle.searchIcon} />
                 //  </i> 
                </button>
              </div>
            </form>
            {errorMessage && (
              <div className={topHeaderStyle.searchResults}>
                <div className={topHeaderStyle.searchResultItem}>
                  {errorMessage}
                </div>
              </div>
            )} */}

          {/* End of Commentation by Jhamman on 22-09-2024
            Reason - Removed search container*/}

          {/* // Commented by Om Shrivastava on 03-08-2024
            // When we need to apply search in onchange then below method is work  */}
          {/* {searchResults.length > 0 ? (
              <div className={topHeaderStyle.searchResults}>
                {searchResults.map((medicine, index) => (
                  <div
                    key={index}
                    className={`${topHeaderStyle.searchResultItem} ${
                      index % 2 === 0
                        ? topHeaderStyle.evenSearchItem
                        : topHeaderStyle.oddSearchItem
                    }`}
                    onClick={() => openProductDetails(medicine)}
                  >
                    {medicine.merged_name}
                  </div>
                ))}
              </div>
            ) : (
                searchTerm && !errorMessage && (
                <div className={topHeaderStyle.searchResults}>
                  <div className={topHeaderStyle.searchResultItem}>
                    Search result not found.
                  </div>
                </div>
              )
            )} */}
          {/* // Commented by Om Shrivastava on 03-08-2024
            // When we need to apply search in onchange then above method is work  */}

          {/* Commentation by Jhamman on 22-09-2024
            Reason - Removed search container*/}
          {/* {searchResults.length > 0 && (
              <div className={topHeaderStyle.searchResults}>
                {searchResults.map((medicine, index) => (
                  <div
                    key={index}
                    className={`${topHeaderStyle.searchResultItem} ${
                      index % 2 === 0
                        ? topHeaderStyle.evenSearchItem
                        : topHeaderStyle.oddSearchItem
                    }`}
                    onClick={() => openProductDetails(medicine)}>
                    {medicine.merged_name}
                  </div>
                ))}
              </div>
            )}
          </div> */}

          {/* End of Commentation by Jhamman on 22-09-2024
            Reason - Removed search container*/}

          {socialIconData?.length > 0 ? (
            <div className={topHeaderStyle.circleContainer}>
              {socialIconData.map((icon, index) => (
                /**
                 * Modified by - Ashish Dewangan on 25-08-2024
                 * Reason - To show social media icons and added links
                 */
                // <div key={index} className={topHeaderStyle.circle}>
                //   {icon.name.charAt(0)}
                // </div>
                <Link
                  to={icon.link}
                  target="_blank"
                  className={`${topHeaderStyle.circle}`}>
                  <img
                    src={baseURL + icon.logo}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
                /**
                 * End of modification by - Ashish Dewangan on 25-08-2024
                 * Reason - To show social media icons and added links
                 */
              ))}
            </div>
          ) : null}

          {/* Added by - Ashlekh on 21-08-2024
          Reason - To add Download brochure button */}
          {/* Commented by - Ashish Dewangan on 25-08-2024
          Reason - Moved it to header file */}
          {/* <div className={`${topHeaderStyle.downloadBrochure}`}>
            <Link
              className={`${topHeaderStyle.downloadBrochureButton}`}
              to={`${config.baseURL}${settingDetail?.brochure}`}
              target="_blank"
            >
              Download brochure
            </Link>
          </div> */}
          {/* End of comment by - Ashish Dewangan on 25-08-2024
          Reason - Moved it to header file */}
          {/* End of code - Ashlekh on 21-08-2024
          Reason - To add Download brochure button */}
        </div>
      </div>

      {/* Added by Jhamman on 19-09-2024
      Reason - added flag*/}
      <div className={topHeaderStyle.countryFlagContainer}>
        <img
          src="spanishhh flag.jpg"
          className={`${topHeaderStyle.flagIcon}`}
        />
        <img src="french flag.jpg" className={`${topHeaderStyle.flagIcon}`} />
        <img src="britishflag.jpg" className={`${topHeaderStyle.flagIcon}`} />
      </div>
      {/* End of addition by Jhamman on 19-09-2024
      Reason - added flag*/}

      {/* Commented by Jhamman on 19-09-2024
      Reason - have to remove globe and use country flag insteadof it*/}
      {/* Added by - Ashish Dewangan on 25-08-2024
          Reason - To show globe button */}
      {/* <div>
        <img src="globe2.png" className={`${topHeaderStyle.globeIcon}`} />
      </div> */}
      {/* Added by - Ashish Dewangan on 25-08-2024
          Reason - To show globe button */}
      {/* End of commentation by Jhamman on 19-09-2024
      Reason - have to remove globe and use country flag instead of it*/}
    </div>
  );
}
