/**
 * Created by - Ashlekh on 01-08-2024
 * Reason - To have AboutUs section
 */
import { useEffect, useState, useContext } from "react";
import style from "./aboutUs.module.css";
import { getAboutUsDetailsApi } from "../../../Api/services";
import config from "../../../Api/config";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const AboutUs = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [aboutusDetails, setAboutUsDetails] = useState({});
  const [aboutUsAchievement, setAboutUsAchievement] = useState([]);
  const [showContent, setShowContent] = useState(false);

  // Added by - Ashlekh on 01-08-2024
  // Reason - This useEffect will run when page loads
  useEffect(() => {
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // { name: "Company Profile", path: "/company_profile/about_us" },
      { name: "About us", path: "/company_profile/about_us" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    window.scrollTo(0, 0);
    aboutUsDetails();
  }, []);
  // End of code - Ashlekh on 01-08-2024
  // Reason - This useEffect will run when page loads

  // Added by - Ashlekh on 01-08-2024
  // Reason - To call API for getting about us details from backend
  const aboutUsDetails = async () => {
    try {
      const response = await getAboutUsDetailsApi();
      setAboutUsDetails(response?.about_us_details);
      setAboutUsAchievement(response?.about_us_achievement);
    } catch (error) {
      console.error("Error fetching about us details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  //   if (!aboutusDetails) return <p>No details available</p>;
  // End of code - Ashlekh on 01-08-2024
  // Reason - To call API for getting about us details from backend
  // Added by - Ashlekh on 01-08-2024
  // Reason - To add color in serial no
  const colors = [
    "#FFD700", // yellow
    "#FFA500", // orange
    "#A52A2A", // brown
    "#008000", // green
    "#008080", // teal
    "#0000FF", // blue
    "#000080", // navy
    "#800080", // purple
    "#FFC0CB", // pink
  ];
  const serialNoContainerColor = [
    "#D4AF37", // dark yellow
    "#FF8C00", // dark orange
    "#8B4513", // dark brown
    "#006400", // dark green
    "#008080", // dark teal
    "#00008B", // dark blue
    "#000080", // dark navy
    "#4B0082", // dark purple
    "#FF1493", // dark pink
  ];
  // End of code - Ashlekh on 01-08-2024
  // Reason - To add color in serial number

  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition of No details available */}
          {/* {aboutusDetails?.banner_image ||
      aboutusDetails?.description ||
      aboutusDetails?.description?.trim()?.length > 0 ||
      aboutusDetails?.achievement_title ||
      aboutusDetails?.achievement_title?.trim()?.length > 0 ||
      aboutUsAchievement?.length > 0 ||
      aboutusDetails?.video ||
      aboutusDetails?.video?.length > 0 ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition of No details available */}
          <div className={`${style.mainContainer}`}>
            {aboutusDetails?.banner_image ? (
              <div className={`${style.bannerImage}`}>
                <img
                  className={`${style.image}`}
                  src={config.baseURL + aboutusDetails?.banner_image}
                  alt=""
                />
              </div>
            ) : null}
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 07-09-2024
        Reason - To add title and display No details available text */}
            {aboutusDetails?.description ||
            aboutusDetails?.description?.trim()?.length > 0 ||
            aboutusDetails?.achievement_title ||
            aboutusDetails?.achievement_title?.trim()?.length > 0 ||
            aboutUsAchievement?.length > 0 ||
            aboutusDetails?.video ||
            aboutusDetails?.video?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /* <div className={`${style.pageTitle}`}>About Us</div> */
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Ab</span>
                  <span className={style.title2}>out Us</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
        Reason - To add title and display No details available text */}
            {aboutusDetails?.description ? (
              <div className={`${style.description}`}>
                {" "}
                {parse(aboutusDetails?.description)}{" "}
              </div>
            ) : null}
            {/* Added by - Ashlekh on 30-08-2024
          Reason - To add title for about us achievement section */}
            {aboutusDetails.achievement_title &&
            aboutusDetails?.achievement_title?.trim()?.length > 0 ? (
              <div className={`${style.aboutUsAchievementTitle}`}>
                {aboutusDetails?.achievement_title}
              </div>
            ) : null}
            {/* End of code - Ashlekh on 30-08-2024
          Reason - To add title for about us achievement section */}
            {aboutUsAchievement != null && aboutUsAchievement?.length > 0 ? (
              <div className={`${style.aboutUsAchievementContainer}`}>
                {aboutUsAchievement.map((item, index) => (
                  <div
                    key={index}
                    className={`${style.aboutUsAchievementSubContainer}`}>
                    <div className={`${style.subContainer1}`}>
                      <div className={`${style.iconContainer}`}>
                        <img
                          className={`${style.icon}`}
                          // Added by - Ashlekh on 29-08-2024
                          // Reason - To display dummy icon if not added from backend
                          // src={config.baseURL + item?.icon}
                          src={
                            item?.icon
                              ? `${config.baseURL + item?.icon}`
                              : "/RightArrowImage.jpeg"
                          }
                          // End of code - Ashlekh on 29-08-2024
                          // Reason - To display dummy icon if not added from backend
                          alt=""
                        />
                      </div>
                      <div className={`${style.titleContainer}`}>
                        {item.title}
                      </div>
                      <div className={`${style.descriptionContainer}`}>
                        {item.description}
                      </div>
                    </div>
                    <div
                      className={`${style.subContainer2}`}
                      style={{
                        backgroundColor: colors[index % colors.length],
                      }}>
                      <div
                        className={`${style.serialNo}`}
                        style={{
                          backgroundColor:
                            serialNoContainerColor[
                              index % serialNoContainerColor.length
                            ],
                        }}>
                        {index + 1}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {aboutusDetails?.video != null &&
            aboutusDetails?.video?.length > 0 ? (
              <div className={`${style.youtubeContainer}`}>
                <ReactPlayer
                  className={`${style.video}`}
                  url={aboutusDetails?.video}
                  controls={true}
                  width={500}
                  height={300}
                />
              </div>
            ) : null}
          </div>
          {/* Commented by - Ashlekh on 07-09-2024
        Reason - To change condition of No details available */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
          {/* End of code - Ashlekh on 07-09-2024
      Reason - To change condition of No details available */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};
export default AboutUs;
