/**
 * Created by - Ashlekh on 11-08-2024
 * Reason - To have ClientSatisfaction section
 */

import { useContext, useEffect, useState } from "react";
import style from "./clientSatisfaction.module.css";
import { getClientSatisfactionDetailsApi } from "../../../Api/services";
import config from "../../../Api/config";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";
import parse from "html-react-parser";

const ClientSatisfaction = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [clientSatisfaction, setClientSatisfaction] = useState({});
  const [showContent, setShowContent] = useState(false);
  // Added by - Ashlekh on 11-08-2024
  // Reason - This useEffect will executes when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    getClientSatisfactionDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // { name: "Company Profile", path: "/company_profile/client-satisfaction" },
      {
        name: "Client Satisfaction",
        path: "/company_profile/client-satisfaction",
      },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 11-08-2024
  // Reason - This useEffect will executes when page loads

  // Added by - Ashlekh on 11-08-2024
  // Reason - To get details from backend
  const getClientSatisfactionDetails = async () => {
    try {
      const response = await getClientSatisfactionDetailsApi();
      setClientSatisfaction(response?.client_satisfaction);
    } catch (error) {
      console.error("Error fetching ClientSatisfaction details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 11-08-2024
  // Reason - To get details from backend
  return (
    <>
      {showContent ? (
        <div>
          {/* Code commented by - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
          {/* {clientSatisfaction.title ||
      clientSatisfaction?.title?.trim()?.length > 0 ||
      clientSatisfaction?.image != null ||
      clientSatisfaction.description ||
      clientSatisfaction?.description?.trim()?.length > 0 ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
          <div className={`${style.mainContainer}`}>
            {/* Added by - Jhamman on 03-10-2024
        Reason - Added banner in top */}
            {clientSatisfaction?.image ? (
              <div className={`${style.imageContainer}`}>
                <img
                  className={`${style.image}`}
                  src={config.baseURL + clientSatisfaction?.image}
                  alt=""
                />
              </div>
            ) : null}
            {/* End of addition by - Jhamman on 03-10-2024
        Reason - Added banner in top */}
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 07-09-2024
        Reason - To add page title and No details available */}

            {/* Commented by Jhamman on 03-10-2024
        Reason - filled is removed from backend so no need */}
            {/* clientSatisfaction.title ||
            clientSatisfaction?.title?.trim()?.length > 0 ||
            clientSatisfaction?.image != null || */}
            {/* End of commentation by Jhamman on 03-10-2024
        Reason - filled is removed from backend so no need */}
            {clientSatisfaction.description ||
            clientSatisfaction?.description?.trim()?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={`${style.pageTitle}`}>Client Satisfaction</div>*/
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Cl</span>
                  <span className={style.title2}>ient Satisfaction</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
        Reason - To add page title and No details available */}

            {/* Commented by Jhamman on 03-10-2024
        Reason - filled is removed from backend so no need */}
            {/* {clientSatisfaction.title &&
            clientSatisfaction?.title?.trim()?.length > 0 ? (
              <div className={`${style.titleContainer}`}>
                {clientSatisfaction?.title}
              </div>
            ) : null} */}
            {/* End of commentation by Jhamman on 03-10-2024
        Reason - filled is removed from backend so no need */}

            {/* Commented by Jhamman on 03-10-2024
              Reason - Have to render image in top */}
            {/* clientSatisfaction?.image != null || */}
            {/*End of commentation by Jhamman on 03-10-2024
              Reason - Have to render image in top */}
            {clientSatisfaction.description ||
            clientSatisfaction?.description?.trim()?.length > 0 ? (
              /* Commented by Jhamman on 03-10-2024
              Reason - Have to render image in top */
              /* <div className={`${style.subContainer1}`}>
                <img
                  className={`${style.image}`}
                  src={config.baseURL + clientSatisfaction?.image}
                  alt=""
                /> */
              /* End of commentation by Jhamman on 03-10-2024
              Reason - Have to render image in top */
              <div className={`${style.description}`}>
                {/* Code changed by - Ashlekh on 12-09-2024
              Reason - To handle html tags of RichTextField */}
                {/* {clientSatisfaction?.description} */}{" "}
                {parse(clientSatisfaction?.description)}{" "}
                {/* End of code - Ashlekh on 12-09-2024
                Reason - To handle html tags of RichTextField */}
              </div>
            ) : /* </div> */
            null}
          </div>
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
  reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
reason - Show nothing while loading the data*/}
    </>
  );
};
export default ClientSatisfaction;
