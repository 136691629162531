/**
 * Created by - Ashish Dewangan on 08-08-2024
 * Reason - Created reusable component to show navigation path
 */
import { Link } from "react-router-dom";
import navigationPathStyle from "./NavigationPath.module.css";
const NavigationPath = ({ navigationPathArray }) => {
  return (
    <div className={navigationPathStyle.navigationPath}>
      {navigationPathArray.map((nav, index) => {
        return (
          <span>
            <Link
              /**
               * Modified by - Ashish Dewangan on 23-08-2024
               * Reason - If path is not coming then dont create link only show text
               */
              // to={nav.path}
              to={nav.path && nav.path}
              /**
               * End of code addition by - Ashish Dewangan on 23-08-2024
               * Reason - If path is not coming then dont create link only show text
               */
              className={`${navigationPathStyle.navigationLink}`}
            >
              {/*
               * Modified by - Ashish Dewangan on 23-08-2024
               * Reason - If path is not coming then dont create link only show text
               */}
              {/* {index !== 0 && ( */}
              {index !== 0 && nav.name && nav.name?.trim()?.length > 0 && (
                /*
                 * Modified by - Ashish Dewangan on 23-08-2024
                 * Reason - If path is not coming then dont create link only show text
                 */
                <span className={`${navigationPathStyle.navigationLinkText}`}>
                  {">"}
                </span>
              )}{" "}
              <span className={`${navigationPathStyle.navigationLinkText}`}>
                {nav.name}
              </span>
            </Link>
          </span>
        );
      })}
    </div>
  );
};
export default NavigationPath;
