import React from "react";
import { useState, useContext, useEffect } from "react";
import NavigationPath from "../../components/NavigationPath/NavigationPath";
import { GlobalContext } from "../../context/Context";
import Style from "./NewProductsDetails.module.css";
import { getProductDetailsApi } from "../../Api/services";
import config, { baseURL } from "../../Api/config";
import { Link } from "react-router-dom";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MdDownload } from "react-icons/md";

const NewProductsDetails = () => {
  const [itemType, setItemType] = useState([]);
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);

  var colorIndex = 0;
  var colors = [
    "#227fbb",
    "#1aaf5d",
    "#8f3faf",
    "#2b3e51",
    "#00a185",
    "#f2c500",
  ];

  const getColor = () => {
    if (colorIndex < 6) {
      var color = colors[colorIndex];
      colorIndex++;
      return color;
    } else {
      colorIndex = 0;
      var color = colors[colorIndex];
      colorIndex++;

      return color;
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Products", path: "/all-products" },
    ]);

    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await getProductDetailsApi();
      setItemType(response.item_type);
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  return (
    <div className={Style.mainContainer}>
      <NavigationPath navigationPathArray={navigationPath} />
      <div className={Style.subContainer}>
        {/* Modified by Jhamman on 05-10-2024
        Reason - Changed the style of heading */}
        {/* <div className={Style.pageTitle}>Products</div> */}
        <div className={Style.pageTitle}>
          <img className={Style.headingImage} src="headingdesign.png" />
          <div className={Style.headingTitle}>
            <span className={Style.title1}>Pr</span>
            <span className={Style.title2}>oducts</span>
          </div>
        </div>
        {/* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading */}
        {itemType?.length > 0 ? (
          <div className={Style.itemsContainer}>
            {itemType.map((data, index) => (
              <div className={Style.productList}>
                <div
                  className={Style.itemTypeContainer}
                  style={{ backgroundColor: getColor() }}>
                  <img
                    className={Style.images}
                    src={
                      data?.logo?.length > 0
                        ? baseURL + data.logo
                        : "medicine_1.png"
                    }
                  />
                  <div className={Style.itemTypeText}>{data.name}</div>
                </div>
                <div className={Style.linkContainer}>
                  {
                    data.PdfFile != null ? (
                      /* Modified by Jhamman on 22-09-2024
                    Reason - have to create button*/
                      <div className={Style.linkButton}>
                        <Link
                          className={Style.clickHere}
                          to={`${config.baseURL}${data.PdfFile}`}
                          target="_blank">
                          {/* click here to download */}
                          Download details
                        </Link>
                        <MdDownload className={Style.downloadButton} />
                      </div>
                    ) : /* End of modification by Jhamman on 22-09-2024
                    Reason -  have to create button*/
                    /* Modified by Jhamman on 22-09-2024
                    Reason - not to show anything*/
                    /* <div className={Style.clickHere}>No pdf found</div> */
                    null
                    /* End of modification by Jhamman on 22-09-2024
                    Reason - not to show anything*/
                  }
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={Style.noDetails}>
            <p className={Style.noDetailText}>No details available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewProductsDetails;
