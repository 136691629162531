/**
 * Created by - Ashlekh on 10-08-2024
 * Reason - To have Why Us section
 */
import { useContext, useEffect, useState } from "react";
import style from "./whyUs.module.css";
import { getWhyUsDetailsApi } from "../../../Api/services";
import parse from "html-react-parser";
import config from "../../../Api/config";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";
import { IoStar } from "react-icons/io5";

const WhyUs = () => {
  const [whyUsStrength, setWhyUsStrength] = useState({});
  const [whyUsList, setWhyUsList] = useState([]);
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [showContent, setShowContent] = useState(false);
  // Added by - Ashlekh on 10-08-2024
  // Reason - This useEffect will executes when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    whyUsDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      // { name: "Company Profile", path: "/company_profile/why-us" },
      { name: "Why Us", path: "/company_profile/why-us" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 10-08-2024
  // Reason - This useEffect will executes when page loads

  // Added by - Ashlekh on 10-08-2024
  // Reason - To get Why us details from backend
  const whyUsDetails = async () => {
    try {
      const response = await getWhyUsDetailsApi();
      setWhyUsStrength(response?.why_us_strength);
      setWhyUsList(response?.why_us);
    } catch (error) {
      console.error("Error fetching why us details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };
  // End of code - Ashlekh on 10-08-2024
  // Reason - To get Why us details from backend

  return (
    <>
      {showContent ? (
        <div>
          {/* Commented by - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
          {/* {whyUsList?.length > 0 || whyUsStrength?.description ? ( */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
          <div className={`${style.mainContainer}`}>
            {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
            {/* Added by - Ashlekh on 04-09-2024
        Reason - To add page title */}
            {/* Code changed by - Ashlekh on 07-09-2024
        Reason - To apply condition for No details available and page title */}
            {/* <div className={`${style.pageTitle}`}>Why Us</div> */}
            {whyUsList?.length > 0 ||
            whyUsStrength?.description ||
            whyUsStrength?.description?.trim()?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={`${style.pageTitle}`}>Why Us</div>*/
              <div className={style.pageTitle}>
                <img className={style.headingImage} src="headingdesign.png" />
                <div className={style.headingTitle}>
                  <span className={style.title1}>Wh</span>
                  <span className={style.title2}>y Us</span>
                </div>
              </div>
            ) : (
              /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              <p className={style.noDetailsAvailableText}>
                No details available
              </p>
            )}
            {/* End of code - Ashlekh on 07-09-2024
        Reason - To apply condition for No details available and page title */}
            {/* End of code - Ashlekh on 04-09-2024
        Reason - To add page title */}
            <div className={`${style.subContainer}`}>
              {whyUsList?.length > 0 ? (
                <div className={`${style.subContainer1}`}>
                  <div className={`${style.titleContainer}`}>
                    Why Choose Us?
                  </div>
                  <div className={`${style.listContainer}`}>
                    {whyUsList.map((item, index) => (
                      <div className={`${style.listSubContainer}`}>
                        {item?.icon ? (
                          <img
                            className={`${style.image}`}
                            // Code changed by - Ashlekh on 29-08-2024
                            // Reason - If icon is not coming from backend then by default RightArrowImage will display
                            // src={config.baseURL + item?.icon}
                            /* Modified by jhamman on 04-10-2024
                             Reason - added star icon if image is not avilable*/
                            // src={
                            //   item?.icon
                            //     ? `${config.baseURL + item?.icon}`
                            //     :
                            //     "/RightArrowImage.jpeg"
                            // }
                            src={config.baseURL + item?.icon}
                            // End of code - Ashlekh on 29-08-2024
                            // Reason - If icon is not coming from backend then by default RightArrowImage will display
                            alt=""
                          />
                        ) : (
                          <IoStar className={style.whyUsePointerIcon} />
                        )}
                        {/* End of modification by jhamman on 04-10-2024
                        Reason - added star icon if image is not avilable */}
                        <div className={`${style.descriptionContainer}`}>
                          {/* Code changed by - Ashlekh on 12-09-2024
                      Reason - To handle html tags in UI */}
                          {/* {item?.description} */}
                          {parse(item.description)}{" "}
                          {/* End of code - Ashlekh on 12-09-2024
                Reason - To handle html tags in UI */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              <div className={`${style.subContainer2}`}>
                {whyUsStrength?.description ? (
                  <div className={`${style.description}`}>
                    {" "}
                    {parse(whyUsStrength?.description)}{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* Commented by - Ashlekh on 07-09-2024
        Reason - To change condition for No details available */}
          {/* ) : (
        <p className={style.noDetailsAvailableText}>No details available</p>
      )} */}
          {/* End of comment - Ashlekh on 07-09-2024
      Reason - To change condition for No details available */}
        </div>
      ) : (
        /* Addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/
        <div className={style.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
    reason - Show nothing while loading the data*/}
    </>
  );
};
export default WhyUs;
