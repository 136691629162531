//Added by - Jhamman lal sahu on 05-08-2024
//Reason - To render UI of ThirdPartyManufacturing.jsx

import styles from "./ThirdPartyManufacturing.module.css";
import { thirdPartyManufacturing } from "../../../Api/services";
import { useState, useEffect, useContext } from "react";
import parse from "html-react-parser";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const ThirdPartyManufacturing = () => {
  const [thirdPartyManufacturingDetails, setThirdPartyManufacturingDetails] =
    useState({});
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  //Added by - jhamman lal sahu on 05-08-2024
  //Reason - To get details of third party manufacturing
  const fetchThirdPartyManufacturingDetails = async () => {
    try {
      const response = await thirdPartyManufacturing();
      setThirdPartyManufacturingDetails(
        response.thirdPartyManufacturing_details
      );
    } catch (error) {
      console.error("Error fetching quality Control details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },

      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Services", path: "/Services/third-party-manufacturing" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path

      {
        name: "Third Party Manufacturing",
        path: "/Services/third-party-manufacturing",
      },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchThirdPartyManufacturingDetails();
  }, []);

  // End of the addition by Jhamman lal sahu on 05-08-2025
  // Reason - to get third party manufacturing details

  //commented by - Jhamman lal sahu on 31-08-2024
  //Reason - dont want to display this

  //Modified by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  // if (!thirdPartyManufacturingDetails) return <p>No details available</p>;

  // if (!thirdPartyManufacturingDetails)
  //   return (
  //     <div className={styles.noDetails}>
  //       <p className={styles.noDetailText}>No details available</p>
  //     </div>
  //   );
  //End of the modification by - Jhamman lal sahu on 22-08-2024
  //Reason - customize no details available

  //End of the commentation by - Jhamman lal sahu on 18-08-2024
  //Reason - dont want to display this

  return (
    <>
      {showContent ? (
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* Added by Jhamman on 31-08-2024
    Reason - conditional rendering */}
          {/* {
        thirdPartyManufacturingDetails?.title_and_description ||
        thirdPartyManufacturingDetails?.title_and_description?.trim()?.length >
          0 ||
        thirdPartyManufacturingDetails?.page_content ||
        thirdPartyManufacturingDetails?.page_content?.trim()?.length > 0 ? (
          // End of addition by Jhamman on 31-08-2024
          //Reason - conditional rendering */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}

          <div className={styles.mainContainer}>
            {/* Added by - Jhamman lal sahu on 18-08-2024
            Reason - To add path in UI */}

            <NavigationPath navigationPathArray={navigationPath} />

            {/* Added by - Jhamman lal sahu on 18-08-2024
    Reason - To add path in UI */}
            <div className={styles.subContainer}>
              {/* Modified by Jhamman on 06-09-2024
        Reason - print page title based on condition*/}
              {/* Added by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* <div className={styles.pageTitle}>Third party manufacturing</div> */}
              {thirdPartyManufacturingDetails?.title_and_description ||
              thirdPartyManufacturingDetails?.title_and_description?.trim()
                ?.length > 0 ||
              thirdPartyManufacturingDetails?.page_content ||
              thirdPartyManufacturingDetails?.page_content?.trim()?.length >
                0 ? (
                /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                /* <div className={styles.pageTitle}>
                  {/* Third party manufacturing 
                  Third Party Manufacturing
                </div>*/
                <div className={styles.pageTitle}>
                  <img
                    className={styles.headingImage}
                    src="headingdesign.png"
                  />
                  <div className={styles.headingTitle}>
                    <span className={styles.title1}>Th</span>
                    <span className={styles.title2}>ird party manufacturing</span>
                  </div>
                </div>
              ) : (
                /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
                <div className={styles.noDetails}>
                  <div className={styles.noDetailText}>
                    No details available
                  </div>
                </div>
              )}
              {/* End of code addition by - Jhamman  on 01-09-2024
              Reason - Added page title */}
              {/* End of modification by Jhamman on 06-09-2024
        Reason - print page title based on condition*/}

              {thirdPartyManufacturingDetails?.title_and_description ? (
                <div className={styles.titleAndDescription}>
                  {parse(thirdPartyManufacturingDetails?.title_and_description)}
                </div>
              ) : null}
              {thirdPartyManufacturingDetails?.page_content ? (
                <div className={styles.page_content}>
                  {parse(thirdPartyManufacturingDetails?.page_content)}
                </div>
              ) : null}
            </div>
          </div>

          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* ) : ( // Added by Jhamman on 31-08-2024 //Reason - No details available
      <div className={styles.noDetails}>
        <p className={styles.noDetailText}>No details available</p>
      </div>
      ) // End of the addition by Jhamman on 31-08-2024 //Reason - No details
      available } */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={styles.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};

export default ThirdPartyManufacturing;

//End of the addition by Jhamman lal sahu on 05-08-2024
//Reason - To render UI of ThirdPartyManufacturing.jsx
