/**
 * Created by - Ashlekh on 16-08-2024
 * Reason - To have Contact us page
 */

import { useContext, useEffect, useState } from "react";
import style from "./contactUs.module.css";
import {
  getContactDetailsApi,
  postContactRequestApi,
} from "../../Api/services";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { IoMdCall } from "react-icons/io";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdContactEmergency } from "react-icons/md";
import { PiPhoneDisconnect } from "react-icons/pi";
import { AiTwotoneMail } from "react-icons/ai";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";

const ContactUs = () => {
  // Added by - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  // End of code - Ashlekh on 23-08-2024
  // Reason - To add useState for navigation path
  const [contactDetails, setContactDetails] = useState({});
  // Added by - Ashlekh on 16-08-2024
  // Reason - This useEffect will executes when page loads
  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 03-09-2024
     * Reason - Scroll to top when refresh
     */
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 03-09-2024
     * Reason - Scroll to top when refresh
     */
    contactUsDetails();
    /**
     * Added by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Contact Us", path: "/contact" },
    ]);

    /**
     * End of code addition by - Ashlekh on 23-08-2024
     * Reason - To add paths in navigation path variable
     */
  }, []);
  // End of code - Ashlekh on 16-08-2024
  // Reason - This useEffect will executes when page loads

  const initialValues = {
    name: "",
    countryCode: "91",
    contact_number: "",
    email: "",
    query: "",
  };

  // Added by - Ashlekh on 16-08-2024
  // Reason - To apply validation message
  const validationSchema = Yup.object({
    contact_number: Yup.number()
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value
      )
      .integer()
      .typeError("Please enter 10 digit mobile number ")
      .required("Please enter mobile number")
      .min(1000000000, "Please enter 10 digit mobile number ")
      .max(9999999999, "Please enter 10 digit mobile number "),

    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9\u0900-\u097F\s]+$/,
        "Please remove special characters"
      )
      .required("Please enter name"),
    email: Yup.string().trim().email("Please enter email in correct format"),
  });
  // End of code - Ashlekh on 16-08-2024
  // Reason - To apply validation message

  // Added by - Ashlekh on 16-08-2024
  // Reason - To display validation message
  const renderError = (message) => (
    <span className={`${style.validationMsgContainer}`}>{message}</span>
  );
  // End of code - Ashlekh on 16-08-2024
  // Reason - To display validation message

  // Added by - Ashlekh on 16-08-2024
  // Reason - API to post contact form
  async function submitContactRequest(values) {
    const requestData = {
      name: values.name,
      // Added by jhamman on 03-09-2024
      // Reason - Added country code in form data
      countryCode: values.countryCode,
      // End of addition by jhamman on 03-09-2024
      // Reason - Added country code in form data
      contact_number: values.contact_number,
      email: values.email,
      query: values.query,
    };

    // Added by - Ashlekh on 11-09-2024
    // Reason - Message was not saving (Set key/value pair and appending data)
    const formData = new FormData();
    formData.append("name", values.name);

    // Added by jhamman on 03-09-2024
    // Reason - Added country code in form data
    formData.append("countryCode", values.countryCode);
    // End of addition by jhamman on 03-09-2024
    // Reason - Added country code in form data

    formData.append("contact_number", values.contact_number);
    formData.append("email", values.email);
    formData.append("message", values.query);
    const response = await postContactRequestApi(formData);
    // End of code - Ashlekh on 11-09-2024
    // Reason - Message was not saving (set key/value pair and appending data)
    // const response = await postContactRequestApi(requestData);
    if (response.message) {
      notificationObject.success(response.message);
    } else if (response.error) {
      notificationObject.dismissAll();
      notificationObject.error(response.error);
    }
  }
  const onSubmit = (values) => {
    submitContactRequest(values);
  };
  // End of code - Ashlekh on 16-08-2024
  // Reason - API to post contact form

  // Added by - Ashlekh on 16-08-2024
  // Reason - To get ContactUs form
  const contactUsDetails = async () => {
    try {
      const response = await getContactDetailsApi();
      setContactDetails(response?.contact_us_details);
    } catch (error) {
      console.error("Error fetching Contact Us details:", error);
    }
  };
  // End of code - Ashlekh on 16-08-2024
  // Reason - To get ContactUs form
  return (
    <div className={`${style.mainContainer}`}>
      {/* Added by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
      <NavigationPath navigationPathArray={navigationPath} />
      {/* End of code addition by - Ashlekh on 23-08-2024
        Reason - To show navigation path */}
      {/* Added by - Ashlekh on 07-09-2024
        Reason - To add page title */}
      {/* Modified by Jhamman on 05-10-2024
      Reason - Changed the style of heading */}
      {/* <div className={`${style.pageTitle}`}>Contact Us</div> */}
      <div className={style.pageTitle}>
        <img className={style.headingImage} src="headingdesign.png" />
        <div className={style.headingTitle}>
          <span className={style.title1}>Co</span>
          <span className={style.title2}>ntact Us</span>
        </div>
      </div>
      {/* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/}
      {/* End of code - Ashlekh on 07-09-2024
        Reason - To add page title */}
      <div className={`${style.subContainer}`}>
        {contactDetails?.business_contact_number ||
        contactDetails?.business_contact_number?.trim()?.length > 0 ||
        contactDetails.email ||
        contactDetails?.email?.trim()?.length > 0 ||
        contactDetails.office_address ||
        contactDetails?.office_address?.trim()?.length > 0 ||
        contactDetails.factory_address ||
        contactDetails?.factory_address?.trim()?.length > 0 ? (
          <div className={`${style.descriptionContent}`}>
            <div className={`${style.descriptionSubContent}`}>
              {contactDetails.office_address &&
              contactDetails?.office_address?.trim()?.length > 0 ? (
                <div className={`${style.addressContainer}`}>
                  {/* Modified by Jhamman on 21-09-2024
                reason - wrapped element in div*/}
                  <div className={`${style.iconField}`}>
                    <MdOutlineLocationOn className={`${style.locationIcon}`} />
                  </div>
                  {/* End of modification by Jhamman on 21-09-2024
                reason - wrapped element in div*/}
                  <div className={`${style.address}`}>
                    <span>Office address: </span>{" "}
                    {contactDetails?.office_address}
                  </div>
                </div>
              ) : null}
              {/* Added by - Ashlekh on 06-09-2024
              Reason - To add factory address */}
              {contactDetails.factory_address &&
              contactDetails?.office_address?.trim()?.length > 0 ? (
                <div className={`${style.factoryAddressContainer}`}>
                  {/* Modified by Jhamman on 21-09-2024
                reason - wrapped element in div*/}
                  <div className={`${style.iconField}`}>
                    <MdOutlineLocationOn className={`${style.locationIcon}`} />
                  </div>
                  {/* End of modification by Jhamman on 21-09-2024
                reason - wrapped element in div*/}
                  <div className={`${style.factoryAddress}`}>
                    <span>Factory address: </span>{" "}
                    {contactDetails?.factory_address}
                  </div>
                </div>
              ) : null}
              {/* End of code - Ashlekh on 06-09-2024
              Reason - To add factory address */}

              {contactDetails?.business_contact_number &&
              contactDetails?.business_contact_number?.trim()?.length > 0 ? (
                <div className={`${style.mobileContainer}`}>
                  {/* Modified by Jhamman on 21-09-2024
                reason - wrapped element in div*/}
                  <div className={`${style.iconField}`}>
                    <IoMdCall className={`${style.contactIcon}`} />{" "}
                  </div>
                  {/* End of modification by Jhamman on 21-09-2024
                reason - wrapped element in div*/}
                  <div className={`${style.mobile}`}>
                    <span>For institutional business and tenders: </span>{" "}
                    {contactDetails?.business_contact_number}
                  </div>
                </div>
              ) : null}

              {/*Code by - Jhamman on 19-09-2024
              Reason - To add general query */}
              {contactDetails.career_contact_number &&
                contactDetails.career_contact_number?.trim()?.length > 0 && (
                  <div className={`${style.mobileContainer}`}>
                    <div className={`${style.iconField}`}>
                      <IoMdCall className={`${style.contactIcon}`} />{" "}
                    </div>

                    <div className={`${style.mobile}`}>
                      <span>For general query: </span>{" "}
                      {contactDetails?.career_contact_number}
                    </div>
                  </div>
                )}
              {/* End of code - Jhamman on 19-09-2024
              Reason - To add general query */}

              {/*Code by - Jhamman on 19-09-2024
              Reason - domestic sale */}
              {contactDetails.domestic_sales_contact_number &&
                contactDetails.domestic_sales_contact_number?.trim()?.length >
                  0 && (
                  <div className={`${style.mobileContainer}`}>
                    <div className={`${style.iconField}`}>
                      <IoMdCall className={`${style.contactIcon}`} />{" "}
                    </div>

                    <div className={`${style.mobile}`}>
                      <span>For domestic sale: </span>{" "}
                      {contactDetails?.domestic_sales_contact_number}
                    </div>
                  </div>
                )}
              {/* End of code - Jhamman on 19-09-2024
              Reason - To add domestic sale */}

              {contactDetails.email ||
              contactDetails?.email?.trim()?.length > 0 ||
              contactDetails?.second_email ||
              contactDetails?.second_email?.trim()?.length > 0 ? (
                <div className={`${style.emailContainer}`}>
                  {/* Modified by Jhamman on 21-09-2024
                reason - wrapped element in div*/}
                  <div className={`${style.iconField}`}>
                    <AiTwotoneMail className={`${style.emailIcon}`} />
                  </div>
                  {/* End of modification by Jhamman on 21-09-2024
                reason - wrapped element in div*/}

                  <div className={`${style.email}`}>
                    <span>Email: </span> {contactDetails?.email}
                    {contactDetails?.second_email ||
                    contactDetails?.second_email?.trim()?.length > 0 ? (
                      <> || </>
                    ) : null}
                    {contactDetails?.second_email}
                  </div>
                </div>
              ) : null}

              {/* Added by Jhamman on 19-09-2024
              Reason - Added hr email*/}
              {contactDetails?.hr_email ||
              contactDetails?.hr_email?.trim()?.length > 0 ? (
                <div className={`${style.emailContainer}`}>
                  <div className={`${style.iconField}`}>
                    <AiTwotoneMail className={`${style.emailIcon}`} />
                  </div>
                  <div className={`${style.email}`}>
                    <span>HR email: </span>
                    {contactDetails?.hr_email}
                  </div>
                </div>
              ) : null}
              {/* End of addition by Jhamman on 19-09-2024
        Reason - Added hr email*/}

              {/*Addition by Jhamman on 19-09-2024
        Reason - Added contact for international sales*/}
              {contactDetails.international_sales_contact_number ||
              contactDetails?.international_sales_contact_number?.trim()
                ?.length > 0 ||
              contactDetails.first_email_for_international_sales ||
              contactDetails?.first_email_for_international_sales?.trim()
                ?.length > 0 ||
              contactDetails?.second_email_for_international_sales ||
              contactDetails?.second_email_for_international_sales?.trim()
                ?.length > 0 ? (
                <div className={`${style.emailContainer}`}>
                  <div className={`${style.iconField}`}>
                    {/* <AiTwotoneMail className={`${style.emailIcon}`} /> */}
                    <PiPhoneDisconnect className={`${style.emailIcon}`} />
                  </div>
                  <div className={`${style.email}`}>
                    <span>For international sales: </span>{" "}
                    {contactDetails?.international_sales_contact_number}
                    {contactDetails?.first_email_for_international_sales ||
                    contactDetails?.first_email_for_international_sales?.trim()
                      ?.length > 0 ? (
                      <> || </>
                    ) : null}
                    {contactDetails?.first_email_for_international_sales}
                    {contactDetails?.second_email_for_international_sales ||
                    contactDetails?.second_email_for_international_sales?.trim()
                      ?.length > 0 ? (
                      <> || </>
                    ) : null}
                    {contactDetails?.second_email_for_international_sales}
                  </div>
                </div>
              ) : null}
              {/* End of addition by Jhamman on 19-09-2024
        Reason - Added contact for international sales*/}
            </div>
          </div>
        ) : null}

        <div className={`${style.contactUsFormContainer}`}>
          <div className={`${style.contactUsTitle}`}>
            Please fill this form to get in touch with us
          </div>
          <div className={`${style.formContainer}`}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                await onSubmit(values);
                resetForm();
              }}
              enableReinitialize={true}>
              <Form noValidate={true}>
                <div className={`${style.inputHolder}`}>
                  {/* Commented by - Ashlekh on 14-09-2024
                  Reason - To remove Name and colon */}
                  {/* <label className={`${style.label}`}>
                    Name
                    <span style={{ color: "red" }}> * </span>
                  </label>
                  <div className={`${style.colon}`}> : </div> */}
                  {/* End of code - Ashlekh on 14-09-2024
                  Reason - To remove Name and colon */}
                  <div className={`${style.inputWithError}`}>
                    <Field
                      name="name"
                      type="text"
                      // Added by - Ashlekh on 14-09-2024
                      // Reason - To display by default name
                      placeholder="*Name"
                      // End of code - Ashlekh on 14-09-2024
                      // Reason - To display by default name
                      className={`${style.inputBox}`}
                    />
                    <ErrorMessage name="name" render={renderError} />
                  </div>
                </div>

                <div className={`${style.inputHolder}`}>
                  {/* Commented by - Ashlekh on 14-09-2024
                  Reason - To remove Mobile Number and colon */}
                  {/* <label className={`${style.label}`}>
                    Mobile Number
                    <span style={{ color: "red" }}> * </span>
                  </label>

                  <div className={`${style.colon}`}> : </div> */}
                  {/* End of comment - Ashlekh on 14-09-2024
                  Reason - To remove Mobile Number and colon */}
                  <div className={style.country_code_and_mobile_number}>
                    <Field
                      name="countryCode"
                      type="number"
                      className={`${style.countryInput}`}
                      /* Added by jhamman on 05-10-2024
                          Reason - Added max length */
                      onInput={(e) => {
                        if (e.target.value.length > 5) {
                          e.target.value = e.target.value.slice(0, 5);
                        }
                      }}
                      /* End of addition by jhamman on 05-10-2024
                          Reason - Added max length */
                    />
                    <div className={`${style.inputWithError}`}>
                      {/* Added by - Jhamman on 03-09-2024 
                    Reason - To display country code */}
                      {/* End of addition by - Jhamman on 03-09-2024
                     Reason - To display country code */}
                      <Field
                        name="contact_number"
                        type="number"
                        // Added by - Ashlekh on 14-09-2024
                        // Reason - To display by default name
                        placeholder="*Mobile Number"
                        // End of code - Ashlekh on 14-09-2024
                        // Reason - To display by default name
                        className={`${style.inputBox}`}
                      />
                      <ErrorMessage
                        name="contact_number"
                        render={renderError}
                      />
                    </div>
                  </div>
                </div>
                <div className={`${style.inputHolder}`}>
                  {/* Commented by - Ashlekh on 14-09-2024
                  Reason - To remove Email Address and colon */}
                  {/* <label
                    className={`${style.label}`}
                    style={{ paddingLeft: "1px" }}
                  >
                    Email Address
                  </label>
                  <div className={`${style.colon}`}> : </div> */}
                  {/* End of code - Ashlekh on 14-09-2024
                  Reason - To remove Email Address and colon */}
                  <div className={`${style.inputWithError}`}>
                    <Field
                      name="email"
                      type="text"
                      placeholder="Email"
                      className={`${style.inputBox}`}
                    />
                    <ErrorMessage name="email" render={renderError} />
                  </div>
                </div>
                <div className={`${style.inputHolder}`}>
                  {/* Commented by - Ashlekh on 14-09-2024
                  Reason - To remove Message and colon */}
                  {/* <label
                    className={`${style.label}`}
                    style={{ paddingLeft: "1px" }}
                  >
                    Message
                  </label>
                  <div className={`${style.colon}`}> : </div> */}
                  {/* End of code - Ashlekh on 14-09-2024
                  Reason - To remove Message and colon */}
                  <div className={`${style.inputWithError}`}>
                    <Field
                      component="textarea"
                      id="textareaField"
                      name="query"
                      placeholder="Message"
                      className={`${style.textArea} `}
                    />
                  </div>
                </div>
                <div
                  className={`${style.buttonContainer}`}
                  style={{
                    paddingTop: "2%",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <button className={`${style.button}`} type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      {contactDetails?.map_location_of_office != "" && (
        <iframe
          className={`${style.map}`}
          title="Map"
          src={contactDetails?.map_location_of_office}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          style={{ border: "0", outline: "none" }}></iframe>
      )}
    </div>
  );
};
export default ContactUs;
