// Created by Om Shrivastava on 02-08-2024
// Reason : Created a product detail page
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import ProductDetailStyle from "./ProductDetail.module.css";
import config from "../../Api/config";
import { IoHome } from "react-icons/io5";
import ItemDetailPopup from "./ItemDetailPopup";

export default function SearchProductDetail() {
  const location = useLocation();
  const dataReceived = location.state;
  const [selectedItem, setSelectedItem] = useState(null); // Set the all category

  const openItemDetailPopup = (data) => {
    const item = data;
    setSelectedItem(item);
  };

  const closePopup = () => {
    setSelectedItem(null); // Close the popup
  };

  console.log(dataReceived, "check value");
  return (
    <div className={ProductDetailStyle.mainDivContainer}>
      <div className={ProductDetailStyle.breadcrumbs}>
        <div
          style={{
            textAlign: "left",
            paddingLeft: "7.3%",
            fontSize: "14px",
            fontFamily: "sans-serif",
          }}
        >
          <IoHome style={{ fontSize: "13px" }} />
          <a href="/" style={{ textDecoration: "none", color: "black" }}>
            {" "}
            Home{" "}
          </a>
          &nbsp;&nbsp;/&nbsp;&nbsp;
          Search Details /&nbsp;&nbsp; {dataReceived?.searchData?.name}
        </div>
      </div>
      <div className={ProductDetailStyle.categoryItemsContainer}>
        <div className={ProductDetailStyle.categoryContainer}>
          <h3 className={ProductDetailStyle.categoryName}>
            Category
          </h3>
          <ul>
            <li
              style={{
                backgroundColor: "#7ecd02" ,
                cursor: "pointer",
              }}
            >
              <a
                className={ProductDetailStyle.insideListcategoryName}
                style={
                  {
                      color: "#fff" 
                  }
                }
              >
                {dataReceived?.searchData?.category_name}
              </a>
            </li>
          </ul>
        </div>
        <div
          className={ProductDetailStyle.itemTypeItemsContainer}
        >
          <div
            className={ProductDetailStyle.allItemsContainer}
          >
            <div
              style={{
                display: "flex",
                gap: "5%",
              }}
            >
              <div
                className={ProductDetailStyle.itemTypeContainer}
              >
                <img
                  height="30px"
                  alt="Noo"
                  src={config.baseURL + dataReceived?.searchData?.logo}
                />
                {dataReceived?.searchData?.item_type}
              </div>
            </div>
          </div>
          <div>
            <div >
              <div
                className={ProductDetailStyle.insideItemContainerCategoryName}
              >
                {dataReceived?.searchData?.category_name}
              </div>
              <div
                className={ProductDetailStyle.insideItemContainerItemTypeName}
              >
                {dataReceived.searchData?.item_type}
              </div>
              <div className={ProductDetailStyle.itemTypeRow}>
                <div
                  className={ProductDetailStyle.insideItemContainerItemName}
                  onClick={() => openItemDetailPopup(dataReceived)}
                >
                  {dataReceived.searchData?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedItem && (
        <ItemDetailPopup item={selectedItem.searchData} onClose={closePopup} />
      )}
    </div>
  );
}
