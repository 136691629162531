/* Added by Jhamman Lal Sahu on 08-08-2024
  Reason - to render UI for Events*/

import { events } from "../../Api/services";
import Style from "./Events.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../Api/config";
import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";
import { useNavigate } from "react-router-dom";

const Events = () => {
  const [eventsDetails, setEventsDetails] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  /* Added by Jhamman Lal Sahu on 08-08-2024
    Reason - to fetch Events details*/

  const fetchEventsDetails = async () => {
    try {
      const response = await events();
      setEventsDetails(response);
    } catch (error) {
      console.error("Error fetching events details:", error);
    }
  };

  /* End of addition by Jhamman Lal Sahu on 08-08-2024
    Reason - to fetch Events details*/

  useEffect(() => {
    /*Added by Jhamman lal sahu on 28-08-2024
    Reason - to show current viewed event in their current state after reload*/

    /**
     * Added by - Jhamman lal sahu on 27-09-2024
     * Reason - Scroll to top when refresh
     */
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 27-09-2024
     * Reason - Scroll to top when refresh
     */

    /* Commented by Jhamman on 27-09-2024
    Reason - We are passing data to another page so no need*/
    // const storedEvent = sessionStorage.getItem("selectedEvent");
    // if (storedEvent) {
    //   setSelectedEvent(JSON.parse(storedEvent));
    // }
    /* End of commentation by Jhamman on 27-09-2024
    Reason - We are passing data to another page so no need*/

    /*Added by Jhamman lal sahu on 28-08-2024
    Reason - to show current viewed event in their current state after relode*/

    /**
     * Added by - Jhamman lal sahu on 28-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Events", path: "/events" },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 28-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchEventsDetails();
  }, []);

  // Added by - Jhamman Lal sahu 0n 29-08-2024
  // Reason - to remove item from session storage when no content in backend

  /* Commented by Jhamman on 27-09-2024
    Reason - We are passing data to another page so no need*/
  // useEffect(() => {
  //   if (eventsDetails?.events_details?.length === 0) {
  //     setSelectedEvent(null);
  //     sessionStorage.removeItem("selectedEvent");
  //   }
  // }, [eventsDetails]);
  /* End of commentation by Jhamman on 27-09-2024
    Reason - We are passing data to another page so no need*/

  // End of ddition by - Jhamman Lal sahu 0n 29-08-2024
  // Reason - to remove item from session storage when no content in backend

  /* End of the addition by Jhamman Lal Sahu on 08-08-2024
    Reason - to fetch Events details*/

  // Added by - Jhamman Lal sahu 0n 09-08-2024
  // Reason - to show deatails of a card in top and cancel that

  /* Commented by Jhamman on 27-09-2024
    Reason - We are passing data to another page so no need*/
  // const handleViewMore = (data) => {
  //   sessionStorage.setItem("selectedEvent", JSON.stringify(data));
  //   const storedEvent = sessionStorage.getItem("selectedEvent");
  //   if (storedEvent) {
  //     setSelectedEvent(JSON.parse(storedEvent));
  //   }
  //   window.scrollTo(0, 0);
  // };

  // const handlecancel = () => {
  //   setSelectedEvent(null);
  //   sessionStorage.removeItem("selectedEvent");
  // };
  /* End of commentation by Jhamman on 27-09-2024
    Reason - We are passing data to another page so no need*/

  // End of addition - Jhamman Lal sahu 0n 09-08-2024
  // Reason - to show deatails of a card in top and cancel that

  //   Added by - jhamman lal sahu on 08-08-2024
  //   Reason - To truncate title if the word is exceed then 10
  const truncateTitle = (title) => {
    const words = title?.split("");
    // Code changed by - Ashlekh on 11-09-2024
    // Reason - To display less words in event card
    // if (words.length > 10) {
    //   return words.slice(0, 10).join(" ") + ".......";
    // }
    if (words?.length > 26) {
      return words.slice(0, 26).join("") + ".......";
    }
    // End of code - Ashlekh on 11-09-2024
    // Reason - To display less words in event card
    return title;
  };
  //End of the addition jhamman lal sahu on 08-08-2024
  //   Reason - To truncate title if the word is exceed then 10

  //   Added by - jhamman lal sahu on 05-09-2024
  //   Reason - To truncate description if the word is exceed then 50
  const truncateDescription = (description) => {
    /* Commentation by Jhamman on 15-09-2024
    Reason - Here we rendering based on number of word but now i want to based on number of charecter*/
    // const words = description.split(" ");
    // if (words.length > 52) {
    //   return words.slice(0, 52).join(" ") + "......";
    // }
    // return description;
    /* End of commentation by Jhamman on 15-09-2024
    Reason - Here we rendering based on number of word but now i want to based on number of charecter*/

    /* Addition by Jhamman on 15-09-2024
    Reason - customize description content based on character*/
    // const isMobile = window.innerWidth <= 720;
    const characters = description?.split("");
    if (windowWidth > 720 && characters?.length > 396) {
      return characters.slice(0, 395).join("") + "......";
    }
    if (windowWidth <= 720 && characters?.length > 110) {
      return characters.slice(0, 110).join("") + "......";
    }
    return description;
    /* End of addition by Jhamman on 15-09-2024
    Reason - customize description content based on character*/
  };
  //   End of addition by - jhamman lal sahu on 05-09-2024
  //   Reason - To truncate description if the word is exceed then 50

  /* Addition by Jhamman on 15-09-2024
    Reason - we have to resize the length of description in screen chane*/
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    truncateTitle();
    truncateDescription();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  /* End of addition by Jhamman on 15-09-2024
    Reason - we have to resize the length of description in screen chane*/

  //Commented by Jhamman lal sahu on 28-08-2024
  //Reason - will handle this in another way
  // if (eventsDetails) return <p> No deatils found</p>;
  //End of commentation by Jhamman lal sahu on 28-08-2024
  //Reason - will handle this in another way

  // if (eventsDetails?.events_details?.length === 0) return <p>No content</p>;

  //   Added by - jhamman lal sahu on 29-08-2024
  //   Reason - Show no content available
  if (eventsDetails?.events_details?.length === 0)
    return (
      <div className={Style.noDetails}>
        <p className={Style.noDetailText}>No content available</p>
      </div>
    );

  return (
    <div className={Style.mainContainer}>
      {/* Added by - Jhamman lal sahu on 18-08-2024
     Reason - To add path in UI */}

      <NavigationPath navigationPathArray={navigationPath} />

      {/* Added by - Jhamman lal sahu on 18-08-2024
      Reason - To add path in UI */}

      {/* Added by - Jhamman lal sahu on 18-08-2024
     Reason - To add title on UI */}

      {/* Modified by Jhamman on 05-10-2024
        Reason - Changed the style of heading */}
      {/* <div className={Style.uITitle}>
        {eventsDetails?.title && parse(eventsDetails?.title)}
      </div> */}
      <div className={Style.pageTitle}>
        <img className={Style.headingImage} src="headingdesign.png" />
        <div className={Style.headingTitle}>
          <span className={Style.title1}>Ev</span>
          <span className={Style.title2}>ents</span>
        </div>
      </div>
      {/* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading */}

      {/* End of addition by - Jhamman lal sahu on 18-08-2024
     Reason - To add title on UI */}

      {/* Commented by Jhamman on 23-09-2024
        Reason - we render this in another screen*/}
      {/* {selectedEvent && (
        <div className={Style.popupContainer}>
          <div className={Style.popupCards}>
            Modification by Jhamman on 23-09-2024
                Reason - Added multiple image

            <div className={Style.popupImageField}>
              <img
                src={baseURL + selectedEvent.event_image}
                className={Style.popupImage}
              />
              <Slide easing="ease">
                {selectedEvent?.event_image ? (
                  <div className={Style.popupImages}>
                    <img
                      src={baseURL + selectedEvent?.event_image}
                      className={Style.popupImage}
                    />
                  </div>
                ) : null}
                {selectedEvent?.event_image2 ? (
                  <div className={Style.popupImages}>
                    <img
                      src={baseURL + selectedEvent?.event_image2}
                      className={Style.popupImage}
                    />
                  </div>
                ) : null}
                {selectedEvent?.event_image3 ? (
                  <div className={Style.popupImages}>
                    <img
                      src={baseURL + selectedEvent?.event_image3}
                      className={Style.popupImage}
                    />
                  </div>
                ) : null}
                {selectedEvent?.event_image4 ? (
                  <div className={Style.popupImages}>
                    <img
                      src={baseURL + selectedEvent?.event_image4}
                      className={Style.popupImage}
                    />
                  </div>
                ) : null}
                {selectedEvent?.event_image5 ? (
                  <div className={Style.popupImages}>
                    <img
                      src={baseURL + selectedEvent?.event_image5}
                      className={Style.popupImage}
                    />
                  </div>
                ) : null}
              </Slide>

              End of modification by Jhamman on 23-09-2024
                Reason - Added multiple image
            </div>
            <div className={Style.popupTitle}>
              {selectedEvent?.title && parse(selectedEvent.title)}
            </div>

            <div className={Style.popupDescription}>
              {selectedEvent?.description && parse(selectedEvent.description)}
            </div>

            <div className={Style.cancelField}>
              <button
                className={Style.cancelButton}
                onClick={() => handlecancel()}>
                <div className={Style.cancelText}>Close</div>
              </button>
            </div>
          </div>
        </div>
      )} */}
      {/*End of commentation by Jhamman on 23-09-2024
        Reason - we render this in another screen*/}

      <div className={Style.subContainer}>
        <div className={Style.imageAndtitleAndDescriptionContainer}>
          {/* {eventsDetails?.events_details?.map((data, index) => (
            <div className={Style.cards} key={index}>
              <div className={Style.imageField}>
                {data.event_image ? (
                  <img
                    src={baseURL + data.event_image}
                    className={Style.all_cards_image}
                  />
                ) : null}
              </div>

              <div className={Style.all_cards_title}>
                {data?.title && parse(truncateTitle(data.title))}
              </div>

              <div className={Style.all_cards_description}>
                {data?.description && parse(data.description)}
              </div>
              <div className={Style.viewField}>
                <button
                  className={Style.viewButton}
                  onClick={() => handleViewMore(data)}>
                  <div className={Style.viewText}>View More</div>
                </button>
              </div>
            </div>
          ))} */}
          {eventsDetails?.events_details?.map((data, index) => (
            <div
              // className={`${Style.cards} ${data.id === selectedEvent.id ? Style.highlightedCard : ''}`}
              className={`${
                selectedEvent && data.id === selectedEvent.id
                  ? Style.highlightedCard
                  : Style.cards
              }`}
              key={index}>
              <div className={Style.imageField}>
                {data.event_image ? (
                  <img
                    src={baseURL + data.event_image}
                    className={Style.all_cards_image}
                  />
                ) : null}
              </div>

              <div className={Style.all_cards_title}>
                {data?.title && parse(truncateTitle(data.title))}
              </div>

              <div className={Style.all_cards_description}>
                {data?.description &&
                  parse(truncateDescription(data.description))}
              </div>
              <div className={Style.viewField}>
                <button
                  className={`${Style.viewButton} ${
                    selectedEvent && data.id === selectedEvent.id
                      ? Style.highlightedViewButton
                      : ""
                  }`}
                  /* Modified by Jhamman on 23-09-2024
                  Reason - to open new page and show data*/
                  // onClick={() => handleViewMore(data)}
                  onClick={() => {
                    /* Commentation by Jhamman on 27-09-2024
                    Reason - We are passing data to another page so no need*/
                    // handleViewMore(data);
                    // window.location.href = "/event";
                    /* End of commentation by Jhamman on 27-09-2024
                    Reason - We are passing data to another page so no need*/

                    /* Modified by Jhamman on 29-09-2024
                    Reason - Passing id instead of whole data*/
                    // navigate("/event", { state: data });
                    navigate("/event", { state: data.id });
                    /* End of modification by Jhamman on 29-09-2024
                    Reason - Passing id instead of whole data*/
                  }}
                  /* End of modification by Jhamman on 23-09-2024
                  Reason - to open new page and show data*/
                >
                  <div
                    className={`${
                      selectedEvent && data.id === selectedEvent.id
                        ? Style.highlightedViewText
                        : Style.viewText
                    }`}>
                    View More
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;

/* End of the addition by Jhamman Lal Sahu on 08-08-2024
    Reason - to render UI for Events*/
