// Added by - Jhamman Lal Sahu on 04-08-2024
// Reason - UI to render contract manufacturing details

import { contractManufacturing } from "../../../Api/services";
import contractManufacturingStyle from "./ContractManufacturing.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../../Api/config";
import { useState, useEffect, useContext } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { GlobalContext } from "../../../context/Context";
import NavigationPath from "../../../components/NavigationPath/NavigationPath";

const ContractManufacturing = () => {
  // Added by - Jhamman Lal Sahu on 04-08-2024
  // Reason - to fetch contract manufacturing details

  const [contractManufacturingDetails, setContractManufacturingDetails] =
    useState({});
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const [showContent, setShowContent] = useState(false);

  const fetchContractManufacturingDetails = async () => {
    try {
      const response = await contractManufacturing();
      setContractManufacturingDetails(response);
    } catch (error) {
      console.error("Error fetching contract manufacturing details:", error);
    } finally {
      /* Addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
      setShowContent(true);
    }
    /* End of addition by jhamman on 13-09-2024
    Reason - show nothing when data is loading*/
  };

  useEffect(() => {
    /**
     * Added by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 30-08-2024
     * Reason - Scroll to top when refresh
     */

    /**
     * Added by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },

      // Commented by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path
      // { name: "Services", path: "/Services/contract-manufacturing" },
      // End of commentation by Jhamman on 06-09-2024
      // Reason  - Remove Infrastructure from navigation path

      {
        name: "Contract Manufacturing",
        path: "/Services/contract-manufacturing",
      },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 22-08-2024
     * Reason - To add paths to navigation path variable
     */
    fetchContractManufacturingDetails();
  }, []);

  return (
    <>
      {showContent ? (
        // Added by Jhamman on 31-08-2024
        //Reason - conditional rendering
        <>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* {
        contractManufacturingDetails?.contractManufacturing_details?.title ||
        contractManufacturingDetails?.contractManufacturing_details?.title?.trim()
          ?.length > 0 ||
        contractManufacturingDetails?.contractManufacturing_details
          ?.description_first ||
        contractManufacturingDetails?.contractManufacturing_details?.description_first?.trim()
          ?.length > 0 ||
        contractManufacturingDetails?.contractManufacturing_details
          ?.description_secound ||
        contractManufacturingDetails?.contractManufacturing_details?.description_secound?.trim()
          ?.length > 0 ||
        contractManufacturingDetails?.contractManufacturing_details
          ?.page_content ||
        contractManufacturingDetails?.contractManufacturing_details?.page_content?.trim()
          ?.length > 0 ||
        contractManufacturingDetails?.contractManufacturingBanners_details
          ?.banner_file != null ||
        contractManufacturingDetails?.contractManufacturingImagesGallery_details
          ?.value?.images != null ? ( */}
          {/* End of commentation by Jhamman on 06-09-2024
      Reason - We have to print navigation path wheather content have or not */}

          {/* // End of addition by Jhamman on 31-08-2024
          //Reason - conditional rendering */}
          <div className={contractManufacturingStyle.mainContainer}>
            {/* Added by - Jhamman lal sahu on 22-08-2024
            Reason - To add path in UI */}
            <NavigationPath navigationPathArray={navigationPath} />
            {/* End of addition by - Jhamman lal sahu on 22-08-2024
          Reason - To add path in UI */}

            {/* Added by - Jhamman  on 06-09-2024
              Reason - Added page title */}
            {contractManufacturingDetails?.contractManufacturingBanners_details
              ?.length >= 1 ||
            contractManufacturingDetails?.contractManufacturing_details
              ?.title ||
            contractManufacturingDetails?.contractManufacturing_details?.title?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturing_details
              ?.description_first ||
            contractManufacturingDetails?.contractManufacturing_details?.description_first?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturing_details
              ?.description_secound ||
            contractManufacturingDetails?.contractManufacturing_details?.description_secound?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturing_details
              ?.page_content ||
            contractManufacturingDetails?.contractManufacturing_details?.page_content?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturingBanners_details.length > 0||
            contractManufacturingDetails
              ?.contractManufacturingImagesGallery_details?.value?.length > 0 ? (
              /* Modified by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
              /*<div className={contractManufacturingStyle.pageTitle}>
                {/* Contract manufacturing 
                Contract Manufacturing
              </div>*/
              <div className={contractManufacturingStyle.pageTitle}>
                <img className={contractManufacturingStyle.headingImage} src="headingdesign.png" />
                <div className={contractManufacturingStyle.headingTitle}>
                  <span className={contractManufacturingStyle.title1}>Co</span>
                  <span className={contractManufacturingStyle.title2}>ntract Manufacturing</span>
                </div>
              </div>
            ) : /* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading*/
            null}
            {/* End of code addition by - Jhamman  on 06-09-2024
              Reason - Added page title */}

            {/* Comment by Jhamman on 06-09-2024
          Reason - Hardcoded it */}
            {/* {contractManufacturingDetails?.contractManufacturing_details?.title ? (
          <div className={contractManufacturingStyle.pageTitle}>
            {contractManufacturingDetails?.contractManufacturing_details?.title}
          </div>
        ) : null} */}
            {/* End of commentation by Jhamman on 06-09-2024
          Reason - Hardcoded it */}

            {/* Commented by - Jhamman lal sahu on 25-08-2024
      Reason - have some issue on ui */}
            {/* {contractManufacturingDetails?.contractManufacturingBanners_details
        ?.length > 0 ? (
        <Slide easing="ease">
          {contractManufacturingDetails?.contractManufacturingBanners_details?.map(
            (slide, index) => {
              return (
                <div
                  className={contractManufacturingStyle.slideContainer}
                  key={index}>
                  <img
                    src={baseURL + slide.banner_file}
                    className={contractManufacturingStyle.slideshowImage}
                  />
                </div>
              );
            }
          )}
        </Slide>
      ) : null} */}
            {/* End of the commentation by - Jhamman lal sahu on 25-08-2024
      Reason - have some issue on ui */}

            {/* Added by - Jhamman lal sahu on 25-08-2024
            Reason - To handle issue on banner */}
            {contractManufacturingDetails?.contractManufacturingBanners_details
              ?.length > 1 ? (
              <Slide easing="ease">
                {contractManufacturingDetails?.contractManufacturingBanners_details?.map(
                  (slide, index) => {
                    return (
                      <div
                        className={contractManufacturingStyle.slideContainer}
                        key={index}>
                        <img
                          src={baseURL + slide.banner_file}
                          className={contractManufacturingStyle.slideshowImage}
                        />
                      </div>
                    );
                  }
                )}
              </Slide>
            ) : contractManufacturingDetails
                ?.contractManufacturingBanners_details?.length === 1 ? (
              <div className={contractManufacturingStyle.slideContainer}>
                <img
                  src={
                    baseURL +
                    contractManufacturingDetails
                      ?.contractManufacturingBanners_details[0]?.banner_file
                  }
                  className={contractManufacturingStyle.slideshowImage}
                />
              </div>
            ) : null}
            {/* End of the addition by - Jhamman lal sahu on 25-08-2024
            Reason - To handle issue on banner */}

            {/* Modified by Jhamman on 06-09-2024
        Reason - render based on condition*/}
            {contractManufacturingDetails?.contractManufacturing_details
              ?.title ||
            contractManufacturingDetails?.contractManufacturing_details?.title?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturing_details
              ?.description_first ||
            contractManufacturingDetails?.contractManufacturing_details?.description_first?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturing_details
              ?.description_secound ||
            contractManufacturingDetails?.contractManufacturing_details?.description_secound?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturing_details
              ?.page_content ||
            contractManufacturingDetails?.contractManufacturing_details?.page_content?.trim()
              ?.length > 0 ||
            contractManufacturingDetails?.contractManufacturingBanners_details?.length > 0||
            contractManufacturingDetails
              ?.contractManufacturingImagesGallery_details?.value?.length > 0 ? (
              /* End of modification by Jhamman on 06-09-2024
        Reason - render based on condition*/

              <div className={contractManufacturingStyle.subContainer}>
                <div
                  className={contractManufacturingStyle.descriptionContainer}>
                  {contractManufacturingDetails?.contractManufacturing_details
                    ?.description_first && (
                    <div
                      className={contractManufacturingStyle.description_first}>
                      {parse(
                        contractManufacturingDetails
                          ?.contractManufacturing_details?.description_first
                      )}
                    </div>
                  )}

                  {contractManufacturingDetails?.contractManufacturing_details
                    ?.description_secound && (
                    <div
                      className={
                        contractManufacturingStyle.description_secound
                      }>
                      {parse(
                        contractManufacturingDetails
                          ?.contractManufacturing_details?.description_secound
                      )}
                    </div>
                  )}
                </div>

                {contractManufacturingDetails?.contractManufacturing_details
                  ?.page_content && (
                  <div className={contractManufacturingStyle.page_content}>
                    {parse(
                      contractManufacturingDetails
                        ?.contractManufacturing_details?.page_content
                    )}
                  </div>
                )}
                <div className={contractManufacturingStyle.galleryContainer}>
                  {contractManufacturingDetails
                    ?.contractManufacturingImagesGallery_details?.value
                    ?.length > 0 ? (
                    <div
                      className={
                        contractManufacturingStyle.galleryContainerHeading
                      }>
                      {
                        contractManufacturingDetails
                          ?.contractManufacturingImagesGallery_details?.title
                      }
                    </div>
                  ) : null}
                  <div className={contractManufacturingStyle.imageGallery}>
                    {contractManufacturingDetails?.contractManufacturingImagesGallery_details?.value?.map(
                      (data, index) => (
                        <div
                          key={index}
                          className={contractManufacturingStyle.imageField}>
                          <img
                            src={baseURL + data.images}
                            className={contractManufacturingStyle.images}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            ) : (
              /* Added by Jhamman on 06-09-2024
        Reason - render based on condition*/
              <div className={contractManufacturingStyle.noDetails}>
                <p className={contractManufacturingStyle.noDetailText}>
                  No details available
                </p>
              </div>
            )}
            {/* End of modification by Jhamman on 06-09-2024
              Reason - render based on condition*/}
          </div>
          {/* Commented by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
          {/* ) : (
          // Added by Jhamman on 31-08-2024
          //Reason - No details available
          <div className={contractManufacturingStyle.noDetails}>
            <p className={contractManufacturingStyle.noDetailText}>
              No details available
            </p>
          </div>
        )
        // End of the addition by Jhamman on 31-08-2024
        //Reason - No details available
      } */}
          {/* End of commentation by Jhamman on 06-09-2024
    Reason - We have to print navigation path wheather content have or not */}
        </>
      ) : (
        /* Addition by Jhamman on 13-09-2024
        reason - Show nothing while loading the data*/
        <div className={contractManufacturingStyle.loaderContent}>
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      )}
      {/* End of addition by Jhamman on 13-09-2024
      reason - Show nothing while loading the data*/}
    </>
  );
};
export default ContractManufacturing;

// End of the addition by Jhamman Lal Sahu on 04-08-2024
// Reason - UI to render contract manufacturing details
