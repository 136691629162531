import Style from "./PopupEvent.module.css";
import parse from "html-react-parser";
import { baseURL } from "../../Api/config";
import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/Context";
import NavigationPath from "../../components/NavigationPath/NavigationPath";
import { useLocation, useNavigate } from "react-router-dom";
import { singleEvent } from "../../Api/services";

const PopupEvent = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  const location = useLocation();
  const eventId = location.state;

  /* Added by Jhamman on 29-09-2024
Reason - to fetch event details*/
  const fetchEventsDetails = async () => {
    try {
      const response = await singleEvent(eventId);
      setSelectedEvent(response.event_details);
    } catch (error) {
      console.error("Error fetching events details:", error);
    }
  };
  /* End of addition by Jhamman on 29-09-2024
Reason - to fetch event details*/

  useEffect(() => {
    /*Added by Jhamman lal sahu on 23-09-2024
      Reason - to show current viewed event in their current state after reload*/
    /**
     * Added by - Jhamman lal sahu on 27-09-2024
     * Reason - Scroll to top when refresh
     */
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    /**
     * End of addition by - Jhamman lal sahu on 27-09-2024
     * Reason - Scroll to top when refresh
     */

    /* Commented by Jhamman on 26-09-2024
    Reason - We are not storing data in session storage*/
    // const storedEvent = sessionStorage.getItem("selectedEvent");
    // if (storedEvent) {
    //   setSelectedEvent(JSON.parse(storedEvent));
    // }
    /* End of commentation by Jhamman on 26-09-2024
    Reason - We are not storing data in session storage*/

    /*Added by Jhamman lal sahu on 23-09-2024
      Reason - to show current viewed event in their current state after relode*/

    /**
     * Added by - Jhamman lal sahu on 23-09-2024
     * Reason - To add paths to navigation path variable
     */
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "Events", path: "/events" },
      { name: "Event", path: "/event" },
    ]);

    /**
     * End of code addition by - Jhamman lal sahu on 23-09-2024
     * Reason - To add paths to navigation path variable
     */

    /* Added by Jhamman on 29-09-2024
Reason - to fetch event details*/
    fetchEventsDetails();
    /* End of addition by Jhamman on 29-09-2024
Reason - to fetch event details*/
  }, []);

  return (
    <div className={Style.mainContainer}>
      <NavigationPath navigationPathArray={navigationPath} />

      {/* Modified by Jhamman on 05-10-2024
        Reason - Changed the style of heading */}
      {/* <div className={Style.uITitle}>Event</div> */}
      <div className={Style.pageTitle}>
        <img className={Style.headingImage} src="headingdesign.png" />
        <div className={Style.headingTitle}>
          <span className={Style.title1}>Ev</span>
          <span className={Style.title2}>ent</span>
        </div>
      </div>
      {/* End of modification by Jhamman on 05-10-2024
                  Reason - Changed the style of heading */}

      {selectedEvent ? (
        <div className={Style.popupContainer}>
          <div className={Style.popupCards}>
            <div className={Style.popupTitle}>
              {selectedEvent?.title && parse(selectedEvent.title)}
            </div>

            <div className={Style.popupDescription}>
              {selectedEvent?.description && parse(selectedEvent.description)}
            </div>
            <div className={Style.popupImageField}>
              {selectedEvent?.event_image ? (
                <div className={Style.popupImages}>
                  <img
                    src={baseURL + selectedEvent?.event_image}
                    className={Style.popupImage}
                  />
                </div>
              ) : null}
              {selectedEvent?.event_image2 ? (
                <div className={Style.popupImages}>
                  <img
                    src={baseURL + selectedEvent?.event_image2}
                    className={Style.popupImage}
                  />
                </div>
              ) : null}
              {selectedEvent?.event_image3 ? (
                <div className={Style.popupImages}>
                  <img
                    src={baseURL + selectedEvent?.event_image3}
                    className={Style.popupImage}
                  />
                </div>
              ) : null}
              {selectedEvent?.event_image4 ? (
                <div className={Style.popupImages}>
                  <img
                    src={baseURL + selectedEvent?.event_image4}
                    className={Style.popupImage}
                  />
                </div>
              ) : null}
              {selectedEvent?.event_image5 ? (
                <div className={Style.popupImages}>
                  <img
                    src={baseURL + selectedEvent?.event_image5}
                    className={Style.popupImage}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PopupEvent;
